import { handleActions } from 'redux-actions'

export default handleActions({
  MENU: {
    TOGGLE: (state, action) => ({
      activeItem: state.activeItem,
      isOpen: !state.isOpen,
      menuItemName: action.payload?.menuItemName ? action.payload?.menuItemName : state.menuItemName,
    }),
    ACTIVE_ITEM: (state, action) => ({
      ...state,
      activeItem: action?.activeItem,
    }),
    DRAWER_OPEN: (state, action) => ({
      ...state,
      drawerOpen: action?.drawerOpen,
    }),
  },

}, {
  isOpen: false,
  menuItemName: 'MENU',
  activeItem: [],
  drawerOpen: false,
})