import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import actions from 'app/state/ducks/actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const styler = withStyles(({ spacing }) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  content: {
    padding: spacing(1),
  },
}))

const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />)

class DetailDialog extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.node.isRequired,
    close: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    open: false,
    title: 'Details',
    content: '',
    fullScreen: true,
  }

  render () {
    const {
      title,
      content,
      open,
      close,
      classes,
      ...rest
    } = this.props
    return (
      <Dialog
        scroll='paper'
        open={open}
        onClose={close}
        TransitionComponent={Transition}
        {...rest}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={close} aria-label="Close" size="large">
              <CloseIcon />
            </IconButton>
            <Typography
              color="inherit"
              className={classes.flex}
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          className={classes.content}
        >
          {content}
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => state.dialog.details

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(actions.dialog.details.close()),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(connector, styler)(DetailDialog)
