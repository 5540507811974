import * as log from 'loglevel'
import inspect from 'object-inspect'
import { env } from 'utils/env'
import _ from 'lodash'

log.setLevel(['development', 'staging'].includes(env('ENV')) ? 'debug' : 'error')

const levels = [
  'trace',
  'debug',
  'info',
  'warn',
  'error',
]

export default _
  .chain(levels)
  .keyBy(level => level)
  .mapValues((val, key) => (msg, data) => {
    let m = msg
    if (data) {
      m += ': ' + inspect(data)
    }
    log[key](m)
    // data && log[key](data)
  })
  .value()
