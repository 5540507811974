import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'

import useDialogs from 'app/views/hooks/useDialogs'
import { getNotifications } from 'app/state/ducks/user/selectors'

let displayed = []
const useNotifier = () => {
  const notifications = useSelector(getNotifications)
  const { removeNotice } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()

  const storeDisplayed = (id) => {
    displayed = [...displayed, id]
  }
  const removeDisplayed = (id) => {
    displayed = [...displayed.filter(key => id !== key)]
  }

  useEffect(() => {
    notifications.forEach(({ id, message, type }) => {
      if(displayed.includes(id)) return
      enqueueSnackbar(message, {
        variant: `${type.toLowerCase()}`,
        key: id,
        onExit: (event, key) => {
          removeNotice({ id: key })
          removeDisplayed(key)
        },
      })
      storeDisplayed(id)
    })
  }, [notifications])
}

export default useNotifier