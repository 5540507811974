import React, { useCallback, useContext } from 'react'
import { Formik } from 'formik'
import makeStyles from '@mui/styles/makeStyles'
import { aSchema, schemas } from '@sustainhawaii/heal-schemas'
import Scene from '../../../../layouts/Scene'
import ThemeForm from './ThemeForm'
import useDialogs from '../../../../hooks/useDialogs'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'app/views/contexts/ThemeConfigContext'
import { Drawer } from '@mui/material'
import { getTheme } from 'utils/theme'
import { updateTheme } from 'api/admin'
import { resolveErrorMessage } from 'utils/error-handling'
import { useDispatch, useSelector } from 'react-redux'
import { getAppTheme } from '../../../../../state/ducks/appinit/selectors'

const useStyles = makeStyles(() => ({
  form: {
    width: '60%',
    background: 'transparent',
  },
}))

const { string, array } = schemas

const schema = aSchema({
  theme: string().required('Required'),
  fontFamily: array().of(string().min(1)).min(1),
})

export default ({
  isOpen = false,
  handleClose,
}) => {
  const classes = useStyles()
  const [, setTheme] = useContext(ThemeContext)
  const { notice } = useDialogs()
  const { t } = useTranslation(['error'])
  const appTheme = useSelector(getAppTheme)
  const dispatch = useDispatch()
  const save = useCallback(values => {

    updateTheme(values)
      .then(() => {
        const theme = getTheme({ presetColor: values.theme, ...values })
        setTheme(theme)
        dispatch({
          type: 'THEME',
          theme: {
            theme: values.theme,
            fontFamily: values.fontFamily,
          },
        })
        handleClose()
      })
      .catch(e => notice({
        message: `Error ${resolveErrorMessage(e, t)}`,
        urgency: 'error',
      }))
  }, [])
  return (
    <Drawer
      open={isOpen}
      anchor='right'
      ModalProps={{
        onClose: handleClose,
      }}
    >
      <Scene>
        <Formik
          initialValues={{
            theme: appTheme,
            fontFamily: [],
          }}
          validationSchema={schema}
          validateOnMount
          onSubmit={save}
          className={classes.form}
        >
          {formikProps => (
            <ThemeForm
              {...formikProps}
            />
          )}
        </Formik>
      </Scene>
    </Drawer>
  )
}