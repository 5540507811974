import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { IconButton, Toolbar } from '@mui/material'

// project import
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import NavHeader from './NavHeader'
import { getOrg } from '../../../utils/org'

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme()
  const org = getOrg()
  const iconBackColor = 'grey.100'
  const iconBackColorOpen = 'grey.200'

  // common header
  const mainHeader = (
    <Toolbar >
      <IconButton
        disableRipple
        aria-label='open drawer'
        onClick={handleDrawerToggle}
        edge='start'
        color='secondary'
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      <HeaderContent />
    </Toolbar>
  )

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'primary',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }

  return (
    <>
      <AppBarStyled open={open} {...appBar}>
        {org.id === 'truewellth' && <NavHeader open={open}/> }
        {mainHeader}
      </AppBarStyled>

    </>
  )
}

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
}

export default Header