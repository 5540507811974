import _ from 'lodash'
import store from 'utils/local-storage'
import { getBase64ImageFromAWS, getFileFromAwsS3 } from './aws'
import JSZip from 'jszip'
import * as base64ToBlob from 'b64-to-blob'
import { browser, isTouchEnabled } from 'utils/browser'
import async from 'async'
import { getReducedImageBlob } from './image'

export const getFileBaseNameAndExt = file => {
  // Try the file name first:
  const parts = file.name.split('.')
  const ext = parts.pop()
  const baseName = parts.join('.')
  if (baseName && ext) {
    return { baseName, ext }
  }
  // Fallback to the file type:
  const [, flavor] = (file.type || []).split('/')
  return {
    baseName: file.name,
    ext: flavor,
  }
}

export const getFileNameExt = name => {
  if (!_.isString(name)) return
  const parts = name.split('.')
  return parts.pop()
}

export const hasImageFileExt = v => /\.(png|jpe?g)$/i.test(v)

export const getMimeTypeAcceptsStr = ({ imagesOnly = false }) => {
  const mimeTypesByExt = store.get('constants.UPLOADABLE_MIME_TYPES')
  const arr = imagesOnly
    ? _.chain(mimeTypesByExt).values().filter(v => _.startsWith(v, 'image')).value()
    : _.values(mimeTypesByExt)
  return _.uniq(arr).join(',')
}

export const getMimeTypeFromFileName = fileName => {
  const ext = getFileNameExt(fileName)
  if (!ext) return
  const mimeTypesByExt = store.get('constants.UPLOADABLE_MIME_TYPES')
  return mimeTypesByExt[getFileNameExt(fileName)]
}

export const getFileExtFromMimeType = mimeType => mimeType.split('/')[1]

export const isFileInputVal = val => _.isPlainObject(val) && (val.isNewFile || val.isUploadedFile)

// eslint-disable-next-line no-undef
export const isNewFileUpload = val => !!val?.isNewFile && val?.file instanceof File

// eslint-disable-next-line no-undef
export const isPreviousFileUpload = val => !!val?.isUploadedFile

export const getReducedFileUploadBlob = blob => {
  const type = _.get(blob, 'type', '')
  if (type.split('/')[0] === 'image') return getReducedImageBlob(blob)
  return blob
}
/**
 * Return blob representation of zip file.
 *
 * @param {object[]} fileInfo
 * @param {string} fileInfo[].folderName - name of folder to create in zip
 * @param {object[]} fileInfo[].files
 * @param {string} fileInfo[].files[].url - AWS URL
 * @param {string} fileInfo[].files[].fileName - file name for individual upload
 * @param {object} fileInfo[].otherBlobsByFileName - any other blobs to add to the folder
 * @param {function} progressCallback
 */
export const getZipFileContent = async ({
  fileInfo,
  progressCallback,
}) => {
  // Download all blobs and index by url:
  const urls = _.chain(fileInfo).map(({ files }) => _.map(files, 'url')).flatten().uniq().keyBy()
    .mapValues(() => 'nada')
    .value()
  const totalFiles = _.size(urls)
  // Download the files, 5 at a time to avoid timeouts on individual files, which can result in empty files:
  let fileNum = 0
  const blobsByFileKey = await async.mapValuesLimit(
    urls,
    5,
    async.asyncify(async (val, url) => {
      try {
        const blob = await getAwsFileForZip(url)
        progressCallback && progressCallback(100 * ++fileNum / totalFiles)
        return blob
      } catch (e) {
      }
    }),
  )
  const zip = new JSZip() // see docs at https://stuk.github.io/jszip/
  _.forEach(fileInfo, async ({ folderName, files, otherBlobsByFileName }) => {
    if (!_.isEmpty(otherBlobsByFileName)) {
      _.forEach(otherBlobsByFileName, (blob, fileName) => zip.file(`${folderName}/${fileName}`, blob))
    }
    _.forEach(files, ({ url, fileName }) => {
      const blob = blobsByFileKey[url]
      if (!blob instanceof Blob) return
      zip.file(`${folderName}/${fileName}`, blob, { base64: hasImageFileExt(fileName) })
    })
  })
  return zip.generateAsync({ type: 'blob' })
}

const getAwsFileForZip = async url => {
  if (hasImageFileExt(url)) {
    const mimeType = getMimeTypeFromFileName(url)
    const imageDataUri = await getBase64ImageFromAWS({ url, mimeType })
    const cleanedBase64 = imageDataUri.replace(`data:${mimeType};base64,`, '')
    return base64ToBlob(cleanedBase64, mimeType)
  }
  const res = await getFileFromAwsS3({
    url,
    responseType: 'blob',
  })
  return res?.data
}

export const getBlob = ({ blobOrData, bom, mimeType }) => {
  if (blobOrData instanceof Blob) return blobOrData
  const blobData = (typeof bom !== 'undefined') ? [bom, blobOrData] : [blobOrData]
  return new Blob(blobData, { type: mimeType || 'application/octet-stream' })
}

const getBlobUrl = blob => (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob)

export const downloadFile = ({
  data,
  fileName,
  mimeType,
  bom,
}) => {
  mimeType = mimeType || getMimeTypeFromFileName(fileName)
  const blob = getBlob({ blobOrData: data, mimeType, bom })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, fileName)
    return
  }
  const blobURL = getBlobUrl(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', fileName)
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }, 60000)
}

export const previewFile = ({
  data,
  fileName,
  mimeType,
  bom,
}) => {
  // Depending on platform and browser, previewing with a _blank target link may not work:
  const { type, vendor } = browser.getPlatform()
  if ((type === 'mobile' || isTouchEnabled()) && vendor === 'Apple') {
    return downloadFile({ data, fileName, mimeType })
  }
  mimeType = mimeType || getMimeTypeFromFileName(fileName)
  const blob = getBlob({ blobOrData: data, mimeType, bom })
  if (typeof window.navigator.msSaveOrOpenBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  }
  const blobURL = getBlobUrl(blob)
  previewUrl(blobURL)
  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    window.URL.revokeObjectURL(blobURL)
  }, 3000)
}

export const previewUrl = url => {
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = url
  tempLink.target = '_blank'
  document.body.appendChild(tempLink)
  tempLink.click()
  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink)
  }, 3000)
}
