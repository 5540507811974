import React from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import makeStyles from '@mui/styles/makeStyles'
import { useFormikContext } from 'formik'
import _ from 'lodash'

const useStyles = makeStyles(({ palette }) => ({
  helperText: {
    display: 'block',
    color: palette.error.main,
  },
}))

export default ({
  name, // formik field name
  text, // optional override message
}) => {
  const classes = useStyles()
  const { errors, dirty } = useFormikContext()
  if(!dirty) return null
  let helperText = _.get(errors, name)
  if (!text) {
    if (!helperText) return null
    if (_.isPlainObject(helperText)) {
      const vals = _.values(helperText)
      if (_.every(vals, _.isString)) {
        helperText = vals.join('; ')
      } else {
        helperText = `One more values missing from ${name}`
      }
    }
  }
  return (
    <FormHelperText
      component='span'
      className={classes.helperText}
    >
      {text || helperText}
    </FormHelperText>
  )
}
