import { useSelector } from 'react-redux'
import { getRole, getIsMedStaff, getCanViewPHI } from 'app/state/ducks/user/selectors'
import _ from 'lodash'
import {
  systemFeatures,
  systemRoles,
} from 'app/components/Drawer/DrawerContent/Navigation/feature-config'

export default () => {
  const role = useSelector(getRole)
  return {
    role,
    hasAnyRoles: someRoles => _.castArray(someRoles).includes(role),
    isAdmin: role === 'Admin',
    isAccountOwner: role === 'Account Owner',
    isSalesManager: role === 'Sales Manager',
    isProspectAO: role === 'Prospect AO',
    isMedStaff: useSelector(getIsMedStaff),
    canViewPHI: useSelector(getCanViewPHI),
    isSystemFeature: feature => systemFeatures.includes(feature),
    isSystemRole: userRole => systemRoles.includes(userRole),
  }
}