import { all } from 'redux-saga/effects'

import { watchHandleError, watchInitApp } from './appinit/sagas'

import { watchLogin, watchLogout, watchSavePass } from './user/sagas'

import { removeNotification, watchAsk, watchNotice } from './dialog/sagas'

import {
  watchClaimInfoFetch,
  watchGetInsurerGroups,
  watchGetInsurers,
  watchInsuranceEligibility,
  watchLoadActionItems,
  watchLoadPatient,
  watchLoadTest,
  watchLoadVacc,
  watchSaveTest,
  watchSaveVacc,
  watchSubmitClaim,
  watchSubmitSelfTest,
} from './lab/sagas'

export function * rootSaga () {
  yield all([
    watchInitApp(),
    watchHandleError(),
    watchLogin(),
    watchLogout(),
    watchSavePass(),
    watchAsk(),
    watchNotice(),
    removeNotification(),
    watchLoadPatient(),
    watchLoadTest(),
    watchSaveTest(),
    watchSubmitSelfTest(),
    watchLoadVacc(),
    watchSaveVacc(),
    watchLoadActionItems(),
    watchInsuranceEligibility(),
    watchClaimInfoFetch(),
    watchSubmitClaim(),
    watchGetInsurerGroups(),
    watchGetInsurers(),
  ])
}