import SelectField from '../../../../../../components/form/mui-formik/SelectField'
import React from 'react'
import AnswerMessages from './AnswerMessages'

export default ({
  name,
  ...props
}) => {
  return (
    <>
      <SelectField
        name={name}
        {...props}
      />
      <AnswerMessages name={name} />
    </>
  )
}
