import React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import LabScannerButton from './LabScannerButton'
import PhoneIcon from 'app/views/components/icons/PhoneIcon'
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getTotalPendingTests, getTotalPendingVaccs, getTotalTestsToCallAbout } from 'app/state/ducks/lab/selectors'
import makeStyles from '@mui/styles/makeStyles'
import useUserRoles from 'app/views/hooks/useUserRoles'
import ActionItemsButton from 'app/views/layouts/BottomNav/ActionItemsButton'
import TestTubeIcon from 'app/views/components/icons/TestTubeIcon'
import SyringeIcon from 'app/views/components/icons/SyringeIcon'
import GateKeeperButton from './GateKeeperButton'
import { getRole, getUserPermissions } from 'app/state/ducks/user/selectors'
import { systemRoles } from 'app/components/Drawer/DrawerContent/Navigation/feature-config'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.primary.main,
  },
}))

/**
 * A simple example of `BottomNavigation`, with three labels and icons
 * provided. The selected `BottomNavigationItem` is determined by application
 * state (for instance, by the URL).
 */
export default () => {
  const classes = useStyles()
  const location = useLocation()
  const { hasAnyRoles, isMedStaff } = useUserRoles()
  const totalPendingTests = useSelector(getTotalPendingTests)
  const totalTestsToCallAbout = useSelector(getTotalTestsToCallAbout)
  const totalPendingVaccs = useSelector(getTotalPendingVaccs)
  const role = useSelector(getRole)
  const permissions = useSelector(getUserPermissions)
  const { push } = useHistory()
  if(location.pathname === '/') {
    return null
  }
  const labScanItem = {
    component: LabScannerButton,
    enabled: true,
  }
  const gateKeeperItem = {
    component: GateKeeperButton,
    enabled: true,
  }
  const pendingTestsItem = {
    icon: (
      <ActionItemsButton
        Icon={TestTubeIcon}
        count={totalPendingTests}
      />
    ),
    path: '/lab/pending-tests',
    pattern: /\/lab\/pending-tests/,
    enabled: true,
  }
  const pendingVaccsItem = {
    icon: (
      <ActionItemsButton
        Icon={SyringeIcon}
        count={totalPendingVaccs}
      />
    ),
    path: '/lab/pending-vaccs',
    pattern: /\/lab\/pending-vaccs/,
    enabled: false,
  }
  const callForTests = {
    icon: (
      <ActionItemsButton
        Icon={PhoneIcon}
        count={totalTestsToCallAbout}
      />
    ),
    path: '/lab/calls',
    pattern: /\/lab\/calls/,
    enabled: true,
  }

  // ==============================|| BOTTOM NAVIGATION MENU BAR CONFIG BASED ON FEATURE ACCESS ||============================== //
  const bottomMenuConfig = [
    {
      feature: 'Administration of Organization',
      items: [labScanItem, gateKeeperItem, pendingTestsItem, pendingVaccsItem, callForTests],
    },
    {
      feature: 'CRM',
      items: [],
    },
    {
      feature: 'Gatekeeper',
      items: [gateKeeperItem],
    },
    {
      feature: 'Inventory',
      items: [],
    },
    {
      feature: 'Lab Functions',
      items: [labScanItem, pendingTestsItem, pendingVaccsItem, callForTests],
    },
    {
      feature: 'Org Management',
      items: [],
    },
    {
      feature: 'Reporting',
      items: [],
    },
  ]

  let items = []

  if(systemRoles.includes(role)) {
    if(hasAnyRoles(['Account Owner', 'Admin', 'HCP', 'Lab Tech'])) items.push(labScanItem)
    if(hasAnyRoles(['Account Owner', 'Admin', 'HCP', 'Lab Tech', 'Gatekeeper', 'Analyst R', 'Analyst X'])) items.push(gateKeeperItem)
    if(isMedStaff) items.push(pendingTestsItem, pendingVaccsItem)
    if(isMedStaff || hasAnyRoles(['Analyst X'])) items.push(callForTests)
  } else {

    const allowedFeatures = _.chain(permissions).map(perm => {
      if(perm.view) {
        return perm.featureName
      }
    }).compact().value()
    items = _.chain(bottomMenuConfig).filter((features) => allowedFeatures.includes(features.feature)).map('items').flatten().uniq().value()
  }

  if(_.isEmpty(items)) {
    return null
  }

  const selectedIndex = _.findIndex(items, ({ pattern }) => pattern && pattern.test(location.pathname))
  return (
    <BottomNavigation
      value={selectedIndex}
      classes={{
        root: classes.root,
      }}
    >
      {
        _.chain(items).filter('enabled').map((item, i) => {
          const { icon, path } = item
          if(item.component) {
            return (
              <item.component
                key={i}
              />
            )
          }
          return (
            <BottomNavigationAction
              key={i}
              icon={icon}
              onClick={() => push(path)}
            />
          )
        }).value()
      }
    </BottomNavigation>
  )
}