import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Button from '@mui/material/Button'
import React from 'react'
import { useHistory } from 'react-router-dom'
import T from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  leftElement: {
    marginLeft: -12,
    marginRight: 20,
  },
  backLabel: {
    paddingLeft: 10,
  },
})
)

const BackButton = ({ back }) => {
  const { leftElement, backLabel } = useStyles()
  const history = useHistory()

  const clickFunc = () => (back === true || !back.url) ? history.goBack() : history.push(back.url)

  if (back === true || !back.label) {
    return (
      <IconButton key='backButton' onClick={clickFunc} className={leftElement} size="large">
        <ArrowBackIcon />
      </IconButton>
    )
  }

  return (
    <Button
      key='backButton'
      onClick={clickFunc}
      className={leftElement}
    >
      <ArrowBackIcon />
      <span className={backLabel}>{back.label}</span>
    </Button>
  )
}

BackButton.propTypes = {
  back: T.oneOfType([ // Causes a back button to be displayed upper-left. Overrides 'left' prop.
    T.bool, // If true, back button label is "Back" and calls props.goBack()
    T.shape({ // If this shape, a text button is displayed that goes to url
      label: T.string,
      url: T.string,
    }),
  ]),
}

export default BackButton
