export const HEALTH_HISTORY_STATUS = {
  undefined: null,
  uninitialized: 'uninitialized',
  completed: 'completed',
  processing: 'processing',
  fail: 'failed',
}

export const HEALTH_RECORDS_CELL_TYPES = {
  custom: 'custom',
  array: 'array',
  date: 'date',
  coverageOrder: 'coverageOrder',
}

export const HEALTH_RECORDS_INSURANCE_COVERAGE_ORDER = ['Primary', 'Secondary', 'Tertiary']