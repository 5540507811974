import axios from 'axios'
import { nanoid } from 'nanoid'
import semComp from 'semver-compare'
import store from 'utils/local-storage'
import { env } from 'utils/env'
import { isNonEmptyString } from '@sustainhawaii/heal-common/lib/util/string'

export const getAppMeta = () => axios.get(`/meta.json?${nanoid()}`)

export const checkForAppUpdate = async () => {
  try {
    const meta = await getAppMeta()
    const thisVersion = getThisAppVersion()
    const latestVersion = meta?.data?.version
    const updateAvailable = semComp(latestVersion, thisVersion) === 1
    if (!updateAvailable) return false
    store.set('APP_VERSION', latestVersion)
    return true
  } catch (e) {
    // means unknown
  }
}

/**
 * Return version of the currently-loaded web app and store in local storage.
 */
export const getThisAppVersion = () => {
  let result
  const envVer = env('VERSION')
  const storeVer = store.get('APP_VERSION')
  if (isNonEmptyString(envVer) && isNonEmptyString(storeVer)) {
    const verComp = semComp(storeVer, envVer)
    result = verComp === -1 ? envVer : storeVer
  } else {
    result = storeVer || envVer
  }
  store.set('APP_VERSION', result)
  return result
}

