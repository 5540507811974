import _ from 'lodash'
import React from 'react'
import {
  getAnniversaryDateISO,
  getPrettyDate,
  getPrettyDateFromISO,
  isValidISO,
} from './temporal'
import { getIntakeForms } from '../api/user'
import {
  getFullAddressString,
  isValidAddressObject,
} from './address'
import {
  getDivisionId,
  getDivisionProperty,
  getOrgDivisionProperty,
  getOrgProperty,
} from './org'
import {
  arrayHasAnyValues,
  bool2str,
  mapBooleanValsToStrings,
  mapStringValsToBooleans,
  mapValuesDeep,
  objectWithoutNonInfoValues,
  objectWithoutUndefinedValues,
  str2bool,
} from '@sustainhawaii/object-utils'
import { isValid as isValidDriversLicenseUS } from 'driver-license-validator'
import {
  personNameDefault,
  quoteObjectPaths,
} from './form'
import store from './local-storage'
import { mapAwsS3UrlsToFileInputVals } from './aws'

/**
 * Given answer value and intake form key path,
 * return formatted string answer if there is one.
 *
 * @param {*} a - answer value
 * @param {string} k - key path
 * @return {string|undefined}
 */
export const getIntakeFormStringAnswer = ({ a, k }) => {
  // string arrays to comma-separated:
  if(_.isArray(a)) return a.join(', ')
  // Addresses with or without date ranges:
  if(_.isPlainObject(a)) {
    const { description, dateStart, dateEnd } = a
    const fullAddressStr = description || getFullAddressString(a)
    if(fullAddressStr) {
      if(dateStart) return `${fullAddressStr} from ${getPrettyDate({ dateTime: dateStart })} to ${getPrettyDate({ dateTime: dateEnd })}`
      return fullAddressStr
    }
  }
  // Dates:
  if(['birthDate', 'symptomsStarted'].includes(k) || _.startsWith(k, 'date') || (isValidISO(a))) {
    return getPrettyDateFromISO(a)
  }
}

/**
 * Returns unique array of visit types ('test' or 'vacc' so far) based on the 'purposes' of each form (what they were registering for)
 * @returns {Promise<*>}
 */
export const getFamilyIntakeFormVisitTypes = async () => {
  // Get forms to determine which types of visits (test or vacc) the master and companion users are eligible for:
  const forms = await getIntakeForms()
  return _.chain(forms).flatMap('purposes').uniq().filter().map(purpose => purpose === 'covid19test' ? 'test' : 'vacc').value()
}

export const getAgreementConfigs = (fullOrg) => {
  const orgId = getOrgProperty('id')
  const divId = getDivisionId()
  const { agreements: orgAgreements } = getOrgProperty('intakeFormConfig', {})
  const { agreements: divAgreements } = getDivisionProperty({ key: 'intakeFormConfig', df: {} })
  const orgLevels = divAgreements?.org && !fullOrg ? _.pick(orgAgreements, divAgreements.org) : orgAgreements
  const divLevels = divAgreements?.div || {}
  if(!fullOrg) {
    return {
      ..._.mapValues(orgLevels, cfg => ({ ...cfg, orgId, level: 'org' })),
      ..._.mapValues(divLevels, cfg => ({ ...cfg, orgId, divId, level: 'div' })),
    }
  } else {
    return {
      ..._.mapValues(orgLevels, cfg => ({ ...cfg, orgId, level: 'org' })),
      ..._.mapValues(divLevels, cfg => ({ ...cfg, orgId, level: 'div' })),
    }
  }

}

export const getStepConfigs = () => {
  const orgId = getOrgProperty('id')
  const { steps: orgSteps } = getOrgProperty('intakeFormConfig', {})
  const { steps: divSteps } = getDivisionProperty({ key: 'intakeFormConfig', df: {} })
  const orgLevels = orgSteps
  const divLevels = divSteps || {}
  return {
    ..._.mapValues(orgLevels, cfg => ({ ...cfg, orgId, level: 'org' })),
    ..._.mapValues(divLevels, cfg => ({ ...cfg, orgId, level: 'div' })),
  }
}

export const getAgreementConfigsForPurposes = ({
  agreementConfigs,
  purposes,
}) => _.pickBy(agreementConfigs, ({ purposes: stepPurposes }) => purposes && stepPurposes && arrayHasAnyValues(purposes, stepPurposes))

/**
 * Return the keys and lowest signing age of the agreements in the intake form configuration for the current context.
 * @return {{agreementKeys: string[], lowestAgreementAge: number}}
 */
export const getCurrentAgreementsRequirements = () => {
  const configs = getAgreementConfigs()
  return {
    agreementKeys: _.keys(configs) || [],
    lowestAgreementAge: _.chain(configs).map('requiresGuardianSignature').filter().min().value() || 0,
  }
}

const dataCleaningConfig = {
  forceString: [
    'userId',
    'formId',
  ],
  omit: [
    'parentId',
    'isVisiting',
    'scheduledTest',
    'scheduledVacc',
    'cosignerDocumentCount',
    'hadSymptomsRecently',
  ],
  omitBy: {
    travelsLast10Days: o => !isValidAddressObject(o),
    addresses: o => !isValidAddressObject(o),
  },
  omitIfFalse: {
    hadRespiratoryIllness: ['respiratoryIllness'],
    hadContactWithCovidPositive: ['contactWithCovidPositiveDetails'],
    isEssentialWorker: ['essentialWorkerDetails'],
    hadFeverLast30: ['feverLast30Date', 'feverLast30DF'],
  },
  omitNone: [
    'symptoms',
    'riskFactors',
    'essentialWorkerDetails.ppeSelections',
  ],
  omitEmpty: [
    'previousTestInfo.over90daysAgo',
    'previousTestInfo.last3to6days',
  ],
}
// Return data cleaned for sending to API:
export const getCleanedIntakeForm = ({
  data,
  purposes,
  agreementTranslatedData,
}) => {
  // Remove non-info values and convert 'true' and 'false' to boolean:
  let result = _.omit(data, dataCleaningConfig.omit)
  result = objectWithoutNonInfoValues(result)
  result = mapStringValsToBooleans(result)
  // Force some values to be JSON.parse'd as strings:
  quoteObjectPaths(result, dataCleaningConfig.forceString)
  // Omit some values using specific functions:
  _.forEach(dataCleaningConfig.omitBy, (func, key) => {
    const val = _.get(result, key)
    if(val) _.set(result, key, _.omitBy(_.get(result, key), func))
  })
  // Omit some values based on another value:
  _.forEach(dataCleaningConfig.omitIfFalse, (keysToOmit, boolKey) => {
    if(!_.get(result, boolKey)) {
      result = _.omit(result, keysToOmit)
    }
  })
  // Omit 'none' from multiple-choice fields:
  _.forEach(dataCleaningConfig.omitNone, key => {
    const val = _.get(result, key)
    if(val) _.set(result, key, _.without(val, 'none'))
  })
  // Omit some empty fields:
  _.forEach(dataCleaningConfig.omitEmpty, key => {
    if(_.isEmpty(_.get(result, key))) {
      result = _.omit(result, key)
    }
  })
  if(!_.isEmpty(result.vitals?.birthDate)) result.vitals.birthDate = getAnniversaryDateISO(result.vitals.birthDate)
  result.purposes = purposes
  result.agreementTranslatedData = agreementTranslatedData
  return result
}

export const getIntakeFormConfigProperty = ({ path, df }) => getOrgDivisionProperty({
  key: `intakeFormConfig.${path}`,
  df,
})

export const getStepIncludesExtraField = (stepName, fieldName) => {
  const extraFields = getIntakeFormConfigProperty({
    path: `steps.${stepName}.extraFields`,
    df: [],
  })
  return extraFields.includes(fieldName)
}

// Return data from API formatted for display in intake form:
export const getFormCompatibleIntakeFormData = async response => {
  if(_.isEmpty(response?.data)) return
  let formData = mapBooleanValsToStrings(response?.data)
  formData.hasNoSSN = str2bool(formData.hasNoSSN)
  formData = await mapAwsS3UrlsToFileInputVals({
    obj: formData,
    metaOnly: true,
  })
  formData.isVisiting = bool2str(!!formData.addresses?.visiting)
  return formData
}
export const getStepProp = ({
  stepName,
  prop,
  intakeFormConfig,
  divSteps,
  fieldsProps,
}) => _.get(fieldsProps, prop) || _.get(divSteps, `${stepName}.${prop}`) || _.get(intakeFormConfig, `steps.${stepName}.${prop}`)

/**
 *
 * @param {string} stepName - key in allSteps
 * @param {string[]=} purposes - list of purposes for current form
 * @param {object} intakeFormConfig - intake form config of org
 * @param {object=} divSteps - intake form config of div
 * @param {object=} fieldsProps - specific props for the step, if any
 * @return {boolean}
 */
export const stepIsForCurrentPurposes = ({
  stepName,
  purposes,
  intakeFormConfig,
  divSteps,
  fieldsProps,
}) => {
  const stepPurposes = getStepProp({
    stepName,
    prop: 'purposes',
    intakeFormConfig,
    divSteps,
    fieldsProps,
  })
  return purposes && stepPurposes && arrayHasAnyValues(purposes, stepPurposes)
}
/**
 *
 * @param {string} stepName - key in allSteps
 * @param {string=} patientType - patient type of intake form subject
 * @param {object} intakeFormConfig - intake form config of org
 * @param {object=} divSteps - intake form config of div
 * @param {object=} fieldsProps - specific props for the step, if any
 * @return {boolean}
 */
export const stepIsForCurrentPatientTypes = ({
  stepName,
  patientType,
  intakeFormConfig,
  divSteps,
  fieldsProps,
}) => {
  const stepPatientTypes = getStepProp({
    stepName,
    prop: 'patientTypes',
    intakeFormConfig,
    divSteps,
    fieldsProps,
  })
  return !stepPatientTypes || stepPatientTypes.includes(patientType)
}

export const setIntakeFormInProgress = ({
  snapshot,
  purposes,
}) => {
  const intakeFormsInProgress = store.get('intakeFormsInProgress') || {}
  intakeFormsInProgress[snapshot.userId] = {
    snapshot: mapValuesDeep({
      obj: snapshot,
      iter: ({ val }) => val?.isNewFile ? '' : val,
      recursionTest: val => !val?.isNewFile,
    }),
    purposes,
  }
  store.set('intakeFormsInProgress', intakeFormsInProgress)
}
export const getIntakeFormInProgress = userId => store.get(`intakeFormsInProgress.${userId}`)
export const removeIntakeFormInProgress = userId => store.remove(`intakeFormsInProgress.${userId}`)
export const getPatientTypes = () => getOrgDivisionProperty({ key: 'patientTypes' })
const getAgreementDefaultValues = ({ purposes, patientType }) => ({
  agreements: _.chain(getAgreementConfigs())
    .mapValues(({ purposes, patientTypes, iAgree, initials, signature, file }, agreementKey) => ({
      agreementKey,
      purposes,
      patientTypes,
      file,
      ...objectWithoutUndefinedValues(_.mapValues({ iAgree, initials, signature }, v => v ? '' : undefined)),
    }))
    .filter(({
      purposes: agreementPurposes,
      patientTypes,
    }) => (
      (agreementPurposes && arrayHasAnyValues(agreementPurposes, purposes))
      && (!patientTypes || patientTypes.includes(patientType))
    ))
    .keyBy('agreementKey')
    .mapValues(vals => vals.file ? '' : _.pick(vals, ['iAgree', 'initials', 'signature']))
    .value(),
})
const getFilteredDefaultValues = ({ topKeys, purposes, patientType }) => {
  const defaultValues = getAllDefaultValues()
  return {
    ..._.pick(defaultValues, topKeys),
    ...getAgreementDefaultValues({ purposes, patientType }),
  }
}
const addNoneIfEmpty = (data, key, topKeys) => {
  const topKey = key.split('.')[0]
  if(!topKeys.includes(topKey)) return
  const arr = _.get(data, key)
  if(_.isArray(arr) && _.isEmpty(arr)) arr.push('none')
}
export const getInitialValuesFromHealthIDs = ({
  pass,
  masterPass,
}) => ({
  ..._.pick(masterPass, ['phoneNumber', 'email']),
  ..._.pick(pass || {}, ['userId', 'name', 'email', 'phoneNumber', 'vitals', 'addresses']),
})
const getDynamicQuestionInitialValues = (dynamicQuestionNames = [], prefillData) => _.chain(dynamicQuestionNames)
  .map(name => ({
    key: name,
    val: {
      answer: _.get(prefillData, `dynamicQuestions.${name}.answer`, ''),
      other: _.get(prefillData, `dynamicQuestions.${name}.other`, ''),
      agreement: _.get(prefillData, `dynamicQuestions.${name}.agreement`, { signature: '' }),
    },
  }))
  .keyBy('key')
  .mapValues('val')
  .value()
export const getInitialValues = ({
  prefillData,
  purposes,
  patientType,
  topKeys,
  dynamicQuestionNames,
}) => {
  const result = _.merge(
    { patientType },
    getFilteredDefaultValues({ topKeys, purposes, patientType }),
    _.pick(prefillData, [...topKeys, ...dynamicQuestionNames, 'name', 'vitals', 'email', 'phoneNumber', 'addresses']),
  )
  _.forEach(['symptoms', 'riskFactors', 'essentialWorkerDetails.ppeSelections'], key => {
    addNoneIfEmpty(result, key, topKeys)
  })
  result.hadSymptomsRecently = ''
  result.dynamicQuestions = getDynamicQuestionInitialValues(dynamicQuestionNames, prefillData)
  return result
}

/**
 * Return an inline agreement configured for a dynamic question
 * if current answer makes it required
 *
 * @param {InlineAgreementConfig[]=} agreementConfigs
 * @param {string} answer
 * @return {InlineAgreementConfig|void}
 */
export const getDynamicQuestionAgreementForAnswer = ({
  agreementConfigs,
  answer,
}) => {
  const answerArray = _.castArray(answer)
  return _.find(agreementConfigs, ({ answers }) => _.isEmpty(answers) || !_.isEmpty(_.intersection(answers, answerArray)))
}

export const idConfigs = {
  driversLicenseUS: {
    numberValidator: isValidDriversLicenseUS,
    issuerType: 'state',
    preferred: true,
  },
  stateIdUS: {
    numberValidator: n => /^[a-zA-Z0-9-./]{3,25}$/i.test(n), issuerType: 'state',
    preferred: true,
  },
  passport: {
    numberValidator: n => /^[a-z][a-z0-9]{2,16}$/i.test(n),
    issuerType: 'country',
    preferred: false,
  },
  workStudentId: {
    // MAYBE find better validator since this is a copy of the state ID validator
    numberValidator: n => /^[a-zA-Z0-9-./]{3,25}$/i.test(n),
    preferred: false,
  },
}

export const idDefault = {
  photo: '',
  photoBack: '',
  number: '',
  issuer: '',
}
export const identificationDefaults = _.mapValues(idConfigs, () => idDefault)

const addressDefault = {
  country: 'US',
}
const destinationDefault = {
  address: addressDefault,
  dateStart: null,
  dateEnd: null,
}
const insuranceAddressDefault = {
  country: '',
  state: '',
}
const insuranceVitalsDefault = {
  gender: '',
  birthDate: '',
}
export const insuranceDefault = {
  photo: '',
  photoBack: '',
  name: '',
  group: '',
  otherName: '',
  subscriberNumber: '',
  subscriberName: personNameDefault,
  subscriberAddress: insuranceAddressDefault,
  subscriberVitals: insuranceVitalsDefault,
  eligibility: '',
}
export const secondaryInsuranceDefault = {
  photo: '',
  photoBack: '',
  name: '',
  group: '',
  otherName: '',
  subscriberNumber: '',
  subscriberName: personNameDefault,
  subscriberAddress: insuranceAddressDefault,
  eligibility: '',
}
export const socialSecurityDefault = {
  number: '',
}
const checkBoxGroupsWithOtherChoiceNames = [
  'race',
  'communityHousing',
  'shelters',
  'encampments',
  'socialServiceOrgs',
  'statuses',
]
const emptyFieldList = [
  'homeType',
  'homelessType',
  'haveHomelessDocs',
  'hispanic',
  'liveOrWorkCongregate',
  'congregateType',
  'previouslyTested',
  'previouslyVaccinated',
  'previouslyVaccinatedC19',
  'hasInsurance',
  'pregnant',
  'employedInHealthCare',
  'hospitalizedForCovid',
  'icuForCovid',
  'covidExposureConcern',
  'publicGatherings',
  'workedPublicHighContact',
  'contactWithHighRiskGroups',
  'hadFeverLast30',
  'feverLast30DF',
  'traveledLast10Days',
  'hadRespiratoryIllness',
  'isGuardian',
  'deptPublicSafety',
  'feelSick',
  'vaccineAllergyEn',
  'vaccineAllergyEs',
  'bleedingDisorder',
  'immunocompromised',
  'otherVaccinationLast14days',
  'covidPositiveLast90days',
  'covidTreatment',
  'dermalFiller',
  'allergies',
  'nka',
  'maritalStatus',
  'tribalAffiliation',
  'language',
  'languageOther',
  'divisionEmail',
  'isVisitingHotel',
  'visitingHotelRoom',
  'ethnicity',
  'hasNoSSN',
  'hadContactWithCovidPositive',
  'isEssentialWorker',
  'patientRelationshipToInsured',
  ..._.flatMap(checkBoxGroupsWithOtherChoiceNames, name => [name, `${name}Other`]),
]
const emptyFields = _.chain(emptyFieldList).invert().mapValues(() => '').value()
export const ksSchoolsDefaults = {
  affiliated: '',
  patientType: '',
  patientTypeOther: '',
  studentGrade: '',
  teacherGrades: [],
  familyRelationship: '',
  familyRelationshipOther: '',
}
export const getAllDefaultValues = () => {
  // TODO: add dynamic questions defaults (answer: '')
  const specialQuestions = getOrgProperty({ key: 'intakeFormConfig.specialQuestions' })
  let specialQuestionsDefault = {}
  if(!_.isEmpty(specialQuestions)) {
    // Replace all question strings with ''
    specialQuestionsDefault = mapValuesDeep({
      obj: specialQuestions,
      iter: () => '',
    })
  }
  return {
    ...emptyFields,
    nka: false,
    allergies: '',
    symptomsStarted: null,
    feverLast30Date: null,
    name: personNameDefault,
    cosigner: {
      name: personNameDefault,
      phoneNumber: '',
      // email: '',
    },
    addresses: {
      home: addressDefault,
      visiting: addressDefault,
    },
    guardianInfo: {
      typeOf: '',
      otherType: '',
    },
    socialSecurity: socialSecurityDefault,
    education: {
      schoolGrade: '',
    },
    identification: identificationDefaults,
    ksSchools: ksSchoolsDefaults,
    insuranceCarriers: {
      primary: insuranceDefault,
      secondary: secondaryInsuranceDefault,
    },
    previousTestInfo: {
      testResult: '',
      last3to6days: '',
      over90daysAgo: '',
    },
    previousVaccInfo: {
      date: null,
      dosagePhase: '',
      manufacturer: '',
    },
    symptoms: [],
    riskFactors: [],
    travelsLast10Days: {
      destination1: destinationDefault,
      destination2: destinationDefault,
      destination3: destinationDefault,
    },
    respiratoryIllness: {
      sawProvider: '',
      symptomsDate: null,
      diagnosis: '',
      wasTreated: '',
      testedForFluOrStrep: '',
      fluOrStrepResult: '',
      symptoms: '',
    },
    contactWithCovidPositiveDetails: {
      awaitingResults: '',
      where: '',
      when: null,
      travelHistory: '',
      workHistory: '',
      quarantined: '',
    },
    essentialWorkerDetails: {
      organization: '',
      position: '',
      departments: '',
      fullTime: '',
      contactWithPatients: '',
      ppeSelections: [],
      ppeOther: '',
    },
    job: {
      title: '',
      duties: '',
    },
    emergencyContact: {
      name: personNameDefault,
      phoneNumber: '',
    },
    employer: {
      name: '',
      phoneNumber: '',
    },
    ...specialQuestionsDefault,
  }
}