import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { getAlert } from 'app/state/ducks/dialog/selectors'
import actions from 'app/state/ducks/actions'

const { notice, alert, confirm, details, loading } = actions.dialog

export default () => {
  const alertState = useSelector(getAlert)
  const dispatch = useDispatch()
  return {
    notice: params => {
      return dispatch(notice.add(params))
    },
    removeNotice: params => dispatch(notice.removeNotification(params)),
    alert: params => dispatch(alert.open(params)),
    alertState,
    confirm: params => {
      return new Promise((resolve) => {
        params.callback = resolve
        dispatch(confirm.ask(params))
      })
    },
    details: {
      open: params => dispatch(details.open(params)),
      close: params => dispatch(details.close(params)),
    },
    loading: params => dispatch(loading[params ? 'open' : 'close'](params)), // calling with no params closes it
  }
}