import { createActions } from 'redux-actions'
import phases from '../actionPhases'

export default createActions({
  APPINIT: phases.basic,
  BACKGROUND_SYNC: {
    START: undefined,
    STOP: undefined,
  },
  HANDLE_ERROR: undefined,
  BEGIN_APP_UPDATE: undefined,
  THEME: undefined,
})