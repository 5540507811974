import { useFormikContext } from 'formik'
import { Box } from '@mui/material'
import _ from 'lodash'
import MarkDownContainer from '../../../../../../components/MarkDownContainer'
import { getIntakeFormConfigProperty } from '../../../../../../../../utils/intake-form'
import { useMemo } from 'react'
import { str2bool } from '@sustainhawaii/object-utils'

export default ({
  name,
}) => {
  const { values } = useFormikContext()
  const answer = _.get(values, name)
  const messages = useMemo(
    () => {
      const answerMessages = getIntakeFormConfigProperty({ path: 'answerMessages', df: [] })
      return _.chain(answerMessages)
        .filter(({
          path,
          answers,
        }) => path === name && (answers.includes(answer) || answers.includes(str2bool(answer))))
        .map('message')
        .value()
    }, [name, answer])
  if(_.isEmpty(messages)) return null
  return (
    <Box
      display='flex'
      flexDirection='column'
      mt={1}
    >
      {_.map(messages, (message, i) => (
        <MarkDownContainer
          key={i}
          markdown={message}
        />
      ))}
    </Box>
  )
}
