import { visitTypes } from '../config/const.json'
import _ from 'lodash'
import store from './local-storage'

/**
 @typedef {object} FullName
 @property {string} first
 @property {string} last
 @property {string=} middleInitial
 @property {string=} suffix
 */

/**
 *
 * @param {object} o
 * @param {FullName} o.name
 * @return {string}
 */
export const getFullName = o => o?.name
  ? _.chain(o).get('name').values().filter().join(' ').trim().value()
  : ''
export const getGenderLabel = gender => _.chain(store.get('constants.GENDERS'))
  .find({ value: gender })
  .get('label')
  .value()

export const stringRegexTest = (str, rgx) => {
  if(!_.isString(str)) return false
  return rgx.test(str)
}

export const getVisitTypeName = visitType => visitTypes[visitType]

export const getPassLabel = () => store.get('constants.PASS_LABEL') || 'Health ID'

export const commaListWithAnd = ({
  list,
  quoted = false,
}) => {
  const formatted = quoted ? _.map(list, item => `"${item}"`) : [...list]
  const last = formatted.pop()
  return _.chain([formatted.join(', '), last]).filter().join(' and ').value()
}

/**
 * Convert string to snake case, then start case, then replace spaces with "_"
 *
 * @example "The fazy fox Jumped" > "The_Fazy_Fox_Jumped"
 * @param str
 */
export const snakeStartCase = str => _.chain(str).snakeCase().startCase().replace(/\s/g, '_').value()

/**
 * Return true if value is truthy, except if value is string 'false'
 * @param {*=} v
 * @return {boolean}
 */
export const isTruthy = v => v === 'false' ? false : !!v
