import LinearProgress from '@mui/material/LinearProgress'
import withStyles from '@mui/styles/withStyles'

export default withStyles(({ palette }) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: palette.grey[palette.mode === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: palette.secondary.main,
  },
}))(LinearProgress)
