// material-ui
import { Box, useMediaQuery } from '@mui/material'

// project import
import Search from './Search'
import Profile from './Profile'
import { useSelector } from 'react-redux'
import { getOrg } from '../../../../utils/org'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const org = getOrg()
  const homeLink = () => {
    window.location.href = '/'
  }
  
  return (
    <>
      {org.id === 'truewellth' && <img src={`/images/logom8.gif`} style={{ cursor: 'pointer' }} className={'logo_img'} onClick={homeLink} /> }
      {org.id === 'medea' && <img src={`/images/medea/medea.svg`} style={{ cursor: 'pointer' , padding : '30px' }} className={'logo_img'} onClick={homeLink} /> }
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      
      {<Profile />}
    </>
  )
}

export default HeaderContent