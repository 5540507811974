import React from 'react'
import SceneLoadingProgress from 'app/views/components/SceneLoadingProgress'
import SceneScrollableContent from 'app/views/layouts/Scene/SceneScrollableContent'
import T from 'prop-types'

const SceneContent = ({
  children,
  loading,
  themeBg,
  scrollableContent,
  preScrollableContent,
  scrollableContentLoading,
  postScrollableContent,
}) => {
  if (loading) return (
    <SceneLoadingProgress
      message={loading}
      themeBg={themeBg}
    />
  )

  if (scrollableContent) {
    return (
      <SceneScrollableContent
        preScrollableContent={preScrollableContent}
        scrollableContent={scrollableContent}
        scrollableContentLoading={scrollableContentLoading}
        postScrollableContent={postScrollableContent}
      />
    )
  }

  return children || null
}

SceneContent.propTypes = {
  themeBg: T.string,
  loading: T.oneOfType([T.bool, T.string]),
  scrollableContent: T.func,
  preScrollableContent: T.element,
  scrollableContentLoading: T.bool,
  postScrollableContent: T.element,
}

export default SceneContent
