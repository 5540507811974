// ==============================|| OVERRIDES - TABS ||============================== //

export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            height : '2.4375em'
          },
        }
      }
    }
  }
}
