import PropTypes from 'prop-types'
import React, {
  useMemo,
  useRef,
  useState,
} from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'

// project import
import MainCard from 'app/components/MainCard'
import Transitions from 'app/components/Transitions'
import ProfileTab from './ProfileTab'
import PersonIconStyled from 'app/views/components/AvatarStyled'

import { getDivisionProperty } from '../../../../../utils/org'
import useUserActions from '../../../../views/hooks/useUserActions'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../state/ducks/user/selectors'
import DivisionSwitcher from '../../../../views/layouts/Header/AuthMenu/DivisionSwitcher'
import useMediaQuery from '@mui/material/useMediaQuery'
import UIPalette from '../../../../views/layouts/Header/AuthMenu/UIPalette'
import EditProfile from './EditProfile'

// tab panel wrapper
function TabPanel ({ children, value, index, ...other }) {
  return (
    <div role='tabpanel' hidden={value !== index} id={`profile-tabpanel-${index}`}
         aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))
  const user = useSelector(getUser)
  const {
    userId,
  } = user ? user : {}
  let first = ''
  let last = ''
  let gender = ''
  if(user && user['passes'] && user['passes'][userId]) {
    first = user['passes'][userId]['name']['first'] ? user['passes'][userId]['name']['first'] : ''
    last = user['passes'][userId]['name']['last'] ? user['passes'][userId]['name']['last'] : ''
    gender = user['passes'][userId]['vitals'] ? user['passes'][userId]['vitals']['gender'] : ''
  }
  const divisionName = useMemo(() => getDivisionProperty({ key: 'name' }))
  const { logout } = useUserActions()

  const handleLogout = async () => {
    // logout
    logout()
  }

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [divSwitcherOpen, setDivSwitcherOpen] = useState(false)
  const [uiPaletteOpen, setUiPaletteOpen] = useState(false)
  const [editProfileOpen, setEditProfileOpen] = useState(false)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if(anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleSwitchDivision = () => {
    setDivSwitcherOpen(true)
    setOpen(false)
  }

  const handleUIPalette = () => {
    setUiPaletteOpen(true)
    setOpen(false)
  }

  const handleEditProfile = () => {
    setEditProfileOpen(true)
    setOpen(false)
  }

  const iconBackColorOpen = 'grey.300'

  return user ? (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          border : '2px solid',
          borderColor : 'primary.dark',
          '&:hover': { bgcolor: 'primary.dark' },
        }}
        aria-label='open profile'
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Stack direction='row' spacing={2} alignItems='center' sx={{ p: 0.5 }}>
          <Avatar alt='profile user' sx={{ width: 24, height: 24 }}>
            <PersonIconStyled />
          </Avatar>
          <Typography color={'primary.dark'} sx={{ fontWeight : 200, display: !matchDownMD ? 'block' : 'none' }}
                      variant='subtitle1'>{`${first} ${last}`}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type='fade' in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <Stack direction='row' spacing={1.25} alignItems='center'>
                            <Avatar alt='profile user' sx={{ width: 32, height: 32 }}>
                              <PersonIconStyled />
                            </Avatar>
                            <Stack>
                              <Typography variant='h6'>{`${first} ${last}`}</Typography>
                              <Typography variant='body2' color='textSecondary'>
                                {divisionName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <ProfileTab
                          onSwitchDivision={handleSwitchDivision}
                          onUIPalette={handleUIPalette}
                          onEditProfile={handleEditProfile}
                          handleLogout={handleLogout} />
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
      <DivisionSwitcher
        isOpen={divSwitcherOpen}
        handleClose={() => setDivSwitcherOpen(false)}
      />
      <UIPalette
        isOpen={uiPaletteOpen}
        handleClose={() => setUiPaletteOpen(false)}
      />
      <EditProfile open={editProfileOpen} close={() => setEditProfileOpen(false)} />
    </Box>
  ) : <></>
}

export default Profile