import _ from 'lodash'

const formikProps = [
  'children',
  'dirty',
  'enableReinitialize',
  'errors',
  'getFieldHelpers',
  'getFieldMeta',
  'getFieldProps',
  'handleBlur',
  'handleChange',
  'handleReset',
  'handleSubmit',
  'initialErrors',
  'initialStatus',
  'initialTouched',
  'initialValues',
  'isInitialValid',
  'isSubmitting',
  'isValid',
  'isValidating',
  'onReset',
  'onSubmit',
  'registerField',
  'unregisterField',
  'render',
  'resetForm',
  'setErrors',
  'setFieldError',
  'setFieldTouched',
  'setFieldValue',
  'setFormikState',
  'setStatus',
  'setSubmitting',
  'setTouched',
  'setValues',
  'submitCount',
  'submitForm',
  'touched',
  'validate',
  'validateField',
  'validateForm',
  'validateOnBlur',
  'validateOnChange',
  'validateOnMount',
  'validationSchema',
  'values',
]

export const noFormikProps = props => _.omit(props, formikProps)
