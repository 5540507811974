import themeTemplate from 'config/theme.json'
import { useMemo } from 'react'
import Palette from 'app/views/themes/palette'
import Typography from 'app/views/themes/typography'
import CustomShadows from 'app/views/themes/shadows'
import { createTheme } from '@mui/material/styles'
import componentsOverride from 'app/views/themes/overrides'

export const getTheme = ({ themeDirection, mode, presetColor, fontFamily }) => {
  const theme = Palette(mode, presetColor)
  const themeTypography = Typography(fontFamily)
  const themeCustomShadows = CustomShadows(theme)

  const themeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1266,
        xl: 1536,
      },
    },
    direction: themeDirection,
    mixins: {
      toolbar: {
        minHeight: 60,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    palette: theme.palette,
    customShadows: themeCustomShadows,
    typography: themeTypography,
  }


  const themes = createTheme(themeOptions)
  themes.components = componentsOverride(themes)
  return themes
}
