import actions from 'app/state/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getIsOpen, getMainMenuItems } from 'app/state/ducks/menu/selectors'
import useTranslateDictionary from './useTranslateDictionary'

export default () => {
  const items = useSelector(getMainMenuItems)
  const trans = useTranslateDictionary({
    nameSpace: 'scenes',
    path: 'mainMenu',
  })
  const dispatch = useDispatch()
  return {
    items: items.map(({ name, path, ...rest }) => ({
      name: /\badmin\b/.test(path) ? name : trans[_.camelCase(name)],
      path,
      ...rest,
    })),
    isOpen: useSelector(getIsOpen),
    onToggle: params => dispatch(actions.menu.toggle(params)),
  }
}
