import { createSlice } from '@reduxjs/toolkit'

const gatekeeper = createSlice({
  name: 'gatekeeper',
  initialState: {
    divId: '',
    locId: '',
  },
  reducers: {
    setDivId (state, { payload }) {
      state.divId = payload
    },
    setLocId (state, { payload }) {
      state.locId = payload
    },
  },
})

const { actions, reducer } = gatekeeper

export const { setDivId, setLocId } = actions

export default reducer
