import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default props => (
  <SvgIcon
    x='0px' y='0px' viewBox='0 -5 36 45'
    {...props}
    color='action'
  >
    <path d='M26,2H10a1,1,0,0,0,0,2h1V28.86A5.07156,5.07156,0,0,0,16,34h4a5.07156,5.07156,0,0,0,5-5.14V4h1a1,1,0,0,0,0-2ZM20,25a.99974.99974,0,0,1-1,1H17a.99974.99974,0,0,1-1-1V11a.99974.99974,0,0,1,1-1h2a.99974.99974,0,0,1,1,1Z' />
  </SvgIcon>
)
