// ==============================|| OVERRIDES - TAB ||============================== //

export default function StepLabel(theme) {
  return {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.98rem'
        }
      }
    }
  }
}
