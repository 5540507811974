// MUI TextField wrapped for use with Formik

import React from 'react'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { noFormikProps } from 'utils/formik'
import InputMask from 'react-input-mask'
import MiuTextField from '@mui/material/TextField'
import useTranslateValue from '../../../hooks/useTranslateValue'

export default ({
  type = 'text',
  margin = 'normal',
  variant = 'outlined',
  name,
  label, // can be t:namespace:path...
  mask,
  ...rest
}) => {
  const labelT = useTranslateValue(label)
  return (
    <Field
      component={mask ? null : TextField}
      {...{
        type,
        name,
        variant,
        label: labelT,
        margin,
        ...noFormikProps(rest),
      }}
    >
      {mask && (({ field }) => (
        <InputMask
          {...field}
          mask={mask}
        >
          {(innerProps) => (
            <MiuTextField
              {...noFormikProps(rest)}
              {...innerProps}
              variant={variant}
              label={labelT}
            />
          )}
        </InputMask>
      ))}
    </Field>
  )
}
