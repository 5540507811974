import {
  FormControlLabel,
  Switch,
} from '@mui/material'
import FormikDebug from '../FormikDebug'
import React, { useState } from 'react'
import { isEnv } from 'utils/env'

export default () => {
  const [debug, setDebug] = useState(false)
  if (isEnv('production')) return null
  return (
    <>
      <FormControlLabel
        control={<Switch checked={debug} onChange={e => setDebug(e.target.checked)} name='debug' />}
        label='Debug'
      />
      {debug && <FormikDebug />}
    </>
  )
}
