import React from 'react'
import QrReader from 'react-qr-reader'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  container: {
    width: '80vw',
    maxWidth: '100%',
  },
}))

export default ({
  onResult,
  onErr,
}) => {
  const classes = useStyles()
  return (
    <QrReader
      onError={onErr}
      onScan={data => {
        if (data) {
          onResult(data)
        }
      }}
      showViewFinder={false}
      className={classes.container}
    />
  )
}
