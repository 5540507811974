const basic = {
  START: undefined,
  SUCCESS: undefined,
  FAILURE: undefined,
}

export const fluxStandard = [
  ({ payload }) => payload,
  ({ meta }) => meta,
]

export default {
  basic,
  promise: fluxStandard,
  formik: {
    ...basic,
    START: fluxStandard,
  },
}
