import _ from 'lodash'
import { objArray2KeyValuePairs } from '@sustainhawaii/object-utils'
import store from './local-storage'

export const getOrg = () => store.get('constants.org') || {}

export const getOrgProperty = (key, df) => _.get(getOrg(), key, df)

/**
 * Returns a division config of the current org.
 * If divisionId is not passed, return the active division, if any.
 *
 * @param {string=} divisionId
 * @returns {object|void}
 */
export const getDivision = ({ divisionId } = {}) => {
  const { divisions, division: orgDivision } = getOrg()
  return divisionId && divisions ? _.find(divisions, { id: divisionId }) : orgDivision
}
export const getDivisionProperty = ({ key, divisionId, df }) => {
  const division = getDivision({ divisionId })
  return _.get(division, key, df)
}
export const getCurrentDivisionId = () => getDivisionProperty({ key: 'id' })
export const getDivisions = ({
  divisionIds,
  eventAttribs,
  withLocations = false,
  forReporting = false,
} = {}) => {
  let divisions = getOrgProperty('divisions')
  if(!divisions) return
  if(!forReporting) divisions = _.reject(divisions, 'onlyForReporting')
  if(!_.isEmpty(divisionIds)) divisions = _.filter(divisions, ({ id }) => divisionIds.includes(id))
  if(withLocations) divisions = _.filter(divisions, ({ locations }) => !_.isEmpty(locations))
  if(_.isEmpty(divisions)) return divisions
  const eventFilter = getEventFilter(eventAttribs)
  if(!eventFilter) return divisions
  return _.filter(
    divisions,
    ({ locations }) => _.some(
      locations,
      ({ events }) => _.some(
        events,
        eventFilter,
      ),
    ),
  )
}
export const getDivisionNamesById = filters => {
  const divisions = _.sortBy(getDivisions(filters), ({ name }) => _.toLower(name))
  return objArray2KeyValuePairs(divisions, 'id', 'name')
}
export const getAllDivisionLocations = ({
  locTypes,
  locId,
  divisionId,
} = {}) => {
  if(divisionId === 'all') return getSomeOrgLocations({ locTypes })
  const division = getDivision({ divisionId })
  const locations = division?.locations
  if(!locations) return []
  return locId
    ? _.filter(locations, { id: locId })
    : filterLocationsByTypes({ locations, locTypes })
}
export const getSomeDivisionLocations = ({
  locTypes,
  locId,
  divisionId,
  eventAttribs,
} = {}) => {
  const locations = getAllDivisionLocations({ locTypes, locId, divisionId })
  if(_.isEmpty(locations)) return []
  const eventFilter = getEventFilter(eventAttribs)
  if(!eventFilter) return locations
  return _.filter(locations, ({ events }) => _.some(events, eventFilter))
}
export const getDivisionSchedulers = ({
  locId,
  divisionId,
  eventAttribs,
} = {}) => {
  const locations = getAllDivisionLocations({
    locTypes: ['Medical'],
    locId,
    divisionId,
  })
  if(_.isEmpty(locations)) return []
  let eventFilter = getEventFilter(eventAttribs)
  if(!eventFilter) eventFilter = () => true
  const orgId = getOrgProperty('id')
  return _.chain(locations)
    .map(({ events }) => _.map(events, (evt, type) => ({
      ...evt,
      type,
    })))
    .flatten()
    .filter(eventFilter)
    .map(({ slug, ...rest }) => ({
      url: `https://calendly.com/perseus-org-${orgId}/${slug}`,
      slug,
      ...rest,
    }))
    .value()
}
export const getDivisionSchedulersVisitTypes = filters => {
  const schedulers = getDivisionSchedulers(filters)
  return _.chain(schedulers).map('type').uniq().filter().value()
}
/**
 * Return function for use with lodash.filter.
 * Returns void if none of the parameters are truthy.
 *
 * @param {boolean=} visitType - if set, reject events of other types
 * @param {string=} patientType - if set, reject events that have a patientTypes array does does not contain this value
 * @return {function}
 */
export const getEventFilter = ({
  visitType,
  patientType,
} = {}) => {
  if(!visitType && !patientType) return
  return event => {
    if(!event) return false
    const { type, patientTypes } = event
    if(visitType && type !== visitType) return false
    return !(patientType && _.isArray(patientTypes) && !patientTypes.includes(patientType))
  }
}
/**
 * Return a property of the active or specified division, falling back to a property of the org.
 *
 * @param {string} key
 * @param {string=} divisionId - defaults to active division
 * @param {*=} df - default if not found
 * @returns {*}
 */
export const getOrgDivisionProperty = ({
  divisionId,
  key,
  df,
}) => getDivisionProperty({
  key,
  divisionId,
  df: getOrgProperty(key, df),
})

export const getOrgDivisionInsuranceAbility = ({ divisionId } = {}) => getOrgDivisionProperty({
  divisionId,
  key: 'insuranceEnabled',
  df: false,
})

export const getSomeOrgLocations = ({
  divisionIds,
  locationIds,
  locTypes,
}) => {
  const divisions = getDivisions({ divisionIds })
  const locations = _.chain(divisions).flatMap(({ locations }) => locations).filter(loc => !!loc && (_.isEmpty(locationIds) || locationIds.includes(loc.id))).value()
  return filterLocationsByTypes({ locations, locTypes })
}

const filterLocationsByTypes = ({
  locations,
  locTypes,
}) => {
  if(_.isArray(locTypes)) return _.filter(locations, ({ types }) => !_.isEmpty(_.intersection(types, locTypes)))
  return locations
}

export const getOrgLocationNamesByLocId = filters => {
  const locations = getSomeDivisionLocations(filters)
  if(_.isEmpty(locations)) return {}
  const sorted = _.sortBy(locations, ({ name }) => _.toLower(name))
  return objArray2KeyValuePairs(sorted, 'id', 'name')
}

export const getOrgLocation = locId => {
  const locations = getSomeDivisionLocations({ locId, divisionId: 'all' })
  return _.find(locations, { id: locId })
}

export const getOrgLocationName = locId => {
  const loc = getOrgLocation(locId)
  return loc?.name
}

export const getDivisionOrOrgVisitFormConfig = ({ visitType, divisionId }) => getOrgDivisionProperty({
  key: `visitConfigs.${visitType}`,
  divisionId,
})

export const getDivisionId = () => getDivisionProperty({ key: 'id' })

/**
 * This is used to merge divSteps with org dynamic questions where division dynamic question marked as org true
 * @param steps
 */
export const mergeOrgDivisionDynamicQuestions = (steps) => {
  const orgDynamicQuestions = getOrgProperty('intakeFormConfig.dynamicQuestions')
  if(!orgDynamicQuestions) return
  _.map(steps, (stepValue, stepKey) => {
    if(stepValue.org) {
      const dynamicQuestion = _.merge({}, orgDynamicQuestions[stepKey], steps[stepKey])
      delete dynamicQuestion.org
      steps[stepKey] = dynamicQuestion
    }
  })
}

/**
 * Get list of stateShort & stateName for stateSelector component
 */
export const getStateSelectors = ({} = {}) => {
  let divisions = getOrgProperty('divisions')
  if(!divisions) return
  divisions = _.filter(divisions, ({ locations }) => !_.isEmpty(locations))

  let stateArray = []
  _.forEach(divisions, division => {
    _.forEach(division.locations, loc => {
      stateArray.push({ 'value': loc.stateShort, 'label': loc.stateName ? loc.stateName : loc.stateLong })
    })
  })

  const result = _.chain(stateArray)
    .uniqBy('value')
    .sortBy('label')
    .compact()
    .value()
  return result
}

/**
 * Get map of stateShort to the associated divs (using their locations)
 */
export const getDivisionsByStates = ({} = {}) => {
  let divisions = getOrgProperty('divisions')
  if(!divisions) return
  divisions = _.filter(divisions, ({ locations }) => !_.isEmpty(locations))

  let divArray = []
  _.forEach(divisions, division => {
    const uniqStates = _.map(_.uniqBy(division.locations, 'stateShort'), 'stateShort')
    _.forEach(uniqStates, loc => {
      divArray.push({ 'value': division.id, 'label': division.name, 'stateShort': loc })
    })
  })

  const result = _.chain(divArray)
    .groupBy((div) => {
      const stateShort = div.stateShort
      delete div.stateShort
      return stateShort
    })
    .value()
  return result
}

/**
 * Check if current div is part of input state
 */
export const isCurrentDivInState = ({ stateShort }) => {
  const division = getDivision()
  const result = _.some(division.locations, ['stateShort', stateShort])
  return result
}