import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import _ from 'lodash'
import LinearProgress from '../progress/LinearProgress'

const styler = withStyles(theme => ({
  root: {
    height: 100,
    padding: theme.spacing(3),
  },
  progress: {
    width: '100%',
  },
}))

class Loading extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    circleSize: PropTypes.number,
    typographyProps: PropTypes.object,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    open: false,
    message: '',
    circleSize: 36,
    typographyProps: {
      variant: 'h6',
    },
  }

  render () {
    const {
      open,
      message,
      progress,
      circleSize,
      typographyProps,
      classes,
    } = this.props
    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth='xs'
      >
        <Box
          display='flex'
          flexDirection={_.isNumber(progress) ? 'column' : 'row'}
          justifyContent='space-around'
          alignContent='center'
          alignItems='center'
          className={classes.root}
        >
          <Typography {...typographyProps}>{message}</Typography>
          {_.isNumber(progress) && (
            <div className={classes.progress}>
            <LinearProgress
              variant='determinate'
              value={progress}
            />
            </div>
          )}
          {!_.isNumber(progress) && (
            <CircularProgress
              size={circleSize}
            />
          )}
        </Box>
      </Dialog>
    )
  }
}

const mapStateToProps = state => state.dialog.loading

export default compose(connect(mapStateToProps), styler)(Loading)
