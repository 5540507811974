import React, { useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { useSelector } from 'react-redux'
import { getMasterPass, getUser } from '../../../../state/ducks/user/selectors'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { setUserProperty } from '../../../../../api/user'
import {
  schemas,
} from '@sustainhawaii/heal-schemas'
import _ from 'lodash'
import CircularProgressStyled from '../../../../views/components/progress/CircularProgressStyled'

const { string } = schemas

export default ({ open, close }) => {
  const { t } = useTranslation()
  const [isEdited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)
  const masterPass = useSelector(getMasterPass)
  const emailFromPass = _.get(masterPass, 'email')
  const [email, setEmail] = useState(emailFromPass || '')
  const title = 'Edit Profile'

  const saveProfile = async () => {
    // Save the new email id
    const validEmail = string().email().isValidSync(email)
    if(!_.isEmpty(email) && validEmail) {
      setLoading(true)
      setEdited(false)
      const userData = { email }
      await setUserProperty(userData)
    }
    setLoading(false)
    await close()
  }
  const handleEmailChange = (evt) => {
    // check user email equal to evt.target.value
    const validEmail = string().email().isValidSync(evt.target.value)
    setEmail(evt.target.value)
    if(validEmail) {
      setEdited(true)
    } else {
      setEdited(false)
    }

  }
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby={title}
      aria-describedby={title}
    >
      {<DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Box m={1}>
          <TextField
            name='email'
            label={t('glossary:emailAddress')}
            type='email'
            value={email}
            autoComplete='email'
            onChange={handleEmailChange}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CircularProgressStyled style={{ display: loading ? 'block' : 'none' }} />
        <Button
          onClick={async () => {
            await close()
          }}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          disabled={!isEdited}
          onClick={async () => {
            await saveProfile()
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}