import _ from 'lodash'
import { DateTime } from 'luxon'
import prettyMs from 'pretty-ms'

/**
 * Return a luxon DateTime instance from either a js Date, object, ISO string, or milliseconds
 *
 * @param {*} val
 * @param {object=} opts
 * @param {string=} opts.tz - if set, setZone with it
 * @param {boolean=} opts.utc - if true, convert to UTC
 * @param {boolean=} opts.iso - if true, convert to ISO string
 * @param {boolean=} opts.isoDate - if true, convert to ISO date-only string
 * @return {DateTime|string}
 */
export function getLuxonDateTime (val, opts = {}) {
  const { utc = false, iso = false, isoDate = false, tz } = opts
  let dt
  if(val instanceof DateTime) {
    dt = val
  } else if(val instanceof Date) {
    dt = DateTime.fromJSDate(val)
  } else if(_.isPlainObject(val)) {
    dt = DateTime.fromObject(val)
  } else if(_.isString(val)) {
    dt = DateTime.fromISO(val)
  } else if(_.isFinite(val)) {
    dt = DateTime.fromMillis(val)
  } else {
    dt = DateTime.local()
  }
  if(tz) dt = dt.setZone(tz)
  if(utc) dt = dt.toUTC()
  if(iso) dt = dt.toISO()
  if(isoDate) dt = dt.toISODate()
  return dt
}

export const getNow = DateTime.now

export function getDateDiff (from, to, units) {
  const dFrom = getLuxonDateTime(from)
  const dTo = getLuxonDateTime(to)
  return Math.floor(dTo.diff(dFrom, units)[units])
}

export function getAgeFromBirthDate (birthDate) {
  return getDateDiff(birthDate, getNow(), 'years')
}

export function getNowUtcIso () {
  return DateTime.utc().toISO()
}

export function isValidISO (str) {
  const dt = DateTime.fromISO(str)
  return isValidLuxonDateTime(dt)
}

export function isValidLuxonDateTime (val) {
  return val instanceof DateTime && !val.invalid
}

/**
 * Return short date in localized format.
 *
 * @param {Date|object|string=} dateTime
 * @param {boolean} localized
 * @param {boolean} withTZ
 * @returns {string}
 */
export const getPrettyDate = ({
  dateTime,
  tz,
  withTZ = false,
  localized = false,
}) => {
  const dt = getLuxonDateTime(dateTime, { tz })
  if(localized) return dt.toLocaleString(DateTime.DATE_SHORT)
  return dt.toFormat(withTZ ? 'L/d/yy ZZZZ' : 'L/d/yy')
}

export const getPrettyDateTime = ({
  dateTime,
  tz,
  withTZ = false,
  localized = false,
}) => {
  const dt = getLuxonDateTime(dateTime, { tz })
  if(localized) {
    const timeFormat = withTZ ? DateTime.TIME_WITH_SHORT_OFFSET : DateTime.TIME_SIMPLE
    return `${dt.toLocaleString(DateTime.DATE_SHORT)}, ${dt.toLocaleString(timeFormat)}`
  }
  return dt.toFormat(withTZ ? 'L/d/yy, h:mm a ZZZZ' : 'L/d/yy, h:mm a')
}

export const getPrettyTime = ({
  dateTime,
  tz,
  withTZ = false,
}) => {
  const dt = getLuxonDateTime(dateTime, { tz })
  const timeSimple = dt.toLocaleString(DateTime.TIME_SIMPLE)
  return withTZ ? `${timeSimple} ${dt.toFormat('ZZZZ')}` : timeSimple
}

export const getDateISOString = ({
  dateTime,
  tz,
  withTZ = false,
}) => getLuxonDateTime(dateTime, { tz }).toFormat(withTZ ? 'yyyy-LL-dd ZZZZ' : 'yyyy-LL-dd')

/**
 * Return object with UTC timestamps of the start and end
 * of the specified unit of time for the provided date.
 *
 * The server stores all datetimes in UTC,
 * so this converts local time to UTC for the server.
 *
 * @example
 * parameters:
 *    startDate = '2020-01-20'
 *    endDate = '2020-01-22'
 *    unit = 'day'
 * returns:
 * {
 *   start: '2020-01-20T10:00:00.000Z'
 *   end: '2020-02-01T09:59:59.999Z'
 * }
 *
 * @param {Date|object|string} startDate - anything luxon can parse
 * @param {Date|object|string} endDate - anything luxon can parse
 * @param {string} unit - e.g.: 'day', 'week', ...
 * @param {string=} tz - timezone in IANA format
 * @return {object}
 */
export const getTimePeriodRange = ({
  startDate,
  endDate,
  unit,
  tz,
}) => {
  startDate = convertToStandardDate(startDate)
  endDate = convertToStandardDate(endDate)
  let t0 = getLuxonDateTime(startDate)
  let t1 = getLuxonDateTime(endDate)
  if(tz) {
    t0 = t0.setZone(tz)
    t1 = t1.setZone(tz)
  }
  return {
    startTime: t0.startOf(unit).toISO(),
    endTime: t1.endOf(unit).toISO(),
  }
}

export const getDateRangeString = (d1, d2, sep) => [d1, d2].map(getAnniversaryDateISO).join(sep)

/**
 *
 * @param {*} dateTime - representation of a timestamp
 * @param {boolean} compact - if true, returns "7d" instead of "7 days"
 * @return {string}
 */
export const getLocalTimeAgo = ({ dateTime, compact = false }) => {
  const from = getLuxonDateTime(dateTime)
  const to = DateTime.now()
  return prettyMs(getDateDiff(from, to, 'seconds') * 1000, { compact })
}

/**
 * Convert a UTC date-time to '1989-03-12' without being affected by timezone.
 * For prepping to send to API.
 *
 * @param {date} dateTime - date-only portion of ISO date.
 * @return {string}
 */

export const getAnniversaryDateISO = dateTime => getLuxonDateTime(dateTime, { isoDate: true })

const timeZoneNameFormats = {
  acronym: 'ZZZZ', // EST
  IANA: 'z', // America/New_York
  full: 'ZZZZZ', // Eastern Standard Time
}
export const getLocalZoneName = (format = 'IANA') => getNow().toFormat(timeZoneNameFormats[format])

/**
 * Reformat YYYY-MM-DD to m/d/yyyy without Luxon (to prevent timezone shifting)
 *
 * @param isoDate
 * @return {string}
 */
export const getPrettyDateFromISO = isoDate => {
  if(!_.isString(isoDate) || isoDate.length < 10) return ''
  const [y, m, d] = isoDate.substr(0, 10).split('-')
  if(!y || !m || !d) return ''
  return `${_.trimStart(m, '0')}/${_.trimStart(d, '0')}/${y}`
}

export const getStrippedDateFromISO = isoDate => isoDate.replace(/-/g, '').substr(0, 8)

/**
 * This method is used to covert chrome, safari , firefox all browsers to one time
 *
 * @param date
 * @returns {DateTime}
 */
export const convertToStandardDate = ( date ) => {
  let currentDateTime = new Date()
  if(date){
    if (typeof(date) === 'string') {
      date = DateTime.fromISO(date)
    }
    // always keep the standard time whether firefox/chrome/safari
    date = date.set({ hour: 15 }).set({ minutes: 25 }).set({seconds : 0})
  }
  return date
}
