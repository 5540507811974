import React from 'react'
import DeluxeButton from 'app/views/components/buttons/DeluxeButton'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import useDialogs from '../../../hooks/useDialogs'

export default ({
  type,
  label = 't:common:submit',
  stacked = true,
  disabled,
  op,
  confirmConfig,
  ...otherProps
}) => {
  const {
    isValid,
    dirty,
    setFieldValue,
    submitForm,
    isSubmitting,
    values,
  } = useFormikContext()
  const { confirm } = useDialogs()
  const submit = () => {
    if (op) setFieldValue('op', op)
    return submitForm()
  }
  const buttonProps = {
    type: type || (op || confirmConfig ? 'button' : 'submit'),
    label,
    name: label,
    disabled: _.isBoolean(disabled)
      ? disabled
      : (!dirty || !isValid || isSubmitting),
    loading: isSubmitting && (!op || op === values.op),
    stacked,
    ...otherProps,
  }
  if (op || confirmConfig) {
    buttonProps.onClick = async () => {
      if (confirmConfig) {
        const confirmed = await confirm(confirmConfig)
        if (confirmed) return submit()
      } else {
        return submit()
      }
    }
  }
  return <DeluxeButton {...buttonProps} />
}
