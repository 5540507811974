import { useTranslation } from 'react-i18next'
import { getNameSpaces, transVal } from 'utils/internationalization'

/**
 * Translate a string if formatted for translation; otherwise, return unchanged.
 *
 * @param {string} str
 * @param {object=} subs - interpolation values
 * @return {string}
 */
export default (str, subs) => {
  const { t } = useTranslation(getNameSpaces([str]))
  return transVal(str, t, subs)
}
