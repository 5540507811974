import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import _ from 'lodash'
import {
  env,
  isEnv,
} from './env'
import { supportedLngs } from 'config/const.json'

const backend = {
  loadPath: `${env('AWS_PREFIX')}/${env('AWS_BUCKET')}/{{lng}}-{{ns}}.json`,
  allowMultiLoading: true,
  customHeaders: {
    'x-amz-server-side-encryption-customer-algorithm': 'AES256',
    'x-amz-server-side-encryption-customer-key': env('AWS_CUSTOMER_KEY_B64'),
    'x-amz-server-side-encryption-customer-key-MD5': env('AWS_CUSTOMER_KEY_MD5_B64'),
  },
}

export const i18init = () => i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: isEnv('development'),
    ns: ['common', 'intake', 'glossary', 'scenes', 'error'],
    defaultNS: 'error',
    supportedLngs,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

/**
 * If v begins with "t:" use the rest as a path to a translation resource path with optional name space and return the
 * translation. Otherwise, just return v.
 *
 * @param {string} v
 * @param {function} t - i18n
 * @param {object=} subs - interpolation values
 * @return {string}
 */
export const transVal = (v, t, subs) => /^t:/.test(v) ? t(v.replace('t:', ''), subs) : v

/**
 * Return unique array of i18 name-spaces parsed from array of paths like 't:intake:question.how' where 'intake' is the
 * name-space.
 *
 * @param {object|array} collection
 */
export const getNameSpaces = collection => _
  .chain(collection)
  .values()
  .filter(_.isString)
  .map(path => (path.replace('t:', '').match(/:?([a-z]+):/) || [])[1])
  .filter()
  .uniq()
  .value()

export const getVisitTypeInterpolation = visitType => ({ visitType: `$t(glossary:visitTypes.${visitType})` })

export const getDynamicQuestionTransNameSpace = ({
  orgId,
  divId,
  level,
  name,
}) => {
  const nameSpaceScope = getNameSpaceScope({ orgId, divId, level })
  return `dq-${nameSpaceScope}-${name.replace('dynamicQuestions.', '').replace('.agreement', '')}`
}

export const getAgreementTransNameSpace = ({
  orgId,
  divId,
  level,
  name,
}) => {
  const nameSpaceScope = getNameSpaceScope({ orgId, divId, level })
  return `agreement-${nameSpaceScope}-${name.replace('agreements.', '')}`
}

const getNameSpaceScope = ({ orgId, divId, level }) => `${orgId}${level === 'div' ? `-${divId}` : ''}`
