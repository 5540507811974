// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        root :{
          fontWeight : 500,
          lineHeight: 1.17,
        },
        gutterBottom: {
          marginBottom: 12
        }
      }
    }
  }
}
