import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action'

/**
 * Construct and dispatch a redux action.
 * Return the result of the dispatch call.
 *
 * @param {function} dispatch
 * @param {function} actionCreator
 * @param {object=} options
 * @param {object=} options.waiting - configuration for returning a Promise to dispatch actions on resolve or reject
 * @param {object=} options.waiting.success - redux action for Promise to wait for and resolve with
 * @param {object=} options.waiting.failure - redux action for Promise to reject with
 * @return {object|Promise<object>}
 */
export const standardFluxThunk = (
  dispatch,
  actionCreator,
  options = {}
) => (
  values,
  formikBag
) => {
  const action = actionCreator({
    payload: values,
    meta: formikBag,
  })
  const {
    waiting,
  } = options
  if (waiting) {
    action[WAIT_FOR_ACTION] = waiting.success
    action[ERROR_ACTION] = waiting.failure
  }
  return dispatch(action)
}
