import React from 'react'
import withStyles from '@mui/styles/withStyles'
import PersonIcon from '@mui/icons-material/Person'
import { useTheme } from '@mui/material/styles'

export default ({
  style,
  ...rest
}) => {
  const { palette } = useTheme()
  const PersonIconStyled = withStyles({
    root: {
      ...style,
      color: palette.primary.dark,
    },
  })(PersonIcon)
  return (
    <PersonIconStyled {...rest} />
  )
}
