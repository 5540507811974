import store from 'utils/local-storage'
import { browser } from 'utils/browser'
import { client } from 'api/client'
import qs from 'query-string'
import _ from 'lodash'

const SITE_CONTEXT_PATH = 'site-context'

const landingPages = [
  /^\/personal\/my-status/,
  /^\/personal\/symptoms/,
  /^\/myact/,
  /^\/i-c/,
  /^\/payment/,
]
/*
Functions using local storage are async in case storage engine is switched to an async one in the future.
 */

export const setSiteContext = ({ location, invitationId }) => {
  setLandingPage({ location })
  const siteContext = getSiteContext()
  // Set division if any:
  const urlDivision = getSiteDivisionFromLocation(location)
  if (urlDivision) siteContext.division = urlDivision
  if (invitationId) {
    siteContext.invitationId = invitationId
  } else {
    delete siteContext.invitationId
  }
  store.set(SITE_CONTEXT_PATH, siteContext)
}

export const getSiteContext = () => store.get(SITE_CONTEXT_PATH, {})

/**
 * If valid landing url, record in local storage so user can be redirected to it after login. (see login saga in user duck)
 *
 * @param {string} location
 */
const setLandingPage = ({ location }) => {
  const landing = store.get('landing')
  // Set any location to the store, not just the defined landing pages.
  // This can be retrieved and traversed to on successful login.
  if (!landing) store.set('landing', location)

}

// get constants from server based on user role and designated org, if any:
/**
 *
 * @param {boolean} noOrgCache
 * @param {boolean} noLocCache
 * @return {Promise<*>}
 */
export const getAndStoreConstants = async ({
  noOrgCache = false,
  noLocCache = false,
} = {}) => {
  const { division, invitationId } = getSiteContext()
  const queryString = qs.stringify(_.pickBy({ invitationId, noOrgCache, noLocCache }, v => !!v))
  const url = `/constants${division ? `/${division}` : ''}?${queryString}`
  const response = await client().get(url)
  return setConstants(response?.data || {})
}

export const setConstants = constants => store.set('constants', constants)

export const getAndStoreCustomRoles = async ({ data }) => {
  const oldConstants = store.get('constants')
  const newConstants = _.clone(oldConstants)

  const roles = _.map(data?.role, 'name')
  newConstants.org.CUSTOM_USER_ROLES = roles
  return setConstants(newConstants)
}

const getSiteDivisionFromLocation = location => {
  const matches = (location?.pathname || '').toLowerCase().match(/\/div\/([a-z-]+)/)
  return matches && matches[1] && matches[1]
}

export const getPwaMode = () => {
  let result
  const browserName = browser.getBrowserName()
  switch (browserName) {
    case 'Safari':
      result = window.navigator.standAlone ? 'stand-alone' : 'in-browser'
      break
    case 'FireFox':
      result = window.fullScreen ? 'full-screen' : 'NOT full-screen'
      break
    case 'Chrome':
      result = (!window.screenTop && !window.screenY) ? 'full-screen' : 'NOT full-screen'
      break
    default:
      result = 'unknown'
  }
  return result
}

export const inMaintenanceMode = () => store.get('constants.inMaintenanceMode')