import React, { useEffect, useState } from 'react'
import Header from 'app/components/Header'
import { Switch, useLocation } from 'react-router-dom'
import routes from 'app/routes'
import BottomNav from 'app/views/layouts/BottomNav'
import { Box, Toolbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MaintenanceMode from './MaintenanceMode'
import { inMaintenanceMode } from '../../../utils/site-context'
import { getDivisionProperty, getOrg } from 'utils/org'
import DivisionDisabled from 'app/views/layouts/DivisionDisabled'
import useUserRoles from 'app/views/hooks/useUserRoles'
import Drawer from '../../components/Drawer'
import { useDispatch, useSelector } from 'react-redux'
import { getUserId } from '../../state/ducks/user/selectors'
import NavHeader from '../../components/Header/NavHeader'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    margin: '0 auto',
    backgroundColor: '#FFFFFF',
    overflowY : 'auto',
  },
  bottomNav: {
    width: '100%',
  },
}))
const Pages = () => {
  const classes = useStyles()
  const userId = useSelector(getUserId)
  return (
    <Box component='main'
         sx={{ width: '100%', display: 'flex', marginTop : userId ? '100px' : '0px' , flexDirection: 'column', flexGrow: 1 }}>
      <Toolbar />
      <Switch>
        {routes}
      </Switch>
     {/* <BottomNav
        className={classes.bottomNav}
      />*/}
    </Box>
  )
}
const AppBarControls = ({ onHomePage }) => {
  const [open, setOpen] = useState(false)
  const { drawerOpen } = useSelector((state) => state.menu)
  const dispatch = useDispatch()
  useEffect(() => {
    if(open !== drawerOpen) setOpen(drawerOpen)
  }, [drawerOpen])
  const handleDrawerToggle = () => {
    setOpen(!open)
    dispatch({ type: 'MENU/DRAWER_OPEN', drawerOpen: !open })
  }
  return (<>
    {!onHomePage && <Header open={open} handleDrawerToggle={handleDrawerToggle} />}
    {!onHomePage && <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />}
  </>)
}
export default () => {
  const userId = useSelector(getUserId)
  const classes = useStyles()
  const org = getOrg()
  const location = useLocation()
  const { hasAnyRoles } = useUserRoles()
  const onHomePage = /^\/(div\/[a-z]+)?$/.test(location.pathname)
  const inFullAreaPage = /my-health/.test(location.pathname) || /food-compass/.test(location.pathname)
    || /diabetes/.test(location.pathname)
    || /food-preferences/.test(location.pathname)
    || /medical-chat/.test(location.pathname)
	|| /scan-upca/.test(location.pathname)
  const inAdminArea = /^\/admin/.test(location.pathname) || /dashboard/.test(location.pathname)
  useEffect(() => {
    document.body.classList[inAdminArea || inFullAreaPage ? 'add' : 'remove']('admin')
  }, [inAdminArea, inFullAreaPage])
  if(inMaintenanceMode()) return <MaintenanceMode />

  const isDivisionDisabled = () => {
    if(getDivisionProperty({ key: 'disabled', df: false })) {
      if(!hasAnyRoles(['Account Owner', 'Admin']))
        return true
    }
    return false
  }
  if(isDivisionDisabled()) return <DivisionDisabled />
  return (
    <>
      {!userId && org.id === 'truewellth' && <NavHeader/>}
    <Box
      sx={{ display: 'flex', width: '100%' , marginTop : userId ? '100px' : '0px'   }}
      className={classes.container}>
      
      <AppBarControls onHomePage={onHomePage} />
      <Pages />

    </Box>
    </>
  )
}