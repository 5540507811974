import Bowser from 'bowser'

let Browser, IsTouchEnabled

if(typeof  window !== 'undefined') {
  Browser = Bowser.getParser(window.navigator.userAgent)

  IsTouchEnabled = () => 'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
}

export const browser = Browser
export const isTouchEnabled = IsTouchEnabled