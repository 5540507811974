import PropTypes from 'prop-types'

// project import
import Default from 'app/views/themes/theme/default'
import Theme1 from 'app/views/themes/theme/theme1'
import Theme2 from 'app/views/themes/theme/theme2'
import Theme3 from 'app/views/themes/theme/theme3'
import Theme4 from 'app/views/themes/theme/theme4'
import Theme5 from 'app/views/themes/theme/theme5'
import Theme6 from 'app/views/themes/theme/theme6'
import Theme7 from 'app/views/themes/theme/theme7'
import Theme8 from 'app/views/themes/theme/theme8'

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors, presetColor, mode) => {
  switch (presetColor) {
    case 'theme1':
      return Theme1(colors, mode)
    case 'theme2':
      return Theme2(colors, mode)
    case 'theme3':
      return Theme3(colors, mode)
    case 'theme4':
      return Theme4(colors, mode)
    case 'theme5':
      return Theme5(colors, mode)
    case 'theme6':
      return Theme6(colors, mode)
    case 'theme7':
      return Theme7(colors, mode)
    case 'theme8':
      return Theme8(colors, mode)
    default:
      return Default(colors)
  }
}

Theme.propTypes = {
  colors: PropTypes.object,
  presetColor: PropTypes.any
}

export default Theme
