import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default props => (
  <SvgIcon
    x='0px' y='0px' viewBox='0 -12 100 125' enableBackground='new 0 0 100 100'
    {...props}
  >
    <path
      d='M32.4,84.6c0.3,1.7,0,3-0.8,3.8c-0.8,0.8-2.2,1-3.8,0.6L8.3,83.3c-3.3-0.9-3.9-3.6-1.4-5.9l5.3-4.9  c-0.1-0.1-0.1-0.2-0.2-0.3C1.2,54,5,31.3,19.8,17.6c2.2-2.1,4.7-3.9,7.4-5.6c6.7-4,14-6,21.3-6.1v6.6c-6.1,0.2-12.3,1.9-17.9,5.2  c-2.3,1.4-4.4,3-6.3,4.7C11.9,33.9,8.6,52.7,17.1,67.9l5.4-5c2.5-2.3,5.1-1.5,5.8,1.8L32.4,84.6z M71.8,35.3  c0.7,3.3,3.3,4.1,5.8,1.8l5.4-5c8.5,15.2,5.2,34-7.1,45.5c-1.9,1.8-4,3.3-6.3,4.7c-5.6,3.4-11.8,5-17.9,5.2l0,6.6  c7.3-0.2,14.6-2.2,21.3-6.1c2.7-1.6,5.2-3.5,7.4-5.6C95.1,68.7,98.8,46,88,27.9c-0.1-0.1-0.1-0.2-0.2-0.3l5.3-4.9  c2.5-2.3,1.9-5-1.4-5.9l-19.5-5.6c-1.6-0.5-3-0.2-3.8,0.6c-0.8,0.8-1.2,2.1-0.8,3.8L71.8,35.3z'
    />
  </SvgIcon>
)
