// ==============================|| OVERRIDES - TABS ||============================== //

export default function Select() {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          height : '55px'
        },
        '& .select.MuiInputBase-input.MuiOutlinedInput-input': {
          height : '55px'
        }
      }
    }
  }
}
