import SceneLoadingProgress from 'app/views/components/SceneLoadingProgress'
import AutoSizer from 'react-virtualized-auto-sizer'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import T from 'prop-types'

const useStyles = makeStyles(() => ({
  scrollableContentOuterWrapper: {
    flexGrow: 1,
  },
  scrollableContentInnerWrapper: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch', // enables momentum-scrolling on iOS overflow elements
  },
  nonScrollableContent: {
    flexGrow: 0,
    flexBasis: 'content',
  },
})
)

const SceneScrollableContent = ({
  preScrollableContent,
  scrollableContent,
  scrollableContentLoading,
  postScrollableContent,
}) => {
  const { nonScrollableContent, scrollableContentOuterWrapper, scrollableContentInnerWrapper } = useStyles()
  return (
    <>
      {preScrollableContent && (
        <div className={nonScrollableContent}>
          {preScrollableContent}
        </div>
      )}
      <div className={scrollableContentOuterWrapper}>
        {scrollableContentLoading
          ? <SceneLoadingProgress message={scrollableContentLoading} />
          : (
            <AutoSizer>
              {
                parentSize => (
                  <div
                    className={scrollableContentInnerWrapper}
                    style={parentSize}
                  >
                    {scrollableContent(parentSize)}
                  </div>
                )
              }
            </AutoSizer>
            )}
      </div>
      {postScrollableContent && (
        <div className={nonScrollableContent}>
          {postScrollableContent}
        </div>
      )}
    </>
  )
}
SceneScrollableContent.propTypes = {
  preScrollableContent: T.element,
  scrollableContent: T.func, // will be provided width and height props
  scrollableContentLoading: T.bool,
  postScrollableContent: T.element,
}

export default SceneScrollableContent
