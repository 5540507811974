import React, { Suspense } from 'react'
import SceneLoadingProgress from 'app/views/components/SceneLoadingProgress'

const SuspenseAndLoader = ({ component: Component, ...rest }) => (
  <Suspense fallback={<SceneLoadingProgress />}>
    <Component {...rest} />
  </Suspense>
)

export default SuspenseAndLoader
