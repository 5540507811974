import { client } from './client'

/**
 * Post phone number.
 *
 * @return {Promise<void|object>}
 */
export const startLogin = async params => {
  const response = await client().post('/auth/start', params)
  return response?.data
}
/**
 * Post OTP.
 *
 * @return {Promise<void|object>}
 */
export const verifyLogin = params => client().post('/auth/verify', params)

export const logOut = async () => {
  await client().get('/auth/logout')
}

/**
 * @param {CovidTest} test
 * @return {Promise<void|object>}
 */
export const createTest = async test => {
  const response = await client().post('/lab/test', test)
  return response?.data
}
