import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { isEnv } from 'utils/env'
import actions from 'app/state/ducks/actions'
import { useDispatch } from 'react-redux'
import useUserActions from '../../hooks/useUserActions'
import useDialogs from '../../hooks/useDialogs'
import { resolveErrorMessage } from '../../../../utils/error-handling'
import { isNonEmptyArray } from '@sustainhawaii/object-utils'

const { alert } = actions.dialog

export default () => {
  const { alertState } = useDialogs()
  const {
    open,
    title,
    message,
    error,
    buttonLabel = 'OK',
    afterClose,
    signOutAfterClose = false,
  } = alertState || {}
  const { logout } = useUserActions()
  const dispatch = useDispatch()
  const close = () => dispatch(alert.close())
  let dialogContentText = ''
  let ariaDescription = ''
  if (typeof message === 'string' || React.isValidElement(message)) {
    dialogContentText = message
    ariaDescription = message
  } else if (isNonEmptyArray(message)) {
    dialogContentText = (
      <>
        {message.map((txt, i) => (
          <Typography
            key={i}
            variant='body1'
            gutterBottom
          >
            {txt}
          </Typography>
        ))}
      </>
    )
    ariaDescription = message.join(': ')
  }
  const debugMessage = resolveErrorMessage(error)
  const afterCloseFunc = signOutAfterClose
    ? () => {
      if (afterClose) afterClose()
      return logout()
    }
    : afterClose
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby={title}
        aria-describedby={ariaDescription}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          {dialogContentText && (
            <DialogContentText component='div'>{dialogContentText}</DialogContentText>
          )}
          {debugMessage && !isEnv('production') && (
            <DialogContentText component='div'>Debug Message: {debugMessage}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await close()
              if (afterCloseFunc) return afterCloseFunc()
            }}
            autoFocus
          >
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
