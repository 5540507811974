import { call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import actions from 'app/state/ducks/actions'
import isOnline from 'is-online'
import log from 'utils/logger'
import { getAndStoreConstants, inMaintenanceMode, setSiteContext } from 'utils/site-context'
import { getIntervalChannel } from 'utils/saga'
import {
  getMasterPass,
  getRole,
  getCanViewPHI,
} from 'app/state/ducks/user/selectors'
import { loadActionItems } from 'app/state/ducks/lab/sagas'
import { getLocation } from 'app/state/ducks/router/selectors'
import * as Sentry from '@sentry/react'
import { isEnv } from 'utils/env'
import { login } from '../user/sagas'
import { isPerseusReachable, resolveErrorMessage } from 'utils/error-handling'
import qs from 'query-string'
import { i18init } from 'utils/internationalization'
import _ from 'lodash'

function * initApp () {
  try {
    const location = yield select(getLocation)
    const { invitationId } = qs.parse(location.search)
    yield call(setSiteContext, { location, invitationId })
    yield call(getAndStoreConstants)
    yield call(i18init)
    if(inMaintenanceMode()) {
      yield put(actions.appinit.success())
      yield cancel()
    }
    yield fork(login)
    // appinit.success is called after successful or failed login in login saga, so that when you reload the app, it doesn't show the login page briefly if you're still logged in.
    while (yield take(actions.backgroundSync.start)) {
      const bgTask = yield fork(backgroundSync)
      yield take(actions.backgroundSync.stop)
      yield cancel(bgTask)
    }
  } catch (e) {
    yield put(actions.handleError({
      e,
      alertCfg: {
        title: 'Could not initialize the app.',
      },
      sentryCfg: {
        tags: {
          section: 'initSaga',
        },
      },
    }))
  }
}

export function * watchInitApp () {
  yield takeEvery(actions.appinit.start, initApp)
}

/**
 * @return {IterableIterator<*|PutEffect<Action>>}
 */
function * backgroundSync () {
  const channel = yield call(getIntervalChannel, 30) // poll every 30 seconds
  yield * sync()
  while (true) {
    yield take(channel)
    yield * sync()
  }
}

function * sync () {
  try {
    const canViewPHI = yield select(getCanViewPHI)
    if(canViewPHI) {
      yield * loadActionItems()
    }
  } catch (e) {
    log.error('system polling error', e)
  }
}

export function * handleError ({
  payload: {
    e,
    alertCfg = {},
    alertOnly = false,
    sentryCfg,
    redirect,
    loggingIn = false,
  } = {},
}) {
  const resStatus = e?.response?.status || e?.code
  const errorMessage = resolveErrorMessage(e)
  const masterPass = yield select(getMasterPass)
  const role = yield select(getRole)
  if(masterPass?.name) _.set(sentryCfg, 'tags.name', `${masterPass.name.first} ${masterPass.name.last.substring(0, 1)}`)
  _.set(sentryCfg, 'tags.isStaff', role ? 'yes' : 'no')
  _.set(sentryCfg, 'tags.role', role || 'none')
  const { alert } = actions.dialog
  // Handle lost internet connection:
  const online = yield call(isOnline)
  if(!online) {
    yield * networkErrorAlert({
      title: 'Cannot connect to internet.',
      message: 'Please check your connection and try again.',
      alertCfg,
      e,
    })
    yield cancel()
  }
  // Handle PERSEUS unreachable:
  const perseusReachable = yield call(isPerseusReachable)
  if(!perseusReachable) {
    const cfg = { ...sentryCfg }
    _.set(cfg, 'tags.networkError', 'api-unreachable')
    cfg.extra = {
      sentryCfg,
      alertCfg,
    }
    Sentry.captureException(e, cfg)
    yield * networkErrorAlert({
      title: 'Connectivity Issue',
      message: 'There may be an issue with the network. Please check signal strength and try again.',
      alertCfg,
      e,
    })
    yield cancel()
  }
  // Handle app out of date:
  if(e?.name === 'ChunkLoadError') {
    const { confirm } = actions.dialog
    yield put(confirm.ask({
      title: 'App Update Required',
      question: `${alertCfg?.message ? `${alertCfg.message} This is because this` : 'This'} app is out of date. Let’s update it now.`,
      yesButtonLabel: 'Okay',
      forceYes: true,
    }))
    yield put(actions.beginAppUpdate())
    yield cancel()
  }
  // Handle not authenticated/session expired::
  if(errorMessage === 'Authentication required') {
    if(!loggingIn) {
      alertCfg.signOutAfterClose = true
      yield * networkErrorAlert({
        title: 'Sign-In Expired',
        message: 'Sorry, but you need to sign in again to continue.',
        alertCfg,
        e,
      })
    }
    yield cancel()
  }
  // Handle forbidden errors:
  if(resStatus === 401) {
    yield * networkErrorAlert({
      title: 'Permission Error',
      message: 'Sorry, but you don\'t have permission to do this.',
      alertCfg,
      e,
    })
    yield cancel()
  }
  // Handle Maintenance Mode:
  yield call(getAndStoreConstants)
  if(inMaintenanceMode()) {
    window.location.reload(true)
    yield cancel()
  }
  if(_.isEmpty(alertCfg)) {
    alertCfg = {
      title: 'Error.',
      message: errorMessage,
    }
  }
  const subTitleLines = [
    alertCfg.message,
    'Please tell what you did right before this happened.',
  ]
  if(!isEnv('production')) subTitleLines.push(errorMessage)
  // Handle other errors with Sentry:
  Sentry.captureException(e, sentryCfg)
  if(isEnv('development') || alertOnly) {
    yield put(alert.open({
      ...alertCfg,
      error: e,
    }))
    if(isEnv('development')) {
      console.error(e)
      console.error(errorMessage)
    }
  } else {
    Sentry.showReportDialog({
      title: alertCfg.title,
      subtitle: subTitleLines.join('  '),
      successMessage: 'Your feedback has been sent. Thank you!',
    })
  }
  if(redirect) {
    yield put(push(redirect))
  }
}

export function * watchHandleError () {
  yield takeEvery(actions.handleError, handleError)
}

function * networkErrorAlert ({
  title,
  message,
  alertCfg = {},
  e,
}) {
  const { alert } = actions.dialog
  yield put(alert.open({
    ...alertCfg,
    title: title || alertCfg?.title || 'Error',
    message: alertCfg?.message ? [alertCfg?.message, message] : message,
    error: e,
  }))
}