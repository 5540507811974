import { createSlice } from '@reduxjs/toolkit'
import * as Cookies from 'js-cookie'

const auth = createSlice({
  name: 'auth',
  initialState: {
    cookieConsent: Cookies.get('CookieConsent'),
  },
  reducers: {
    consentToCookies (state) {
      state.cookieConsent = true
    },
  },
})

const { actions, reducer } = auth

export const { consentToCookies } = actions

export default reducer
