import React from 'react'
import QRCodeScanner from 'app/views/components/QRCodeScanner'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import useDialogs from 'app/views/hooks/useDialogs'
import * as Sentry from '@sentry/react'

/*
Adds an onClick handler to a component that launches QR code scanner
and calls a callback with resulting data object.
 */

const useStyles = makeStyles(({ spacing }) => ({
  instructions: {
    marginTop: spacing(1),
  },
}))

const getErrorAlert = e => ({
  title: 'Camera Access',
  message: 'We had trouble reading the code. Please make sure another browser window or tab, or another app, is not using the camera.',
  error: e,
})

export default ({
  title,
  instructions,
  callback,
  ...props
}) => Component => {
  const { alert, details } = useDialogs()
  const classes = useStyles()
  return (
    <Component
      {...props}
      onClick={() => details.open({
        title,
        fullScreen: false,
        content: (
          <>
            <QRCodeScanner
              onErr={e => {
                alert(getErrorAlert(e))
                if(!['NotAllowedError'].includes(e?.name)) {
                  Sentry.captureException(e, {
                    tags: {
                      component: 'QRCodeScanner',
                    },
                    extra: {
                      title,
                    },
                  })
                }
                details.close()
              }}
              onResult={res => {
                if(!_.isString(res)) {
                  alert(getErrorAlert({ message: 'Result is not a string.' }))
                  return
                }
                try {
                  const resObj = JSON.parse(res)
                  if(_.isPlainObject(resObj)) {
                    callback(resObj)
                  } else {
                    alert(getErrorAlert({ message: 'Could not parse object from result.' }))
                  }
                } catch (e) {
                  alert(getErrorAlert(e))
                }
                details.close()
              }}
            />
            {instructions && (
              <Typography
                variant="body1"
                align="center"
                className={classes.instructions}
              >
                {instructions}
              </Typography>
            )}
          </>
        ),
      })}
    />
  )
}
