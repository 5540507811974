import React from 'react'
import BoxedIconAndText from './BoxedIconAndText'
import { Link } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import _ from 'lodash'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    margin: spacing(3, 'auto'),
    minWidth: '45%',
    maxWidth: '80%',
  },
  fullWidth: {
    width: '80%',
  },
}))

export default ({
  Icon,
  text,
  href,
  hrefTarget,
  fullWidth = false,
  ...props
}) => {
  const allClasses = useStyles()
  const classes = [allClasses.root]
  if (fullWidth) {
    classes.push(allClasses.fullWidth)
  }
  const className = classNames(classes)
  const Button = () => (
    <BoxedIconAndText
      Icon={Icon}
      text={text}
      size='small'
      themeColor='primary'
      direction='down'
      {...props}
    />
  )
  if (href) {
    if (_.startsWith(href, 'http')) return (
      <a
        href={href}
        target={hrefTarget || '_blank'}
        className={className}
      >
        <Button />
      </a>
    )
    return (
      <Link
        to={href}
        className={className}
      >
        <Button />
      </Link>
    )
  }
  return (
    <div className={className}>
      <Button />
    </div>
  )
}