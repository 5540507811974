import React from 'react'
import Box from '@mui/material/Box'
import { Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import _ from 'lodash'
import useTranslateValue from '../../hooks/useTranslateValue'

const directionConfig = {
  right: {
    flexDirection: 'row',
    marginType: 'marginRight',
  },
  left: {
    flexDirection: 'row-reverse',
    marginType: 'marginLeft',
  },
  down: {
    flexDirection: 'column',
    marginType: 'marginBottom',
  },
  up: {
    flexDirection: 'column-reverse',
    marginType: 'marginTop',
  },
}

const iconMarginSizes = {
  small: 0.5,
  large: 1,
  huge: 1.25,
}

const useStyles = makeStyles(() => ({
  iconSmall: {
    fontSize: 26,
  },
  textSmall: {
    fontSize: 14,
  },
  iconLarge: {
    fontSize: 52,
  },
  textLarge: {
    fontSize: 16,
  },
  iconHuge: {
    fontSize: 58,
  },
  textHuge: {
    fontSize: 18,
  },
}))

const getIconStyle = ({ size, direction, spacing }) => ({
  [directionConfig[direction].marginType]: spacing(iconMarginSizes[size]),
})

export default ({
  Icon,
  text,
  size = 'small',
  themeColor,
  styleColor,
  iconClassName,
  direction = 'right',
  ...props
}) => {
  const { spacing } = useTheme()
  const classes = useStyles()
  const textT = useTranslateValue(text)
  const classSuffix = _.capitalize(size)
  const iconClass = clsx(classes[`icon${classSuffix}`], iconClassName)
  const textClass = classes[`text${classSuffix}`]
  const iconStyle = getIconStyle({ size, direction, spacing })
  return (
    <Box
      display='flex'
      flexDirection={directionConfig[direction].flexDirection}
      alignItems='center'
      {...props}
    >
      <Icon
        color={themeColor || 'inherit'}
        className={iconClass}
        style={iconStyle}
      />
      <Typography
        align={['up', 'down'].includes(direction) ? 'center' : 'inherit'}
        classes={{
          root: textClass,
        }}
        color={themeColor || 'initial'}
        style={styleColor ? { color: styleColor } : undefined}
      >
        {textT}
      </Typography>
    </Box>
  )
}
