import React from 'react'
import withStyles from '@mui/styles/withStyles'
import CircularProgress from '@mui/material/CircularProgress'

export default ({
  style,
  ...rest
}) => {
  const CircularProgressStyled = withStyles({
    root: {
      ...style,
    },
  })(CircularProgress)
  return (
    <CircularProgressStyled {...rest} />
  )
}
