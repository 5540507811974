import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { configureStore, history, sagaMiddleware } from 'app/state/store'
import { rootSaga } from 'app/state/ducks/sagas'
import App from './app'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { getPwaMode } from 'utils/site-context'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import loadScript from 'load-script'
import { browser } from 'utils/browser'
import { getThisAppVersion } from './api/general'
import { initializeErrorReporting } from './utils/error-handling'
import { ConfigProvider } from 'app/views/contexts/ThemeConfigContext'

const appVersion = getThisAppVersion()
console.info(`${appTitle} PWA version ${appVersion}`)
console.info('Browser:', browser.getBrowser())
console.info('Display Mode:', getPwaMode())

const launch = async () => {
  const reduxStore = configureStore()
  sagaMiddleware.run(rootSaga)
  initializeErrorReporting()
  ReactDOM.render(
    <ReduxProvider store={reduxStore}>
      <ConfigProvider>
        <ConnectedRouter history={history}>
          <Route path='/' component={App} />
        </ConnectedRouter>
      </ConfigProvider>
    </ReduxProvider>,
    document.getElementById('root'),
  )
  loadScript('https://cdn.jsdelivr.net/npm/pwacompat', {
    attrs: {
      crossorigin: 'anonymous',
    },
  })

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister()

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals()
}

launch()

/*
Test your React application with the axe-core accessibility testing library.
Results will show in the Chrome DevTools console.
if (isEnv('development')) {
  axe(React, ReactDOM, 1000)
}
 */
export { getOrgLocationName } from './utils/org'
