import MenuItem from '@mui/material/MenuItem'
import _ from 'lodash'
import React from 'react'
import { getDivisionNamesById, getOrgLocationNamesByLocId } from 'utils/org'

/**
 * Return set of MenuItem components.
 *
 * @param {string=} name
 * @param {object} itemData - object with keys as values and values as labels
 * @param {string=} placeholder - displayed in select box before selection made
 * @param {string=} otherChoiceName - adds 'other' value and label
 * @param {boolean=} multiple - is a multi-select
 * @param {object=} values - form's values
 * @param {object=} theme - MUI theme
 * @return {element[]}
 */
export const getMenuItems = ({
  name,
  itemData,
  placeholder,
  otherChoiceName,
  multiple = false,
  values,
  theme,
}) => {
  // use the itemData keys as the values, and the itemData values as the displayed text, of the menu items
  const itemPropSets = _.map(itemData, (val, key) => ({
    key: key.toString(),
    value: key,
    label: val,
    style: getItemStyles({
      name,
      value: key,
      values,
      multiple,
      theme,
    }),
  }))
  if(placeholder) {
    itemPropSets.unshift({
        key: placeholder,
        value: '',
        label: placeholder,
        disabled: true,
      },
    )
  }
  if(otherChoiceName) {
    itemPropSets.push({
      key: 'other',
      value: 'other',
      label: 'Other',
    })
  }
  return itemPropSets.map(({
    key,
    label,
    ...menuItemProps
  }) => (
    <MenuItem
      key={key}
      {...menuItemProps}
    >
      {label}
    </MenuItem>
  ))
}

const getItemStyles = ({
  name,
  value,
  values,
  multiple,
  theme,
}) => {
  if(!multiple || !name) return
  const selectedValues = _.get(values, name)
  const selected = _.some(selectedValues, v => v === value)
  return {
    backgroundColor: selected
      ? theme.palette.grey[200]
      : 'transparent',
    fontWeight: selected
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular,
  }
}

const addAllSelectionItem = (result, withAll) => {
  if(!!withAll || _.isString(withAll)) return {
    ALL: _.isString(withAll) ? withAll : 'ALL',
    ...result,
  }
  return result
}

export const getOrgDivisionSelectionItemData = ({
  withAll,
  filters,
} = {}) => {
  let result = getDivisionNamesById(filters)
  return addAllSelectionItem(result, withAll)
}

export const getOrgLocationSelectionItemData = ({
  withAll = false,
  filters,
} = {}) => {
  let result = getOrgLocationNamesByLocId(filters)
  return addAllSelectionItem(result, withAll)
}

export const getOrgWarehouseSelectionItemData = ({
  withAll = false,
  filters,
} = {}) => {
  let result = getOrgLocationNamesByLocId(filters)
  return addAllSelectionItem(result, withAll)
}
