import { client, createFormData, multiPartClient } from './client'
import {
  mapBooleanValsToStrings,
  mapStringValsToBooleans,
  objectWithoutNonInfoValues,
} from '@sustainhawaii/object-utils'
import { getLocalZoneName, getTimePeriodRange } from 'utils/temporal'
import { blobToDataURL } from 'blob-util'
import { getFilteredQueryString } from '@sustainhawaii/heal-common/lib/util/url'

/**
 *
 * @param {string} patientId
 * @param {string} divisionId - current division ID
 * @param {boolean=} isMedStaff
 * @param {boolean=} canViewPHI
 * @param {boolean=} selfTest
 * @return {Promise<*>}
 */
export const getPatient = async ({
  patientId,
  divisionId,
  isMedStaff = false,
  canViewPHI = false,
  selfTest = false,
  visitType = 'test',
}) => {
  const path = getLabPath({ isMedStaff, canViewPHI, selfTest })
  let response
  if(selfTest) {
    response = await client().get(`${path}/patient/${visitType}/${patientId}`)
  } else {
    response = await client().get(`${path}/patient/${visitType}/${patientId}/${divisionId}`)
  }
  // response.data.data object should contain patient, intakeForm objs
  return response?.data?.data
}

export const getLabAccounts = async ({ divId }) => {
  const response = await client().get(`/lab/lab-accounts/${divId}`)
  return response?.data
}

/**
 * @param {CovidTest} test
 * @param {boolean=} selfTest
 * @param {object=} agreements - photos of hard-copies of agreements not signed in PERSEUS intake form
 * @param {boolean=} reuseIntakeForm
 * @return {Promise<void|object>}
 */
export const createTest = async ({
  test,
  selfTest = false,
  agreements,
  reuseIntakeForm = false,
}) => {
  const path = selfTest ? '/user/lab' : '/lab'
  const fd = await createFormData({
    test: objectWithoutNonInfoValues(test),
    agreements,
    reuseIntakeForm,
    tz: getLocalZoneName(),
  })
  const response = await multiPartClient().post(`${path}/test`, fd)
  return response?.data
}

/**
 * @param {CovidTest} test
 * @param {boolean=} selfTest
 * @return {Promise<void|object>}
 */
export const updateTest = async ({
  test,
  selfTest = false,
}) => {
  const path = getLabPath({
    isMedStaff: true,
    selfTest,
  })
  const response = await client().put(`${path}/test`, objectWithoutNonInfoValues(test))
  return response?.data
}

/**
 * @param {CovidTest} test
 * @return {Promise<void|object>}
 */
export const deleteTest = id => client().delete(`/lab/hcp/test/${id}`)

export const submitSelfTestImages = async (data) => {
  const fd = await createFormData({
    data,
  })
  try {
    const response = await multiPartClient().post(`/user/lab/test/image`, fd)
    return {
      result: response.data.result.toLowerCase(),
    }
  } catch (e) {
    return {
      errorMessage: 'Kit image analysis failed. Please retry.',
    }
  }
}
/**
 * @return {Promise<void|object>}
 */
export const getTest = async (testId) => {
  const response = await client().get(`/lab/hcp/test/${testId}`)
  return response?.data
}

/**
 * @param {CovidVacc} test
 * @param {boolean=} selfTest
 * @param {object=} agreements - photos of hard-copies of agreements not signed in PERSEUS intake form
 * @param {boolean=} reuseIntakeForm
 * @return {Promise<void|object>}
 */
export const createVacc = async ({
  vacc,
  agreements,
  reuseIntakeForm = false,
}) => {
  const path = '/lab'
  const fd = await createFormData({
    vacc: objectWithoutNonInfoValues(vacc),
    agreements,
    reuseIntakeForm,
    tz: getLocalZoneName(),
  })
  const response = await multiPartClient().post(`${path}/vacc`, fd)
  return response?.data
}

/**
 * @param {CovidVacc} vacc
 * @return {Promise<void|object>}
 */
export const updateVacc = async ({ vacc }) => {
  const response = await client().put('/lab/hcp/vacc', mapStringValsToBooleans(vacc), {
    timeout: 30000,
  })
  return getFormCompatibleVaccData(response?.data)
}

/**
 * @param {CovidVacc} vacc
 * @return {Promise<void|object>}
 */
export const deleteVacc = id => client().delete(`/lab/hcp/vacc/${id}`)

/**
 * @return {Promise<void|object>}
 */
export const getVacc = async (vaccId) => {
  const response = await client().get(`/lab/hcp/vacc/${vaccId}`)
  return getFormCompatibleVaccData(response?.data)
}

const getFormCompatibleVaccData = data => data ? mapBooleanValsToStrings(data) : undefined

export const getActionItems = async (params) => {
  const response = await client().get(`/lab/hcp/action-items?${getFilteredQueryString(params)}`)
  return response?.data
}

/**
 * @return {Promise<void|object>}
 */
export const getImageDataUriFromApi = async (fileName) => {
  const response = await client().get(`/lab/hcp/images/${fileName}`, {
    responseType: 'blob',
  })
  if(response?.data) {
    return blobToDataURL(response.data)
  }
}

export const initialHomelessDocs = async (formId) => client().post(`/lab/hcp/initial-intake-form-homeless-docs/${formId}`)

export const getScheduledVisitStats = async ({
  startDate,
  endDate,
  filters,
  asCSV = false,
}) => {
  const { startTime, endTime } = getTimePeriodRange({ startDate, endDate, unit: 'day' })
  return client().post('/lab/hcp/scheduled-visit-stats', {
    startDate: startTime,
    endDate: endTime,
    tz: getLocalZoneName(),
    filters,
    asCSV,
  })
}

export const getIntakeFormSummary = async ({ formId }) => {
  const response = await client().get(`/lab/hcp/intake-form-summary/${formId}`)
  return response?.data
}

/**
 * Post test id and new result to endpoint.
 *
 * @param {string} id
 * @param {string} result
 * @return {Promise<AxiosResponse<any>>}
 */
export const changeTestResult = async ({ id, result }) => client().post('/lab/hcp/change-test-result', {
  id,
  result,
})

/**
 * Retrieve all visits of a certain type for userId.
 *
 * @param {object} params
 * @param {string} params.patientId - any user id
 * @param {string} params.visitType
 * @return {Promise<*>}
 */
export const getVisitCardVisits = async ({ patientId, visitType }) => {
  const response = await client().get(`/lab/hcp/visit-card-visits/${patientId}/${visitType}`)
  return response?.data
}

/**
 *
 * @param {boolean=} isMedStaff
 * @param {boolean=} canViewPHI
 * @param {boolean=} selfTest
 * @return {string}
 */
const getLabPath = ({
  isMedStaff = false,
  canViewPHI = false,
  selfTest = false,
}) => {
  if(selfTest) return '/user/lab'
  return isMedStaff || canViewPHI ? '/lab/hcp' : '/lab'
}

// Insurance and claims
/**
 * Get insurance eligibility of the patient for the specified division
 * Format: `{ success: true/false, insurance: {hasInsurance: true/false, eligible: true/false}}`
 * If `success: false`, there is error in the request
 * If `hasInsurance: false`, no insurance applicable for the paramters passed
 * If `hasInsurance: true, eligible: false`, has insurance, but not eligible for the current scenario
 * @param patientId Id of the patient whose insurance status is being verified
 * @param divisionId Id of the division in which the patient is getting treated and insurance is applicable
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getInsuranceEligibility = async ({ patientId, divisionId, patientInfo, insuranceInfo }) =>
  client().post(`/insurance/check-eligibility`, { patientId, divisionId, patientInfo, insuranceInfo }, {
    timeout: 60000,
  })

export const getClaimInformation = async ({ visitType, id }) =>
  client().get(`/insurance/get-claim?visitType=${visitType}&id=${id}`)

export const submitInsuranceClaim = async (data) => client().post('/insurance/submit-claim', data, {
  timeout: 120000,
})

export const getInsurerGroups = async () => client().get('/insurance/get-insurergroups')
export const getInsurers = async ({ key, data }) => client().get(`/insurance/get-insurers?${key}=${data}`)