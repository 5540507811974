import React from 'react'
import T from 'prop-types'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import SceneHeader from 'app/views/layouts/Scene/SceneHeader'
import SceneContent from 'app/views/layouts/Scene/SceneContent'
import _ from 'lodash'

const getPadding = (theme, compact = false) => compact ? 0 : 2

const useStyles = makeStyles(({ spacing }) => {
  const basePadding = getPadding({ spacing })
  return {
    root: {
      flexGrow: 1,
      overflowY: 'auto',
    },
    rootNormal: {
      padding: spacing(0, basePadding, 2),
    },
    rootCompact: {
      padding: spacing(0, 0, 2),
    },
  }
})

const defaultFlexProps = {
  flexDirection: 'column',
}

const Scene = ({
  children,
  scrollableContent,
  compact,
  header,
  loading,
  preScrollableContent,
  scrollableContentLoading,
  postScrollableContent,
  themeBg,
  className,
  style = {},
  flexProps = {},
}) => {
  const classes = useStyles()
  const { palette } = useTheme()
  if(scrollableContent) {
    flexProps.alignContent = 'stretch'
  }
  if(themeBg) {
    style.backgroundColor = _.get(palette, themeBg)
  }
  return (
    <Box
      display='flex'
      className={clsx({
        [classes.root]: true,
        [classes.rootCompact]: compact,
        [classes.rootNormal]: !compact,
        [className]: true,
      })}
      {...defaultFlexProps}
      {...flexProps}
      style={style}
    >
      {header && <SceneHeader header={header} compact={compact} themeBg={themeBg} />}
      <SceneContent
        loading={loading}
        themeBg={themeBg}
        scrollableContent={scrollableContent}
        preScrollableContent={preScrollableContent}
        scrollableContentLoading={scrollableContentLoading}
        postScrollableContent={postScrollableContent}
      >
        {children}
      </SceneContent>
    </Box>
  )
}

Scene.propTypes = {
  loading: T.oneOfType([
    T.bool, // If true, display loading spinner instead of children.
    T.string, // If string, display loading spinner with message.
  ]),
  header: T.object,
  preScrollableContent: T.element,
  scrollableContent: T.func, // will be provided width and height props
  scrollableContentLoading: T.bool,
  postScrollableContent: T.element,
  compact: T.bool,
  justifyContent: T.string,
  alignContent: T.string,
  themeBg: T.string, // dot-notation path of theme.palette color to use
}

Scene.defaultProps = {
  loading: false,
  preScrollableContent: null,
  scrollableContent: null,
  postScrollableContent: null,
  alignContent: 'flex-start',
}

export default Scene
