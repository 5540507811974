export default function DataGrid (theme) {
  const separatorColor = theme.palette.secondary.light
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell': {
            borderBottomColor: separatorColor,
          }, '& .MuiDataGrid-columnSeparator': {
            color: separatorColor,
          },
        },
      },
    },
  }
}