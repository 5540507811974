import Grid from '@mui/material/Grid'
import FormikDebugControl from '../../../../components/form/mui-formik/FormikDebugControl'
import React, { useEffect, useState } from 'react'
import SubmitButton from '../../../../components/form/mui-formik/SubmitButton'
import makeStyles from '@mui/styles/makeStyles'
import { Field } from 'formik'
import SelectField from 'app/views/scenes/personal/IntakeForms/Form/steps/common/SelectField'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import _ from 'lodash'
import clsx from 'clsx'
import TextField from '../../../../components/form/mui-formik/TextField'
import useThemeConfig from '../../../../hooks/useThemeConfig'
import { getTheme } from '../../../../../../utils/theme'
import fontsList from './fontsList.json'

const useStyles = makeStyles(({ palette }) => ({
  themeItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  themeSquare: {
    width: '1.5rem',
    height: '1.5rem',
    borderStyle: 'solid',
    borderWidth: '.25rem',
    cursor: 'pointer',
  },
  themeSelected: {
    backgroundColor: palette.primary.main,
    color: palette.text.primary,
  },
}))
const themes = [{
  label: 'Theme 1',
  key: 'theme1',
}, {
  label: 'Theme 2',
  key: 'theme2',
}, {
  label: 'Theme 3',
  key: 'theme3',
}, {
  label: 'Theme 4',
  key: 'theme4',
}, {
  label: 'Theme 5',
  key: 'theme5',
}, {
  label: 'Theme 6',
  key: 'theme6',
}, {
  label: 'Theme 7',
  key: 'theme7',
}, {
  label: 'Theme 8',
  key: 'theme8',
},
]
export default ({
  values,
  handleSubmit,
  setFieldValue,
}) => {
  const classes = useStyles()
  const [fontFamilies, setFontFamilies] = useState([])
  const [themesSet, setThemesSet] = useState(null)
  const { themeDirection, mode, fontFamily } = useThemeConfig()
  const [selectedTheme, setSelectedTheme] = useState(values.theme)
  useEffect(() => {

    const fonts = fontsList
    setFontFamilies(fonts)

    const themesSetObject = {}
    for (const themeItem of themes) {
      themesSetObject[themeItem.key] = getTheme({ themeDirection, mode, presetColor: themeItem.key, fontFamily })
    }
    setThemesSet(themesSetObject)
  }, [])
  const setTheme = (theme) => {
    setSelectedTheme(theme)
    setFieldValue('theme', theme)
  }
  const generateThemeList = () => {
    if(!themesSet) return null
    return _.map(themes, ({ key, label }) => {
      const { palette: { primary, text } } = themesSet[key]
      return (
        <Grid key={key} item xs={3}
              className={clsx({ [classes.themeSelected]: key === selectedTheme, [classes.themeItem]: true })}>
          <Box className={classes.themeSquare}
               sx={{
                 borderColor: text.primary,
                 backgroundColor: primary.main,
               }}
               onClick={() => setTheme(key)} />
          <Typography variant={'body2'}>{label}</Typography>
        </Grid>
      )
    })
  }
  return (
    <form
      onSubmit={handleSubmit}
      style={{ width: '400px' }}
    >
      <Grid
        container
        spacing={1}
        mt={'60px'}
      >
        {generateThemeList()}
        <Grid item xs={12} sx={{ display: 'none' }}>
          <Field
            label={'Theme'}
            variant={'outlined'}
            margin={'normal'}
            type={'text'}
            name='theme'
            id={'theme'}
            component={TextField}
            fullWidth
            hintText={''}
            onChange={color => {((color) ? setFieldValue('theme', color) : '')}}>
          </Field>
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label='Font Family'
            name='fontFamily'
            itemData={fontFamilies}
            multiple={true}
            required
            margin='normal'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            label={'Update'}
            stacked={false}
            fullWidth
          />
        </Grid>
      </Grid>
      <FormikDebugControl />
    </form>
  )
}