import { call, cancel, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import actions from 'app/state/ducks/actions'
import { getPostLoginPath, getRole, getUserId, isMasterPassComplete } from 'app/state/ducks/selectors'
import { getUser, upsertPass } from 'api/user'
import * as Sentry from '@sentry/react'
import { getAndStoreConstants } from 'utils/site-context'
import store from 'utils/local-storage'
import { logOut } from '../../../../api/auth'
import { getDivision, getOrg } from 'utils/org'

export function * login () {
  const { login, editPass } = actions.user
  const { alert } = actions.dialog
  try {
    let user = {}
    try {
      user = yield call(getUser)
      // Get larger set of constants accessible to authenticated users if not already loaded:
      if(!getDivision()) yield call(getAndStoreConstants)
    } catch (e) {
      yield put(actions.handleError({ e, loggingIn: true }))
      yield cancel()
    }
    if(user.notice) yield put(notice.add(user.notice))
    const { id: orgId, division } = yield call(getOrg)
    if(!division) {
      yield put(alert.open({
        title: 'Incorrect Sign-In Page',
        message: 'Please use the link or QR code you were provided.',
        signOutAfterClose: true,
      }))
      yield cancel()
    }
    if(!user.isSiteOrgStaff) delete user.role
    yield put(login.success(user))
    yield put(actions.backgroundSync.start())
    Sentry.configureScope(scope => {
      scope.setTag('orgId', orgId)
      scope.setTag('orgDivision', division?.name || 'none')
      scope.setTag('role', user.role)
      scope.setUser(_.pick(user, ['id', 'role', 'divIds', 'locIds']))
    })
    let postLoginPath = yield select(getPostLoginPath)
    // Force them to update master health ID if incomplete:
    const masterPassComplete = yield select(isMasterPassComplete)
    const passUpdateRequired = !masterPassComplete
    if(passUpdateRequired) {
      yield put(editPass(user.id))
      postLoginPath = '/personal/dashboard'
    }
    store.remove('landing')
    yield put(push(postLoginPath))
  } catch (error) {
    yield put(login.failure())
    yield * logout()
  } finally {
    yield put(actions.appinit.success())
  }
}

export function * watchLogin () {
  yield takeEvery(actions.user.login.start, login)
}

export function * logout () {
  const role = yield select(getRole)
  yield put(actions.backgroundSync.stop())
  store.remove('printingTestLabelId')
  yield call(logOut)
  yield put(push('/'))
  yield put(actions.user.logout.success())
}

export function * watchLogout () {
  yield takeEvery(actions.user.logout.start, logout)
}

export function * savePass ({ payload: pass, meta: { resetForm } = {} }) {
  const { savePass } = actions.user
  const passId = pass.userId
  const masterUserId = yield select(getUserId)
  const isMasterPass = masterUserId === passId
  const masterPassWasComplete = yield select(isMasterPassComplete)
  const isNewMasterPass = isMasterPass && !masterPassWasComplete
  const isNewCompanionPass = !passId
  const isNewPass = (isNewMasterPass || isNewCompanionPass)
  try {
    const { savedPass } = yield call(upsertPass, { pass })
    yield put(savePass.success(savedPass))
  } catch (e) {
    yield put(savePass.failure())
    yield put(actions.handleError({
      e,
      alertCfg: {
        title: 'Sorry, there was a problem',
        message: `We could not ${isNewPass ? 'create' : 'update'} your Health ID. Please try again.`,
      },
      sentryCfg: {
        tags: {
          section: 'user',
          func: 'upsertPass',
        },
        extra: {
          passId,
          masterUserId,
        },
      },
    }))
    if(resetForm) {
      yield call(resetForm)
    }
  }
}

export function * watchSavePass () {
  yield takeEvery(actions.user.savePass.start, savePass)
}
