import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default props => (
  <SvgIcon
    x='0px' y='0px' viewBox='0 0 32 40'
    {...props}
    color='action'
  >
    <path
      d='M29.85,7.44,28.37,6,26.14,3.73,24.66,2.24a1,1,0,0,0-1.42,1.42l.78.77L21.33,7.12l-.77-.78a1,1,0,0,0-.71-.29,1,1,0,0,0-.71.29L17,8.44,20.3,11.7a1,1,0,0,1,0,1.42,1,1,0,0,1-.71.29,1,1,0,0,1-.71-.29L15.63,9.86,13,12.44,16.3,15.7a1,1,0,0,1,0,1.42,1,1,0,0,1-.71.29,1,1,0,0,1-.71-.29l-3.25-3.26L8.21,17.27a1,1,0,0,0,0,1.42l0,0L6.9,20.06a1,1,0,0,0,0,1.42l1.15,1.15L2.61,28.07a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3L9.47,24l1.15,1.15a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29l1.34-1.34,0,0a1,1,0,0,0,1.41,0L25.75,13a1,1,0,0,0,0-1.41L25,10.76l2.68-2.69.78.78a1,1,0,0,0,1.41,0A1,1,0,0,0,29.85,7.44Z'
    />
  </SvgIcon>
)
