export default ({open = false}) => {
  return (<div className={`container ${open ? 'hide_mobile' : ''}`} style={{ maxHeight: '60px', maxWidth: '100%' }}>
      <nav className='navbar' style={{ justifyContent: 'spaceEvenly', marginTop: '-5px' }}>
        <div className='navmenumain'>
          <ul className='nav_menu_top'>
            <li className='nav_item'>
              <a href='https://truewellth.care' className='nav_link'
                 style={{ backgroundColor: '#42A5F5', borderColor: '#42A5F5' }}>Home</a>
            </li>
            <li className='nav_item'>
              <a href='https://diet.truewellth.care/' className='nav_link'
                 style={{
                   backgroundColor: '#81D4FA',
                   borderColor: '#81D4FA',
                 }}>Diet</a>
            </li>
            <li className='nav_item'>
              <a href='https://myhealth.truewellth.care' className='nav_link active_nav'
                 style={{ backgroundColor: '#BDBDBD', borderColor: '#BDBDBD' }}>My Health</a>
            </li>
            <li className='nav_item'>
              <a href='https://www.truewellth.market/pages/get-started' className='nav_link'
                 style={{ backgroundColor: '#9CCC65', borderColor: '#9CCC65' }}>Market</a>
            </li>
            <li className='nav_item'>
              <a href='https://grow.truewellth.market/' className='nav_link '
                 style={{ backgroundColor: '#66BB6A', borderColor: '#66BB6A' }}>Farmer</a>
            </li>
            <li className='nav_item'>
              <a href='https://soilmate.truewellth.market/' className='nav_link'
                 style={{ backgroundColor: '#1B5E20', borderColor: '#1B5E20' }}>Soil mate</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}