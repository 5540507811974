import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import actions from 'app/state/ducks/actions'
import autoBindReact from 'auto-bind/react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import { isNonEmptyArray } from '@sustainhawaii/object-utils'

class Confirm extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    question: PropTypes.oneOfType([
      PropTypes.string, // Single message string to display in dialog content text.
      PropTypes.arrayOf(PropTypes.string), // Multiple lines of text to display in dialog content text.
    ]),
    yesButtonLabel: PropTypes.string,
    noButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    forceYes: PropTypes.bool,
    cancelable: PropTypes.bool,
    respond: PropTypes.func.isRequired,
    callback: PropTypes.func, // optional callback for use outside of sagas
    open: PropTypes.bool,
  }

  static defaultProps = {
    question: 'Are you sure?',
    yesButtonLabel: 'Yes',
    noButtonLabel: 'No',
    cancelButtonLabel: 'Cancel',
    forceYes: false,
    cancelable: false,
    open: false,
  }

  constructor () {
    super()
    autoBindReact(this)
  }

  decide (how) {
    const { respond, callback } = this.props
    respond(how)
    callback && callback(how)
  }

  render () {
    const { props, decide } = this
    const {
      title,
      question,
      yesButtonLabel,
      noButtonLabel,
      cancelButtonLabel,
      forceYes,
      cancelable,
      open,
    } = props
    let dialogContentText = ''
    let ariaDescription = ''
    if (typeof question === 'string') {
      dialogContentText = question
      ariaDescription = question
    } else if (isNonEmptyArray(question)) {
      dialogContentText = (
        <>
          {question.map((txt, i) => (
            <Typography
              key={i}
              variant='body1'
              gutterBottom
              component='span'
              display='block'
            >
              {txt}
            </Typography>
          ))}
        </>
      )
      ariaDescription = question.join(': ')
    }
    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby={title || 'Dialog'}
          aria-describedby={ariaDescription}
        >
          {title && <DialogTitle>{title}</DialogTitle>}
          {!_.isEmpty(dialogContentText) && (
            <DialogContent>
              <DialogContentText>{dialogContentText}</DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            {!forceYes && (
              <Button
                onClick={() => decide(false)}
              >
                {noButtonLabel}
              </Button>
            )}
            <Button
              onClick={() => decide(true)}
              color='primary'
            >
              {yesButtonLabel}
            </Button>
            {cancelable && (
              <Button
                onClick={() => decide()}
              >
                {cancelButtonLabel}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => state.dialog.confirm

const mapDispatchToProps = dispatch => {
  const { yes, no, cancel } = actions.dialog.confirm
  return {
    respond: respondedYes => {
      let func = cancel
      if(_.isBoolean(respondedYes)) func = respondedYes ? yes : no
      dispatch(func())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm)
