import { createActions } from 'redux-actions'
import phases from '../actionPhases'

export default createActions({
  LAB: {
    LOAD_PATIENT: phases.formik,
    SAVE_TEST: phases.formik,
    SUBMIT_SELF_TEST: phases.formik,
    LOAD_TEST: phases.formik,
    NEW_TEST: undefined,
    EDIT_TEST: undefined,
    CANCEL_EDIT: undefined,
    SAVE_VACC: phases.formik,
    LOAD_VACC: phases.formik,
    NEW_VACC: undefined,
    EDIT_VACC: undefined,
    CANCEL_EDIT_VACC: undefined,
    LOAD_ACTION_ITEMS: phases.formik,
    SET_CURRENT_VISIT_TYPE: undefined,
    LOAD_INSURANCE_ELIGIBILITY: phases.formik,
    LOAD_INSURANCE_CLAIM_INFO: phases.formik,
    SET_INSURANCE_CLAIM: phases.formik,
    FETCH_INSURER_GROUPS: phases.formik,
    FETCH_INSURERS: phases.formik,
    UPDATE_STEP_STATUS: undefined,
  },
})