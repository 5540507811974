import getCommonSchemas from 'schemas/commonSchemas'
import { isValid } from '@sustainhawaii/heal-schemas'
import store from 'utils/local-storage'
import { createSelector } from 'reselect'
import {
  adminSalesMgrDashBoardPath,
  personalDashBoardPath,
} from 'config/const.json'
import _ from 'lodash'
import qs from 'query-string'
import { systemRoles } from 'app/components/Drawer/DrawerContent/Navigation/feature-config'

export const getUser = state => state.user || {}
export const getUserId = state => getUser(state).userId
export const getLoggedIn = state => !!getUserId(state)
export const getLoggingIn = state => getUser(state).loggingIn

export const getPasses = state => getUser(state).passes || {}
export const getPass = userId => createSelector(
  [getPasses],
  passes => passes[userId],
)
export const getMasterPass = state => {
  const passes = getPasses(state)
  return passes[getUserId(state)]
}
export const getCurrentPassId = state => getUser(state).currentPassId
export const getEditingPass = state => getUser(state).editingPass
export const canAddPasses = state => Object.keys(getPasses(state)).length - 1 < store.get('constants.MAX_COMPANION_PASSES')
export const getRole = state => getUser(state).role
export const getEthnicity = state => getUser(state).ethnicity
export const getIsMedStaff = state => ['Admin', 'Account Owner', 'HCP'].includes(getRole(state))
export const getIsSalesManager = state => ['Sales Manager'].includes(getRole(state))
export const getCanViewCoreAdmin = state => ['Admin', 'Account Owner'].includes(getRole(state))
export const getCanViewPHI = state => ['Admin', 'Account Owner', 'HCP', 'Analyst X'].includes(getRole(state))
export const getCanUseAdmin = state => {
  const role = getRole(state)
  if(!role) return false
  if(systemRoles.includes(role)) {
    const { divIds, locIds } = getUser(state)
    return ['Admin', 'Account Owner', 'Sales Manager', 'Prospect AO'].includes(role) || (['HCP', 'Analyst R', 'Analyst X'].includes(role) && !(_.isEmpty(divIds) && _.isEmpty(locIds)))
  }
  return true   // for custom roles - access is defined by permissions
}
export const getUserPermissions = state => getUser(state).permissions
export const getAdminFilters = state => {
  if(['Admin', 'Account Owner'].includes(getRole(state))) return {}
  const { divIds, locIds } = getUser(state)
  return { divIds, locIds }
}
export const isMasterPassComplete = state => {
  const masterPass = getMasterPass(state)
  return isValid(getCommonSchemas().pass, masterPass)
}

export const getPostLoginPath = state => {
  const landing = store.get('landing')
  if(landing) {
    // If there is a query param, append that too to the path to retrieve the desired resource
    if(landing.search) return `${landing.pathname}${landing.search}`
    return landing.pathname
  }
  return getDashBoardPath(state)
}

export const getIsCurrentMasterHealthId = createSelector(
  [getUserId, getCurrentPassId],
  (userId, currentPassId) => userId === currentPassId,
)

// These are for development experimentation:
let dashBoardPathOverride = null
dashBoardPathOverride = false // To keep previous line from switching to const when formatting code.

// dashBoardPathOverride = '/admin/tests-list'
// dashBoardPathOverride = '/admin/patients'
// dashBoardPathOverride = '/admin/dashboard'
// dashBoardPathOverride = '/admin/lab-account-manager'
// dashBoardPathOverride = '/admin/test-bed'
// dashBoardPathOverride = '/admin/division-manager'
// dashBoardPathOverride = '/lab/scheduled-visits'
// dashBoardPathOverride = '/personal/my-results'
// dashBoardPathOverride = '/lab/visit?patientId=6268df4e6be831b9e0073288'
// dashBoardPathOverride = '/lab/visit/?testId=ca03392b-4313-4d38-9c28-e4f6eff5baaf' // pending test
// dashBoardPathOverride = '/personal/intake-forms'
// dashBoardPathOverride = '/personal/intake-form?userId=614ed24b63fab65cce935547'
// dashBoardPathOverride = '/lab/visit?patientId=61c29653b2386e2d058525c0' // Master
// dashBoardPathOverride = '/lab/visit?patientId=60b344ea2aff5510334b4e3c' // Companion
// dashBoardPathOverride = '/lab/visit/?testId=9fded531-ec5a-45f2-84fa-6167dd737552'
// dashBoardPathOverride = '/personal/intake-form?userId=60545d8f63e63458cb2bdfb3'
// dashBoardPathOverride = '/personal/intake-form?formId=60d25082fccab52a731c3ad2'
// dashBoardPathOverride = '/scheduling/select-slot?userId=60545d8f63e63458cb2bdfb3&locId=ChIJkyrqXbjHwoAR1bJ76zx89B8&visitType=test'
// dashBoardPathOverride = '/gate-keeper?userId=60b344ea2aff5510334b4e3c'

export const getDashBoardPath = (state) => {
  if(dashBoardPathOverride) return dashBoardPathOverride
  if(getCanUseAdmin(state)) {
    if(getIsSalesManager(state)) {
      return adminSalesMgrDashBoardPath
    }
  }
  return personalDashBoardPath
}

export const getVisitSchedulerHrefGetter = createSelector(
  [getUserId, getPasses],
  (userId, passes) => ({
    locId,
    visitType,
    selfTest = false,
  }) => {
    const { path, params } = _.size(passes) < 2
      ? {
        path: selfTest ? '/lab/visit' : '/Scheduling/select-slot',
        params: selfTest ? { patientId: userId } : { userId },
      }
      : {
        path: '/Scheduling/select-user',
        params: {},
      }
    const queryString = qs.stringify({ ...params, locId, visitType, selfTest })
    if(!locId) return `/scheduling/select-scheduling-type?${queryString}`
    return `${path}?${queryString}`
  },
)

export const getNotificationList = (state) => {
  return state
}

export const getNotifications = createSelector([getNotificationList], state => state.notifications)

export const getMyOrg = (state) => state.myOrg