import React, { useMemo } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { getOrgProperty } from 'utils/org'
import _ from 'lodash'

export default props => {
  const items = useMemo(() => getOrgProperty('divisionSwitcherItems'), [])
  if (!items) return null
  return (
    <List {...props}>
      {_.map(items, (name, id) => (
        <ListItem
          key={id}
          button
          onClick={() => {
            window.location.href = `/div/${id}`
          }}
        >
          {name}
        </ListItem>
      ))}
    </List>
  )
}
