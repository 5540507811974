import _ from 'lodash'
import { snakeStartCase } from 'utils/string'

export const personNameDefault = {
  first: '',
  middleInitial: '',
  last: '',
  suffix: '',
}

export const emptyPass = {
  name: personNameDefault,
  photoUrl: '',
  phoneNumber: '',
  email: '',
  vitals: {
    gender: '',
    birthDate: new Date(),
  },
  govtIdCardFront: '',
  selfiePhoto: '',
  govtIdExists: true,
}

export const getCheckBoxDefsFromTextList = txt => {
  const lines = getLinesFromTextBlock(txt)
  return _.map(lines, label => ({ value: snakeStartCase(label), label }))
}

export const arr2keyValPairs = arr => _.chain(arr).keyBy().mapValues().value()

export const arr2LabelValuePairs = arr => _.map(arr, value => ({ value, label: value }))

export const obj2LabelValuePairs = obj => _.map(obj, (label, value) => ({ label, value }))

export const getCheckBoxDefsFromIdsLabels = ({ idLabelPairs, sorted = false }) => _.chain(idLabelPairs)
  .map(({ id, label }) => ({ value: id, label }))
  .thru(val => sorted ? _.sortBy(val, 'label') : val)
  .value()

const getLinesFromTextBlock = txt => _.split(txt, '\n')

export const quoteObjectPaths = (obj, paths) => {
  _.forEach(paths, path => {
    if (_.has(obj, path)) _.set(obj, path, `"${_.get(obj, path)}"`)
  })
}
