import actions from 'app/state/ducks/actions'
import { useDispatch } from 'react-redux'
import { getSagaCall } from 'utils/saga'

const { login, logout, update, savePass, editPass, orgStatus } = actions.user

export default () => {
  const dispatch = useDispatch()
  return {
    login: getSagaCall(login),
    logout: getSagaCall(logout),
    updateUser: getSagaCall(update),
    savePass: getSagaCall(savePass),
    editPass: params => dispatch(editPass(params)),
    orgStatus: params => dispatch(orgStatus(params)),
  }
}