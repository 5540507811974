import React from 'react'
import CenteredContentContainer from 'app/views/components/layout/CenteredContentContainer'
import CircularProgressStyled from 'app/views/components/progress/CircularProgressStyled'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { useTheme } from '@mui/material/styles'

export default ({
  message,
  themeBg,
}) => {
  const { spacing, palette, typography } = useTheme()
  const color = themeBg ? palette.primary.dark : _.get(palette, themeBg)
  return (
    <CenteredContentContainer>
      {
        typeof message === 'string' &&
        message.length && (
          <Typography
            variant='h5'
            align='center'
            gutterBottom
            style={{
              fontWeight: typography.fontWeightBold,
              color,
            }}
          >
            {message}
          </Typography>
        )
      }
      <CircularProgressStyled
        size={60}
        style={{
          color,
          marginTop: spacing(4),
        }}
      />
    </CenteredContentContainer>
  )
}
