import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { routerActions } from 'connected-react-router'
import SuspenseAndLoader from 'app/views/components/SuspenseAndLoader'
import SceneLoadingProgress from 'app/views/components/SceneLoadingProgress'
import { configureStore } from 'app/state/store'
import actions from 'app/state/ducks/actions'
import {
  getDashBoardPath,
  getLoggedIn,
  getRole,
  getUserPermissions,
} from 'app/state/ducks/selectors'
import withProps from 'recompose/withProps'
import { compose } from 'recompose'
import { isNonEmptyArray } from '@sustainhawaii/object-utils'
import _ from 'lodash'
import { systemRoles } from 'app/components/Drawer/DrawerContent/Navigation/feature-config'

const reduxStore = configureStore()

/**
 * Wrap a route with this if you have to be logged in to view it.
 */
const withAuthenticationRequired = connectedRouterRedirect({
  redirectPath: '/',
  redirectAction: routerActions.replace,
  authenticatedSelector: getLoggedIn,
  AuthenticatingComponent: SceneLoadingProgress,
  wrapperDisplayName: 'withAuthenticationRequired',
})

const checkRouteAccess = ({ state, userRoles, features }) => {
  const role = getRole(state)
  const permissions = getUserPermissions(state)

  if(systemRoles.includes(role)) {
    return userRoles.includes(role)
  } else {
    const allowedFeatures = _.chain(permissions).map(perm => {
      if(perm.view) { return perm.featureName }
    }).compact().value()

    return _.intersection(allowedFeatures, features)?.length > 0
  }
}

/**
 * Wrap a route with this if you have to have a specific user role to view it.
 */
const withUserRolesRequired = ({ userRoles, features }) => connectedRouterRedirect({
  redirectPath: getDashBoardPath,
  redirectAction: routerActions.replace,
  authenticatedSelector: state => checkRouteAccess({ state, userRoles, features }),
  AuthenticatingComponent: SceneLoadingProgress,
  wrapperDisplayName: 'withUserRolesRequired',
})

const getRouteEnhancers = ({
  authRequired = false,
  userRoles = [],
  features = [],
  commonProps = {},
  routeSpecificProps = {},
}) => {
  const enhancers = []
  if(isNonEmptyArray(userRoles) || isNonEmptyArray(features)) {
    authRequired = true
    enhancers.push(withUserRolesRequired({ userRoles, features }))
  }
  // Determine the user wrapper if any:
  if(authRequired) {
    enhancers.push(withAuthenticationRequired)
  }
  const extraRouteProps = {
    ...commonProps,
    ...routeSpecificProps,
  }
  if(!_.isEmpty(extraRouteProps)) {
    enhancers.push(withProps(extraRouteProps))
  }
  return enhancers
}

const getRouteFromConfig = ({
  routeConfig: {
    path,
    component,
    exact,
    authRequired,
    userRoles,
    features,
    ...routeSpecificProps
  },
  baseRoutePath,
  routeKey,
  ...rest
}) => {
  if(!_.isString(path) && !_.isArray(path)) {
    path = baseRoutePath
  }
  const enhancers = getRouteEnhancers({
    authRequired,
    userRoles,
    features,
    routeSpecificProps,
    ...rest,
  })

  const EnhancedSuspenseAndLoader = compose(...enhancers)(SuspenseAndLoader)
  return (
    <Route
      key={routeKey}
      path={path}
      exact={exact}
      render={routerProps => (
        <EnhancedSuspenseAndLoader
          component={lazy(() => getComponent(component))}
          {...routerProps}
        />
      )}
    />
  )
}

const getComponent = component => {
  return new Promise((resolve, reject) => {
    component().then(resolve).catch(error => {
      if(error?.name === 'ChunkLoadError') {
        reduxStore.dispatch(actions.handleError({ e: error }))
        resolve(null)
        return
      }
      reject(error)
    })
  })
}

/**
 * Return array of Route elements.
 *
 * @param {object} params
 * @param {object[]} params.routeConfigs
 * @param {string=} params.baseRoutePath - used along with the route config object's name prop to build path if missing
 * @param {object=} params.commonProps - props to pass to all components rendered in routes.
 * @return {Route[]}
 */
export const getRoutesFromConfigs = ({
  routeConfigs,
  ...rest
}) => _.chain(routeConfigs).filter('enabled').map((routeConfig, routeKey) => getRouteFromConfig({
  routeConfig,
  routeKey,
  ...rest,
})).value()
