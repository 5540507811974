import useNotifier from 'app/views/hooks/useNotifier'
import Loading from 'app/views/components/dialogs/Loading'
import Alert from 'app/views/components/dialogs/Alert'
import Confirm from 'app/views/components/dialogs/Confirm'
import Details from 'app/views/components/dialogs/Details'
import React from 'react'

export default () => {

  useNotifier()

  return (<>
    <Loading />
    <Alert />
    <Confirm />
    <Details />
  </>)

}