import { isValid } from '@sustainhawaii/heal-schemas'
import { client, createFormData, multiPartClient } from './client'
import getCommonSchemas from 'schemas/commonSchemas'
import { mapAwsS3UrlsToFileInputVals } from 'utils/aws'
import { getAnniversaryDateISO, getLocalZoneName } from 'utils/temporal'
import { getSiteContext } from 'utils/site-context'
import { objectWithoutNonInfoValues } from '@sustainhawaii/object-utils'
import { getFormCompatibleIntakeFormData } from 'utils/intake-form'
import { HEALTH_HISTORY_STATUS } from '../app/config/health-data/constants'

/**
 * Get passes and other stuff needed after login.
 *
 * @return {Promise<void|object>}
 */
export const getUser = async () => {
  const { invitationId } = getSiteContext()
  const response = await client().get(`/user${invitationId ? `?invitationId=${invitationId}` : ''}`)
  return response?.data
}

/**
 * @param {object} pass
 *
 * @return {Promise<void|object>}
 */
export const upsertPass = async ({ pass }) => {
  // fix: PRS-1501 - use dob without timezone conversion
  if(!_.isEmpty(pass?.vitals?.birthDate)) pass.vitals.birthDate = getAnniversaryDateISO(pass.vitals.birthDate)
  const result = {}
  const fd = await createFormData(objectWithoutNonInfoValues(pass))
  // Post new or updated pass:
  const res = await multiPartClient().post('/passes', fd)
  /*
  Validate saved pass and log errors to console.
  Return submitted values if saved pass invalid (probably because of server error).
   */
  const schema = getCommonSchemas().pass
  const { savedPass, existingPass, latestIntakeForm } = res?.data || {}
  result.existingPass = existingPass
  result.latestIntakeForm = latestIntakeForm
  if(isValid(schema, savedPass)) result.savedPass = await mapAwsS3UrlsToFileInputVals({ obj: savedPass })
  return result
}

/**
 * Retrieve all visits of userId and their companions.
 *
 * @param {string} userId - account holder id
 * @return {Promise<*>}
 */
export const getFamilyVisits = async userId => {
  const response = await client().get(`/user/visits/${userId}`)
  return response?.data
}

export const getIntakeForms = async () => {
  const response = await client().get('/user/intake-forms')
  return response?.data || []
}

export const getIntakeForm = async formId => {
  const response = await client().get(`/user/intake-form/${formId}`)
  return getFormCompatibleIntakeFormData(response)
}

export const getIntakeFormGuardian = async formId => {
  const { data } = await client().get(`/user-guardian/intake-form/${formId}`)
  return data
}

export const getLatestIntakeFormOfUser = async userId => {
  const response = await client().get(`/user/latest-intake-form/${userId}`)
  return getFormCompatibleIntakeFormData(response)
}

/**
 * @param {IntakeForm} intakeForm
 *
 * @return {Promise<void|object>}
 */
export const upsertIntakeForm = async intakeForm => {
  const fd = await createFormData(intakeForm)
  // Post new or updated intake form:
  const res = await multiPartClient().post('/user/intake-form', fd)
  return res?.data || {}
}

export const recordScheduledVisit = data => client().post('/user/record-scheduled-visit', data)

/**
 * @param {IntakeForm} intakeForm
 * @return {Promise<void|object>}
 */
export const cosignIntakeForm = async intakeForm => {
  const fd = await createFormData(intakeForm)
  // Cosign intake form:
  const res = await multiPartClient().post('/user-guardian/intake-form', fd)
  return res?.data || {}
}

export const submitSymptomSurvey = async survey => {
  const fd = await createFormData(survey)
  // Post new symptom survey:
  return client().post('/user/symptom-survey', fd)
}

export const submitSMART = async params => {
  const { data } = await client().post('/user/update-vaccination-access', params)
  return data
}

export const generateNewQRSecret = async ({ userId }) => {
  const { data } = await client().post('/user/secret', { userId })
  return data
}

/**
 * Retrieve all visits of userId and their companions.
 *
 * @param {string} userId - account holder id
 * @return {Promise<*>}
 */
export const deleteUserAccount = async ({ userId }) => {
  const { data } = await client().post('/user/deleteAccount', { userId }, {
    timeout: 300000,
  })
  return data || {}
}

/**
 * Set specific user property of user
 * @param userData
 * @returns {Promise<any|{}>}
 */
export const setUserProperty = async (userData) => {
  const { data } = await client().post('/user/set-user-property', { userData }, {
    timeout: 30000,
  })
  return data || {}
}

export const getUserStatus = async ({ userId }) => {
  const response = await client().post('/user/get-user-status', { userId, tz: getLocalZoneName() }, { timeout: 300000 })
  let data = response?.data
  if(!data) return
  return data
}
export const calculateDRI = async (userPhysicalAttributes) => {
  const response = await client().post('/user/calculate-dri', { ...userPhysicalAttributes })
  let data = response?.data
  if(!data) return
  return data
}
export const getDRIStatus = async () => {
  const response = await client().get('/user/dri-results')
  let data = response?.data
  if(!data) return
  return data
}


export const getFoodPreferences = async (preferences) => {
  const response = await client().post('/fcs/food-match-nutrients', preferences , {
    timeout: 300000,
  })
  const data = response?.data
  return data
}

export const getFoodFromGtinUpc = async (preferences) => {
  const response = await client().post('/fcs/food-match-nutrients', preferences , {
    timeout: 300000,
  })
  const data = response?.data
  return data
}

export const setFoodPreferences = async (preferences) => {
  const response = await client().post('/user/set-food-preferences', { preferences })
  const data = response?.data
  return data
}

// Health Gorilla backend API calls - Start

export const isHealthHistoryInit = async () => {
  try {
    const healthHistoryStatus = await client().get(`/health-history/status`)
    return healthHistoryStatus.data
  } catch (err) {
    // Status fetch will fail and return with 404 in case the user data was not found
    return { status: HEALTH_HISTORY_STATUS.uninitialized, err }
  }
}
export const initHealthHistory = async () => {
  try {
    const healthHistoryInit = await client().post('/health-history/initialize', undefined, { timeout: 90000 })
    return healthHistoryInit.data
  } catch (err) {
    return { status: HEALTH_HISTORY_STATUS.fail, err }
  }

}

export const refreshHealthHistory = async () => {
  const healthHistoryRefresh = await client().post('/health-history/refresh', undefined, { timeout: 120000 })
  return healthHistoryRefresh.data
}
export const getHealthHistory = async ({ healthParam: { value } }) => {
  const healthRecords = await client().get(`/health-history/${value}`)
  return healthRecords.data
}
// Health Gorilla backend API calls - End

// Food Compass backend API calls - Start
export const searchFood = async ({ search }) => {
  const response = await client().get(`/fcs/get-food-details/?query=${search}&type=all`)
  return response?.data
}

export const getDiarySummary = async () => {
  const response = await client().get(`/fcs/get-food-diary-summary`)
  return response?.data
}

export const getDiaryDetail = async (date) => {
  const response = await client().post(`/fcs/get-food-diary-entry` , { date })
  return response?.data
}

export const saveDiaryDetail = async (diaryEntry) => {
  const response = await client().post(`/fcs/set-food-diary-entry` , { diaryEntry })
  return response?.data
}


export const getAlternateFoods = async ({ category }) => {
  const response = await client().get(`/fcs/get-alternate-foods/?category=${category}`)
  return response?.data
}

// Get food based on upcaid
export const searchForFoodByUpcaId = async ({ search }) => {
  const response = await client().get(`/fcs/get-food-by-upcaid/?query=${search}`)
  return response?.data
}
//store recipes
export const setRecipe = async (recipe) => {
  const response = await client().post('/fcs/set-recipe', { recipe })
  const data = response?.data
  return data
}
//store recipes
export const getRecipes = async (recipe) => {
  const response = await client().get('/fcs/get-recipes')
  const data = response?.data
  return data
}

export const searchFoodScore = async ({ fdcId }) => {
  const response = await client().get(`/fcs/get-fcs-score-by-fdcid/?fdcId=${fdcId}`, { timeout: 60000 })
  return response?.data
}
export const searchUpcCodeFoodScore = async ({ gtinUpc }) => {
  const response = await client().get(`/fcs/get-fcs-score-by-gtinUpc/?gtinUpc=${gtinUpc}`, { timeout: 60000 })
  return response?.data
}
// Food Compass backend API calls - End

// Diabetes profile API calls - Start
export const getDiabetesProfiles = async () => {
  const response = await client().get('/health-profile/diabetesProfile')
  return response.data
}

export const getDiabetesFoodGuide = async () => {
  const response = await client().post('/fcs/food-guide' , {disease : 'Diabetes-2'})
  return response.data
}
// Diabetes profile API calls - End

export const getFoodAttributes = async () => {
  const response = await client().get(`/fcs/food-attributes`)
  return response?.data
}

export const getFoodConstants = async () => {
  const response = await client().get(`/fcs/food-constants`)
  return response?.data
}

export const getFoodCategories = async () => {
  const response = await client().get(`/fcs/food-categories`)
  return response?.data
}

export const submitFoodAttributes = async (data) => client().post('/fcs/food-attributes', data)

export const submitFoodConstants = async (data) => client().post('/fcs/food-constants', data)

export const submitFoodCategories = async (data) => client().post('/fcs/food-categories', data)