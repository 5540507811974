import React from 'react'
import Paper from '@mui/material/Paper'
import IconAndText from './IconAndText'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing, palette }) => ({
  box: {
    borderRadius: spacing(2),
    padding: spacing(1.5),
  },
  icon: {
    color: 'white',
    backgroundColor: palette.primary.main,
    borderRadius: '50%',
    padding: spacing(1),
  },
}))

export default ({
  Icon,
  text,
  size = 'default',
  themeColor,
  styleColor,
  direction = 'right',
  ...props
}) => {
  const classes = useStyles()
  const iconTextProps = {
    Icon,
    text,
    size,
    themeColor,
    styleColor,
    direction,
    iconClassName: classes.icon,
  }
  return (
    <Paper
      className={classes.box}
      {...props}
    >
      <IconAndText
        {...iconTextProps}
      />
    </Paper>
  )
}
