import _ from 'lodash'
import { isNonEmptyString } from '@sustainhawaii/heal-common/lib/util/string'
import { blobToDataURL, dataURLToBlob } from 'blob-util'
import axios from 'axios'
import { isEnv } from './env'
import Jimp from 'jimp'
import * as Sentry from '@sentry/react'

/*
 Increase max memory usage of Jimp decoder:
  (from https://github.com/oliver-moran/jimp/issues/915#issuecomment-967163466)
 */
const cachedJpegDecoder = Jimp.decoders['image/jpeg']
Jimp.decoders['image/jpeg'] = (data) => {
  const opts = { maxMemoryUsageInMB: 1024 }
  return cachedJpegDecoder(data, opts)
}

let axiosInstance
const client = () => {
  if (axiosInstance) return axiosInstance
  axiosInstance = axios.create({
    timeout: 5000,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
  return axiosInstance
}

const JPEG_QUALITY = 72
/**
 * Return new image Blob scaled down to fit small dimensions and JPEG compressed 72% quality.
 *
 * @param {File|Blob} blob
 * @return {Promise<Blob>}
 */
export const getReducedImageBlob = async blob => {
  if (!_.isFunction(blob?.arrayBuffer)) return blob
  const { name, type, size } = blob
  const sentryConfig = { tags: { func: 'getReducedImageBlob' }, extra: { name, type, size } }
  let buffer
  let image
  try {
    buffer = await blob.arrayBuffer()
  } catch(e) {
    sentryConfig.extra.step = 'blob.arrayBuffer()'
    Sentry.captureException(e, sentryConfig)
    return blob
  }
  try {
    image = await Jimp.read(buffer)
  } catch(e) {
    sentryConfig.extra.step = 'Jimp.read(buffer)'
    Sentry.captureException(e, sentryConfig)
    return blob
  }
  try {
    buffer = await image.scaleToFit(1024, 1024).quality(JPEG_QUALITY).getBufferAsync(type)
  } catch(e) {
    sentryConfig.extra.step = 'image.scaleToFit'
    Sentry.captureException(e, sentryConfig)
    return blob
  }
  try {
    return new Blob([new Uint8Array(buffer)], { type })
  } catch (e) {
    sentryConfig.extra.step = 'new Blob'
    Sentry.captureException(e, sentryConfig)
    return blob
  }
}

export const getBlobFromImageDataUri = uri => {
  if (!isNonEmptyString(uri)) return
  return dataURLToBlob(uri)
}

export const getImageDataUri = ({
  data,
  mimeType,
}) => `data:${mimeType};base64,${data}`

export const retrieveImageDataUri = async url => {
  try {
    const res = await client().get(url, {
      responseType: 'blob',
    })
    if (res?.data) {
      return blobToDataURL(res.data)
    }
  } catch (e) {
    if (!isEnv('production')) console.error(`could not retrieve image data from "${url}"`, e)
  }
}

export const isImageDataUri = v => _.isString(v) && /^data:image/.test(v)
