import React from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import T from 'prop-types'
import useTranslateMapValues from '../../hooks/useTranslateMapValues'
import _ from 'lodash'

const useStyles = makeStyles(() => ({
  titleElement: {
    flexGrow: 1,
  },
}))

const SceneTitle = ({
  title: { center, main, sub },
  compact,
  themeBg,
}) => {
  const { palette } = useTheme()
  const { titleElement } = useStyles()
  const titles = useTranslateMapValues({
    obj: { main, sub },
  })
  return (
    <div
      key='titleElement'
      className={titleElement}
      style={{
        textAlign: center ? 'center' : 'left',
        color: themeBg ? palette.getContrastText(_.get(palette, themeBg)) : 'inherit',
      }}
    >
      <Typography variant={compact ? 'subtitle1' : 'h6'}>{titles.main}</Typography>
      {!_.isEmpty(titles.sub) && <Typography variant={compact ? 'caption' : 'body2'}>{titles.sub}</Typography>}
    </div>
  )
}

SceneTitle.propTypes = {
  themeBg: T.string,
  compact: T.bool,
  title: T.shape({
    main: T.oneOfType([T.string, T.element]),
    sub: T.oneOfType([T.string, T.element]),
    center: T.bool,
  }).isRequired,
}

export default SceneTitle
