import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default props => (
  <SvgIcon
    x='0px' y='0px' viewBox='0 -12 100 125' enableBackground='new 0 0 100 100'
    {...props}
    color='action'
  >
    <path d='M10,10v23.333h23.333V10H10z M26.667,26.667h-10v-10h10V26.667z' />
    <path d='M10,66.666V90h23.333V66.666H10z M26.667,83.334h-10v-10h10V83.334z' />
    <path d='M66.667,10v23.333H90V10H66.667z M83.333,26.667h-10v-10h10V26.667z' />
    <polygon points='83.333,40 83.333,60 66.667,60 66.667,66.666 90,66.666 90,40 ' />
    <polygon points='66.667,73.334 66.667,90 73.333,90 73.333,80 83.333,80 83.333,90 90,90 90,73.334 ' />
    <polygon points='40,10 40,16.667 53.333,16.667 53.333,33.333 60,33.333 60,10 ' />
    <polygon
      points='53.333,40 53.333,53.334 40,53.334 40,73.334 53.333,73.334 53.333,90 60,90 60,66.666 46.667,66.666 46.667,60   60,60 60,46.667 66.667,46.667 66.667,53.334 73.333,53.334 73.333,40 '
    />
    <rect x='40' y='80' width='6.667' height='10' />
    <rect x='23.333' y='53.334' width='10' height='6.666' />
    <polygon points='40,23.333 40,40 10,40 10,60 16.667,60 16.667,46.667 46.667,46.667 46.667,23.333 ' />
  </SvgIcon>
)
