import { client } from './client'
import { getLocalZoneName } from 'utils/temporal'

export const recordUserScanningUser = ({ scanneeId, locId }) => client().post('/gatekeeper/record-user-scanning-user', {
  scanneeId,
  locId,
})

export const getGateKeeperScan = async ({ userId }) => {
  const response = await client().post('/gatekeeper/gate-keeper-scan', { userId, tz: getLocalZoneName() })
  let data = response?.data
  if (!data) return
  return data
}
