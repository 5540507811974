import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import SceneLoadingProgress from 'app/views/components/SceneLoadingProgress'
import MainMenu from 'app/views/layouts/Header/MainMenu'
import 'font-awesome/css/font-awesome.min.css'
import { ThemeLoader } from './views/themes'
import useAppInit from 'app/views/hooks/useAppInit'
import AppContent from 'app/views/layouts/AppContent'
import AppUpdateButton from 'app/views/components/buttons/AppUpdateButton'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { env } from 'utils/env'
import Notifications from 'app/views/components/Notifications'
import { SnackbarProvider } from 'notistack'
import { getTheme } from '../utils/theme'
import useThemeConfig from 'app/views/hooks/useThemeConfig'

LicenseInfo.setLicenseKey(env('MUI_X_PRO_LICENSE_KEY'))

export default () => {
  const { themeDirection, mode, fontFamily } = useThemeConfig()
  const { initApp, initializing, initialized, appUpdateBegun } = useAppInit()
  const presetColor = __appTheme || 'theme6'
  const presetAppFontFamily = __appFontFamily || fontFamily
  const theme = getTheme({ themeDirection, mode, presetColor, fontFamily: presetAppFontFamily })

  const [themeState, setThemeState] = useState(theme)
  useEffect(() => {
    if(!initialized) {
      initApp()
    } else {
      const themeInit = getTheme({ themeDirection, mode, presetColor, fontFamily: presetAppFontFamily })
      setThemeState(themeInit)
    }
    // return cleanUpInstallPrompts
  }, [initialized])
  return (
    <ThemeLoader themeState={themeState} setThemeState={setThemeState}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <Sentry.ErrorBoundary
          fallback={<AppUpdateButton
            label={'An unexpected error occurred. Sorry, but you\'ll need to reload the app.'} />}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <>
              <Notifications />
              {initializing && (
                <SceneLoadingProgress
                  message='Initializing'
                  themeBg='primary.main'
                />
              )}
              {initialized && (
                <>
                  {
                    appUpdateBegun
                      ? <AppUpdateButton label='Update App' />
                      : (
                        <>
                          <AppContent />
                          <MainMenu />
                        </>
                      )
                  }
                </>
              )}
            </>
          </LocalizationProvider>
        </Sentry.ErrorBoundary>
      </SnackbarProvider>
    </ThemeLoader>
  )
}