import { handleActions } from 'redux-actions'
import produce from 'immer'
import _ from 'lodash'

export default handleActions({
  USER: {
    LOGIN: {
      START: state => ({
        ...state,
        loggingIn: true,
      }),
      SUCCESS: (state, { payload: user }) => produce(state, draft => {
        draft.userId = user.id
        draft.role = user.role
        draft.divIds = user.divIds
        draft.locIds = user.locIds
        draft.passes = user.passes || {}
        draft.currentPassId = user.userId
        draft.loggingIn = false
        draft.permissions = user.permissions
        draft.ethnicity = user.ethnicity
      }),
      FAILURE: state => ({
        ...state,
        loggingIn: false,
      }),
    },
    LOGOUT: {
      START: state => ({
        ...state,
        loggingOut: true,
      }),
      SUCCESS: state => ({
        ...state,
        userId: null,
        loggingOut: false,
        role: '',
        divIds: [],
        locIds: [],
        passes: {},
        editingPass: false,
      }),
    },
    UPDATE: {
      SUCCESS: (state, { payload: user }) => ({
        ...state,
        ...user,
      }),
    },
    EDIT_PASS: (state, { payload: newOrPassId }) => produce(state, draft => {
      if(newOrPassId) {
        draft.currentPassId = newOrPassId
        draft.editingPass = true
      } else {
        draft.editingPass = false
      }
    }),
    SAVE_PASS: {
      SUCCESS: (state, { payload: pass }) => produce(state, draft => {
        draft.editingPass = false
        const userId = pass?.userId
        if(!userId) return
        if(_.isPlainObject(draft.passes)) {
          draft.passes[userId] = pass
        } else {
          draft.passes = {
            [userId]: pass,
          }
        }
        draft.currentPassId = userId
      }),
    },
    GET_QR: {
      SUCCESS: (state, { payload: { userId, qrSecret } }) => produce(state, draft => {
        _.set(draft, `passes.${userId}.qrSecret`, qrSecret)
      }),
    },
    SET_LINKED_SMART: (state, { payload: { userId, linkedSMART, dataSourceType } }) => produce(state, draft => {
      draft.passes[userId].linkedSMART = linkedSMART
      if(linkedSMART) draft.passes[userId].dataSourceType = dataSourceType
      else delete draft.passes[userId].dataSourceType
    }),
    SET_USER_ETHNICITY: (state, { payload: { ethnicity } }) => produce(state, draft => {
      draft.ethnicity = ethnicity
    }),
  },
}, {
  loggingIn: false,
  loggingOut: false,
  userId: null,
  role: '',
  divIds: [],
  locIds: [],
  passes: {},
  editingPass: false,
})