import React from 'react'
import Box from '@mui/material/Box'

export default ({ children }) => (
  <Box
    display='flex'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    style={{
      width: '100%',
      height: '100%',
      flexGrow: 1,
    }}
  >
    {children}
  </Box>
)
