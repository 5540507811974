import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { supportedLngs } from 'config/const.json'

/**
 * Convert array of i18 resource paths to array objects like:
 * { value: <the original key>, label: <translated text from dictionary>}
 *
 * A dictionary is any object or nested object within an i18 resource json.
 *
 *
 * @param {string} nameSpace
 * @param {string} path - dot-notation path to dictionary
 * @param {string=|string[]=} keys - array of, or comma-separated string of, dictionary keys to translate; if missing, use all keys
 * @param {function=} formatter - function to format the translated string
 * @param {object=} subs - interpolation values
 * @param {boolean=} asValuesLabels - return array of objects with value/label keys
 * @param {boolean=} sort
 *
 * @return {object|object[]}
 */
export default ({
  nameSpace,
  path = '',
  keys,
  formatter = v => v,
  subs,
  asValuesLabels = false,
  sort = false,
}) => {
  const { t, i18n } = useTranslation(nameSpace)
  const currentLng = supportedLngs.includes(i18n.language) ? i18n.language : 'en'
  const bundle = i18n.getResourceBundle(currentLng, nameSpace)
  const keysArray = getKeysArray({
    bundle,
    path,
    keys,
  })
  let result
  if(asValuesLabels) {
    result = _.map(keysArray, key => ({
      value: key,
      label: formatter(t(getFullPath({ nameSpace, path, key }), subs)),
    }))
    if(sort) result = _.sortBy(result, 'label')
    return result
  }
  result = _.chain(keysArray)
    .invert()
    .mapValues((val, key) => formatter(t(getFullPath({ nameSpace, path, key }), subs)))
    .value()
  if(sort) return _.fromPairs(_.sortBy(_.toPairs(result), 1))
  return result
}

const getKeysArray = ({
  bundle,
  path,
  keys,
}) => {
  if(keys) return _.isString(keys) ? keys.split(',') : keys
  const obj = path ? _.get(bundle, path) : bundle
  return _.keys(obj)
}

const getFullPath = ({ nameSpace, path = '', key }) => `${nameSpace}:${_.filter([path, key]).join('.')}`
