import React from 'react'
import PropTypes from 'prop-types'
import { Image, Transformation } from 'cloudinary-react'
import _ from 'lodash'

const CdnImg = props => {
  const { src, transforms, ...rest } = props
  if (_.isEmpty(src)) return null
  const transformComponents = _.chain(transforms)
    .castArray()
    .map((cfg, i) => (
      <Transformation
        key={i}
        {...cfg}
      />
    ))
    .value()
  return (
    <Image
      cloudName='sustainhawaii'
      publicId={src}
      {...rest}
    >
      {transformComponents}
    </Image>
  )
}

CdnImg.propTypes = {
  src: PropTypes.string.isRequired,
}

export default CdnImg
