import actions from 'app/state/ducks/actions'
import { getAppInit } from 'app/state/ducks/appinit/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { checkForAppUpdate } from '../../../api/general'
import { useDebouncedCallback } from 'use-debounce'
import { useCurrentEffect } from 'use-current-effect'
import store from 'utils/local-storage'

const emptyCache = async () => {
  if (!window.caches?.keys) return
  const keys = await window.caches.keys()
  for (const key of keys) {
    const cache = await window.caches.open(key)
    const cachedRequests = await cache.keys()
    for (const cachedRequest of cachedRequests) {
      await cache.delete(cachedRequest)
    }
  }
}

const updateApp = async () => {
  // Delete constants from local storage so they are reloaded:
  store.remove('constants')
  await emptyCache()
  // Delete browser cache and hard reload:
  window.location.reload(true)
}

export default () => {
  const allSelectors = useSelector(getAppInit)
  const dispatch = useDispatch()
  const debounced = useDebouncedCallback(() => {
    checkForAppUpdate().then(updateAvailable => {
      if (updateAvailable) dispatch(actions.beginAppUpdate())
    })
  }, 5000)
  useCurrentEffect(isCurrent => {
    if (isCurrent() && !allSelectors.appUpdateBegun) debounced()
  })
  return {
    ...allSelectors,
    initApp: params => dispatch(actions.appinit.start(params)),
    handleError: params => dispatch(actions.handleError(params)),
    updateApp,
  }
}
