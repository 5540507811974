import ReactMarkdown from 'react-markdown'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import useTranslateValue from '../hooks/useTranslateValue'

const useStyles = makeStyles(({ typography }) => ({
  md: {
    '& > strong, & > p > strong, & > a > strong, & > p > a > strong': {
      fontWeight: typography.fontWeightBold,
    },
    '& > h6': {
      fontWeight: typography.fontWeightBold,
    },
    '& > h5': {
      fontWeight: typography.fontWeightBold,
    },
    '& > h4': {
      fontWeight: typography.fontWeightBold,
    },
    '& > h3': {
      fontWeight: typography.fontWeightBold,
    },
    '& > h2': {
      fontWeight: typography.fontWeightBold,
    },
    '& > h1': {
      fontWeight: typography.fontWeightBold,
    },
    '& > p': {
      fontWeight: typography.fontWeightRegular,
    },
  },
}))

export default ({
  markdown,
  className,
  style,
}) => {
  const classes = useStyles()
  const markdownT = useTranslateValue(markdown)
  return (
    <div
      className={className}
      style={style}
    >
      <ReactMarkdown
        className={classes.md}
      >
        {markdownT}
      </ReactMarkdown>
    </div>
  )
}
