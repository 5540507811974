import { createActions } from 'redux-actions'

export default createActions({
  DIALOG: {
    NOTICE: {
      ADD_NOTIFICATION: undefined,
      REMOVE_NOTIFICATION: undefined,
      ADD: undefined,
      REMOVE: undefined,
    },
    LOADING: {
      OPEN: undefined,
      CLOSE: undefined,
    },
    ALERT: {
      OPEN: undefined,
      CLOSE: undefined,
    },
    CONFIRM: {
      ASK: undefined,
      OPEN: undefined,
      YES: undefined,
      NO: undefined,
      CANCEL: undefined,
    },
    DETAILS: {
      OPEN: undefined,
      CLOSE: undefined,
    },
  },
})