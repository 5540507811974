import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import promise from 'redux-promise'
import waitForAction from 'redux-wait-for-action'
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router'
import * as ducksReducers from './ducks/reducers'
import notifyReducer from './ducks/dialog/noticeReducers'
import * as Sentry from '@sentry/react'
import { isEnv } from 'utils/env'

let reduxStore

// configure reducers:

export const history = require('history').createBrowserHistory()

const reducers = combineReducers({
  ...ducksReducers,
  notifications: notifyReducer,
  router: connectRouter(history),
})

// configure middlewares:

export const sagaMiddleware = createSagaMiddleware()

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const middlewares = [
  routerMiddleware(history),
  sagaMiddleware,
  thunk,
  promise,
  waitForAction(),
]

if (!isEnv('production')) {
  // see options at https://www.npmjs.com/package/redux-logger
  const reduxLogger = createLogger({
    predicate: (getState, action) => ![
      '@@router/LOCATION_CHANGE',
      'LAB/LOAD_ACTION_ITEMS/SUCCESS',
    ].includes(action.type),
    collapsed: true,
    timestamp: false,
    level: 'info',
  })
  middlewares.push(reduxLogger)
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// Activate tracing in Redux DevTools:
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) || compose

export const configureStore = () => {
  if (reduxStore) return reduxStore
  reduxStore = createStore(
    reducers,
    composeEnhancer(applyMiddleware(...middlewares), sentryReduxEnhancer)
  )
  return reduxStore
}