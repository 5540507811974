import { call, cancel, cancelled, fork, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import actions from 'app/state/ducks/actions'
import _ from 'lodash'
import { objectWithoutNonInfoValues } from '@sustainhawaii/object-utils'
import {
  createTest,
  createVacc,
  getActionItems,
  getClaimInformation,
  getInsuranceEligibility,
  getInsurerGroups,
  getInsurers,
  getPatient,
  getTest,
  getVacc,
  submitInsuranceClaim,
  submitSelfTestImages,
  updateTest,
  updateVacc,
} from 'api/lab'
import { getCanViewPHI, getCurrentLocId, getIsMedStaff, getReuseIntakeForm } from 'app/state/ducks/selectors'
import { recordUserScanningUser } from '../../../../api/gatekeeper'
import { getDivisionId } from 'utils/org'

const { loading, alert, notice } = actions.dialog
const {
  loadInsuranceEligibility,
  loadInsuranceClaimInfo,
  setInsuranceClaim,
  fetchInsurers,
  fetchInsurerGroups,
} = actions.lab

export function * loadPatient ({ payload: { patientId, selfTest, currentVisitType } }) {
  const { loadPatient } = actions.lab
  const divisionId = getDivisionId()
  const isMedStaff = yield select(getIsMedStaff)
  const canViewPHI = yield select(getCanViewPHI)
  try {
    const patientAndIntakeForm = yield call(getPatient, {
      patientId,
      divisionId,
      isMedStaff,
      canViewPHI,
      selfTest,
      visitType: currentVisitType,
    })
    if(_.isPlainObject(patientAndIntakeForm)) {
      yield put(loadPatient.success(patientAndIntakeForm))
    } else {
      yield cancel()
    }
  } catch (e) {
    yield put(actions.handleError({
      e,
      alertCfg: {
        title: 'Could not retrieve the patient.',
      },
      alertOnly: true,
      sentryCfg: {
        tags: {
          section: 'lab',
          func: 'getPatient',
        },
        extra: {
          patientId,
          divisionId,
        },
      },
    }))
    yield cancel()
  } finally {
    if(yield cancelled()) {
      yield put(loadPatient.failure())
      yield put(push('/lab/pending-tests'))
    }
  }
}

export function * watchLoadPatient () {
  yield takeEvery(actions.lab.loadPatient.start, loadPatient)
}

export function * saveTest ({ payload: { agreements, selfTest, ...submittedTest }, meta: { resetForm } = {} }) {
  const { saveTest } = actions.lab
  const reuseIntakeForm = yield select(getReuseIntakeForm)
  const cleanTest = objectWithoutNonInfoValues(_.omit(submittedTest, ['specimenSourceRequired']))
  const isNew = !cleanTest.id
  const canViewPHI = yield select(getCanViewPHI)
  const newResult = !!cleanTest.result
  const actionPhrase = newResult ? 'Recording Result' : (isNew ? 'Creating Test' : 'Updating Test')
  if(isNew && !selfTest) {
    yield call(recordUserScanningUser, {
      scanneeId: submittedTest.patientId,
      locId: submittedTest.location,
    })
  }
  const errorConfigs = {
    alertCfg: {
      title: `Error ${_.toLower(actionPhrase)}.`,
    },
    sentryCfg: {
      tags: {
        section: 'lab',
        func: isNew ? 'createTest' : 'updateTest',
      },
      extra: {
        testId: cleanTest.id,
      },
    },
  }
  try {
    const resData = yield call(isNew ? createTest : updateTest, {
      test: cleanTest,
      selfTest,
      agreements,
      reuseIntakeForm,
    })
    if(_.isPlainObject(resData)) {
      if(resData.test) {
        yield put(saveTest.success({
          ...resData,
          division: submittedTest.division,
          isNew,
        }))
        if(canViewPHI) {
          yield fork(loadActionItems)
        }
      } else if(resData.alertCfg) {
        yield put(alert.open(resData.alertCfg))
        yield cancel()
      } else {
        yield put(actions.handleError({
          e: new Error('no test id returned'),
          ...errorConfigs,
        }))
        yield cancel()
      }
    } else {
      yield cancel()
    }
  } catch (e) {
    yield put(actions.handleError({
      e,
      ...errorConfigs,
    }))
    yield cancel()
  } finally {
    yield put(loading.close())
    if(yield cancelled()) {
      yield put(saveTest.failure())
      if(resetForm) {
        yield call(resetForm)
      }
    }
  }
}

export function * watchSaveTest () {
  yield takeEvery(actions.lab.saveTest.start, saveTest)
}

export function * submitSelfTest (testData) {
  const {
    data,
    test,
  } = testData.payload
  const testId = test.test.id
  const { result, errorMessage } = yield call(submitSelfTestImages, data)
  if(errorMessage) {
    const errorConfigs = {
      alertCfg: {
        title: `Error in Image analysis`,
      },
      sentryCfg: {
        tags: {
          section: 'lab',
          func: 'submitSelfTest',
        },
        extra: {
          testId: testId,
        },
      },
    }
    yield put(actions.handleError({
      e: new Error(errorMessage),
      ...errorConfigs,
    }))
    return yield cancel()
  }
  let resultImg
  for (const img of data) {
    // Pick up the result image of the kit and save with test
    if(img.id === 'result') resultImg = img
  }
  const testInfo = {
    id: testId,
    result,
    flowTestImg: resultImg.data,
  }
  yield saveTest({
    payload: {
      selfTest: true,
      ...testInfo,
    },
  })
}

export function * watchSubmitSelfTest () {
  yield takeEvery(actions.lab.submitSelfTest.start, submitSelfTest)
}

export function * loadTest ({ payload: testId }) {
  const { loadTest } = actions.lab
  try {
    const testAndPatientAndIntakeForm = yield call(getTest, testId)
    if(_.isPlainObject(testAndPatientAndIntakeForm)) {
      yield put(loadTest.success(testAndPatientAndIntakeForm))
    } else {
      yield cancel()
    }
  } catch (e) {
    yield put(actions.handleError({
      e,
      alertCfg: {
        title: 'Error retrieving test.',
      },
      alertOnly: true,
      sentryCfg: {
        tags: {
          section: 'lab',
          func: 'getTest',
        },
        extra: {
          testId,
        },
      },
    }))
    yield cancel()
  } finally {
    if(yield cancelled()) {
      yield put(loadTest.failure())
      yield put(push('/lab/pending-tests'))
    }
  }
}

export function * watchLoadTest () {
  yield takeEvery(actions.lab.loadTest.start, loadTest)
}

export function * saveVacc ({ payload: submittedVacc, meta: { resetForm } = {} }) {
  const { saveVacc } = actions.lab
  const reuseIntakeForm = select(getReuseIntakeForm)
  const cleanVacc = objectWithoutNonInfoValues(submittedVacc)
  const isNew = !cleanVacc.id
  const isMedStaff = yield select(getIsMedStaff)
  const recordingReaction = !!cleanVacc.hadReaction
  const actionPhrase = recordingReaction ? 'Updating' : (isNew ? 'Creating Vacc Record' : 'Recording Vacc Completion')
  yield put(loading.open({ message: actionPhrase }))
  try {
    const resData = yield call(isNew ? createVacc : updateVacc, { vacc: cleanVacc, reuseIntakeForm })
    if(_.isPlainObject(resData)) {
      yield put(saveVacc.success({
        division: submittedVacc.division,
        ...resData,
        isNew,
      }))
      if(isMedStaff) {
        yield fork(loadActionItems)
      }
      if(isNew) {
        yield call(recordUserScanningUser, {
          scanneeId: submittedVacc.patientId,
          locId: submittedVacc.location,
        })
      }
    } else {
      yield cancel()
    }
  } catch (e) {
    yield put(actions.handleError({
      e,
      alertCfg: {
        title: `Error ${_.toLower(actionPhrase)}.`,
      },
      sentryCfg: {
        tags: {
          section: 'lab',
          func: isNew ? 'createVacc' : 'updateVacc',
        },
        extra: {
          vaccId: cleanVacc.id,
        },
      },
    }))
    yield cancel()
  } finally {
    yield put(loading.close())
    if(yield cancelled()) {
      yield put(saveVacc.failure())
      if(resetForm) {
        yield call(resetForm)
      }
    }
  }
}

export function * watchSaveVacc () {
  yield takeEvery(actions.lab.saveVacc.start, saveVacc)
}

export function * loadVacc ({ payload: vaccId }) {
  const { loadVacc } = actions.lab
  try {
    const vaccAndPatientAndIntakeForm = yield call(getVacc, vaccId)
    if(_.isPlainObject(vaccAndPatientAndIntakeForm)) {
      yield put(loadVacc.success(vaccAndPatientAndIntakeForm))
    } else {
      yield cancel()
    }
  } catch (e) {
    yield put(actions.handleError({
      e,
      alertCfg: {
        title: 'Error retrieving vaccination.',
      },
      alertOnly: true,
      sentryCfg: {
        tags: {
          section: 'lab',
          func: 'getVacc',
        },
        extra: {
          vaccId,
        },
      },
    }))
    yield cancel()
  } finally {
    if(yield cancelled()) {
      yield put(notice.add({
        message: 'Could not retrieve vaccination record.',
        urgency: 'error',
      }))
      yield put(loadVacc.failure())
      yield put(push('/lab/pending-vaccs'))
    }
  }
}

export function * watchLoadVacc () {
  yield takeEvery(actions.lab.loadVacc.start, loadVacc)
}

export function * loadActionItems ({ payload } = {}) {
  const { loadActionItems } = actions.lab
  const currentLocId = yield select(getCurrentLocId)
  const locId = payload?.locId || currentLocId
  if(!locId) return
  try {
    const data = yield call(getActionItems, { locId })
    yield put(loadActionItems.success(data))
  } catch (e) {
    yield put(loadActionItems.failure())
    yield put(actions.handleError({
      e,
      alertCfg: {
        title: 'Error loading action items.',
      },
      sentryCfg: {
        tags: {
          section: 'lab',
          func: 'getActionItems',
        },
        extra: {
          locId,
        },
      },
    }))
  }
}

export function * watchLoadActionItems () {
  yield takeEvery(actions.lab.loadActionItems.start, loadActionItems)
}

// Insurance & claims
export function * checkInsuranceEligibility ({ payload }) {
  try {
    const { data } = yield call(getInsuranceEligibility, payload)
    yield put(loadInsuranceEligibility.success({
      ...data,
      ...payload,
    }))
  } catch (e) {
  }
}

export function * watchInsuranceEligibility () {
  yield takeEvery(loadInsuranceEligibility.start, checkInsuranceEligibility)
}

export function * getInsuranceClaimInfo ({ payload }) {
  try {
    const claim = yield call(getClaimInformation, payload)
    yield  put(loadInsuranceClaimInfo.success({
      ...claim,
      ...payload,
    }))
  } catch (e) {
  }
}

export function * watchClaimInfoFetch () {
  yield takeEvery(loadInsuranceClaimInfo.start, getInsuranceClaimInfo)
}

export function * submitClaim ({ payload }) {
  try {
    const submitResponse = yield call(submitInsuranceClaim, payload)
    yield put(setInsuranceClaim.success(submitResponse))
  } catch (e) {
  }
}

export function * watchSubmitClaim () {
  yield takeEvery(setInsuranceClaim.start, submitClaim)
}

export function * populateInsurerGroups () {
  try {
    const { data } = yield call(getInsurerGroups)
    yield put(fetchInsurerGroups.success(data))
  } catch (e) {
  }
}

export function * watchGetInsurerGroups () {
  yield takeEvery(fetchInsurerGroups.start, populateInsurerGroups)
}

export function * populateInsurers ({ payload }) {
  try {
    const { data } = yield call(getInsurers, payload)
    yield put(fetchInsurers.success(data))
  } catch (e) {
  }
}

export function * watchGetInsurers () {
  yield takeEvery(fetchInsurers.start, populateInsurers)
}