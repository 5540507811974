import React from 'react'
import BackButton from 'app/views/layouts/Scene/SceneBackButton'
import SceneTitle from 'app/views/layouts/Scene/SceneTitle'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import T from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

const getPadding = (theme, compact = false) => compact ? 0 : 2

const useStyles = makeStyles(({ spacing }) => {
  const basePadding = getPadding({ spacing })
  return {
    headerNormal: {
      padding: spacing(1, 0),
    },
    headerCompact: {
      padding: spacing(0.5),
    },
    dividerNormal: {
      marginBottom: spacing(basePadding),
    },
    dividerCompact: {
      marginBottom: 0,
    },
    leftElement: {
      marginRight: spacing(2),
    },
    rightElement: {
      flexGrow: 1,
      marginRight: spacing(2),
    },
  }
})

const SceneHeader = ({ compact, header, themeBg }) => {
  const { headerCompact, headerNormal, leftElement, rightElement, dividerCompact, dividerNormal } = useStyles()
  const {
    title,
    back,
    left,
    right,
    divider = true,
  } = header
  return (
    <>
      <Toolbar
        disableGutters
        variant={compact ? 'dense' : 'regular'}
        className={compact ? headerCompact : headerNormal}
      >
        {back
          ? <BackButton back={back} />
          : left &&
            <div
              key='leftElement'
              className={leftElement}
            >
              {left}
            </div>}
        {title && <SceneTitle title={title} compact={compact} themeBg={themeBg} />}
        {
          right &&
            <div
              key='rightElement'
              className={rightElement}
            >
              {right}
            </div>
        }
      </Toolbar>
      {divider && <Divider className={compact ? dividerCompact : dividerNormal} />}
    </>
  )
}

SceneHeader.propTypes = {
  compact: T.bool,
  header: T.shape({
    title: T.shape({
      main: T.oneOfType([T.string, T.element]),
      sub: T.oneOfType([T.string, T.element]),
      center: T.bool,
    }),
    left: T.oneOfType([T.string, T.element]),
    right: T.oneOfType([T.string, T.element]),
    back: T.oneOfType([T.bool, T.object]),
    divider: T.bool,
  }).isRequired,
  themeBg: T.string,
}

export default SceneHeader
