import PropTypes from 'prop-types'

// material-ui
import {
  CssBaseline,
  StyledEngineProvider,
} from '@mui/material'
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles'
import { ThemeContext } from 'app/views/contexts/ThemeConfigContext'

// ==============================|| DEFAULT THEME - MAIN  ||============================== //


export const ThemeLoader = (props) => {
  const { themeState, setThemeState } = props
  return (
    <ThemeContext.Provider value={[themeState, setThemeState]}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(themeState)}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  )
}
