import user from './user/actions'
import appinit from './appinit/actions'
import menu from './menu/actions'
import dialog from './dialog/actions'
import lab from './lab/actions'

export default {
  ...user,
  ...appinit,
  ...menu,
  ...dialog,
  ...lab,
}
