import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { getNameSpaces, transVal } from 'utils/internationalization'
import { obj2LabelValuePairs } from 'utils/form'

/**
 * Convert array of i18 resource paths to array of translated strings.
 * Empty values are converted to empty strings so sparse arrays maintain order.
 *
 * @param {object} obj - each object value is either:
 *    a dot-notation path, or array of paths, to translation resources optionally prefixed with "<namespace>:"
 *    other strings that should not be translated
 *    empty (null, undefined, empty strings)
 * @param {function=} formatter - function to format the translated string
 * @param {object=} subs - interpolation values
 * @param {boolean} asValuesLabels - return array of objects with value/label keys
 * @return {object}
 */
export default ({
  obj,
  formatter = v => v,
  subs,
  asValuesLabels = false,
}) => {
  const { t } = useTranslation(getNameSpaces(obj))
  const mapped = _.mapValues(
    obj,
    v => _.isArray(v)
      ? _.map(v, v => formatter(transVal(v, t, subs)))
      : formatter(transVal(v, t, subs)),
  )
  if (asValuesLabels) return obj2LabelValuePairs(mapped)
  return mapped
}
