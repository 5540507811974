// noinspection HttpUrlsUsage

import * as Sentry from '@sentry/react'
import { env, isEnv } from './env'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { stringRegexTest } from './string'
import { getAppMeta, getThisAppVersion } from '../api/general'
import { browser } from 'utils/browser'
import { ignoreErrorNames, ignoreErrorsInBrowserNames } from '../config/const.json'
import _ from 'lodash'
import { inMaintenanceMode } from './site-context'

let sentryCapturingEnabled = true

const integrations = [
  new TracingIntegrations.BrowserTracing({
    tracingOrigins: [
      env('API_URL'),
      'maps.googleapis.com',
      's3-us-west-2.amazonaws.com',
    ],
  }),
]

const isAppOutOfDateError = error => error?.name === 'ChunkLoadError' ||
  stringRegexTest(error?.code, /chunk/i) ||
  stringRegexTest(error?.message, /chunk/i)

const beforeSend = (event, hint) => {
  if (!sentryCapturingEnabled) return
  const error = hint.originalException
  if (isAppOutOfDateError(error) || inMaintenanceMode()) {
    sentryCapturingEnabled = false
    return
  }
  if (ignoreErrorNames.includes(error?.name)) return
  if (ignoreErrorsInBrowserNames.includes(browser.getBrowserName(true))) return
  return event
}

const stateTransformer = state => {
  const result = { ...state }
  _.forEach(secretStatePaths, path => _.unset(result, path))
  return result
}

const secretStatePaths = [
  'auth',
  'user.passes',
  'lab.patient',
  'lab.pendingTests',
  'lab.pendingVaccs',
  'lab.testDefaults',
  'lab.testInfo',
  'lab.testsToCallAbout',
  'lab.vaccDefaults',
  'lab.vaccInfo',
]

const ignoreErrors = [
  /timeout/,
  'RangeError: Array size is not a small enough positive integer',
  'Request failed with status code 401',
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  'Can\'t find variable: ZiteReader',
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Input devices out of our control:
  'Requested device not found',
  'play() can only be initiated',
  // App updates:
  'Cannot read property \'default\' of null',
  'null is not an object (evaluating \'t.default\')',
  'Unexpected token \'<\'',
  /Loading.* chunk \d+ failed/,
  /removeChild/,
  'Duplicate Phone Number',
  'Extension context invalidated',
  'TypeError: null is not an object (evaluating \'u.play\')',
]

const denyUrls = [
// Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
]

export const initializeErrorReporting = () => {
  const appVersion = getThisAppVersion()
  Sentry.init({
    dsn: env('SENTRY_DSN'),
    release: `perseus-pwa@${appVersion}-${env('ENV')}`,
    enabled: !isEnv('development'),
    environment: env('ENV'),
    integrations,
    beforeSend,
    stateTransformer,
    normalizeDepth: 4,
    tracesSampleRate: isEnv('production') && 0.1,
    attachStacktrace: true,
    ignoreErrors,
    denyUrls,
  })
}

/**
 * Return true if cannot get response from web server and api.
 * @return {Promise<boolean>}
 */
export const isPerseusReachable = async () => {
  try {
    await getAppMeta()
    return true
  } catch (e) {
    return false
  }
}

/**
 * Given an error e, return string that is most likely the error message.
 *
 * @param {*} e
 * @param {function=} t - i18next
 * @return {string|void}
 */
export const resolveErrorMessage = (e, t) => {
  if (!e) return
  if (_.isString(e)) return e
  const unknownErrorT = t ? t('error:unknownError') : 'Unknown Error'
  if (!_.isError(e)) return unknownErrorT
  let message = e.isAxiosError
    ? _.get(e, 'response.data.message') || _.get(e, 'response.data.error')
    : e.message || e.error || e.toString()
  return message || unknownErrorT
}
