import React from 'react'
import Loader from './Loader'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import { noFormikProps } from 'utils/formik'
import { Link } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import useTranslateMapValues from '../../../hooks/useTranslateMapValues'

const useStyles = makeStyles(({ spacing }) => ({
  stacked: {
    marginTop: spacing(4),
  },
  fatButton: {
    height: spacing(8),
    borderRadius: spacing(1),
  },
  fatButtonLabel: {
    fontSize: '1.5rem',
  },
  gutterBottom: {
    marginBottom: spacing(4),
  },
}))

export default ({
  label,
  loadingLabel,
  href,
  loading = false,
  stacked = false,
  fat = false,
  gutterBottom = false,
  className,
  variant = 'outlined',
  disabled = false,
  ...buttonProps
}) => {
  const classes = useStyles()
  const trans = useTranslateMapValues({
    obj: {
      label,
      loadingLabel,
    },
  })
  const button = (
    <Button
      variant={variant}
      classes={{
        root: clsx(
          className,
          stacked && classes.stacked,
          fat && classes.fatButton,
          gutterBottom && classes.gutterBottom,
        ),
        label: fat && classes.fatButtonLabel,
      }}
      disabled={disabled}
      {...noFormikProps(buttonProps)}
    >
      {
        loading
          ? <Loader text={trans.loadingLabel || trans.label} fat={fat} />
          : trans.label
      }
    </Button>
  )
  if (href && !disabled) {
    return (
      <Link
        to={href}
        style={{
          width: buttonProps.fullWidth ? '100%' : 'auto',
          lineHeight: 1.43 // to match .MuiDataGrid-root line-height
        }}
      >
        {button}
      </Link>
    )
  } else {
    return button
  }
}
