import PropTypes from 'prop-types'
import React, { useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

// assets
import {
  FormatPainterOutlined,
  LogoutOutlined,
  SwapOutlined,
  TranslationOutlined,
  UserDeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import store from '../../../../../utils/local-storage'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getRole, getUser } from '../../../../state/ducks/user/selectors'
import useTranslateDictionary from '../../../../views/hooks/useTranslateDictionary'
import useDialogs from '../../../../views/hooks/useDialogs'
import { deleteUserAccount } from '../../../../../api/user'
import useUserActions from '../../../../views/hooks/useUserActions'

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //
const PROFILE_MENUS = {
  SWITCH_DIVISION: 0,
  LANGUAGE_SWITCH: 1,
  UI_PALETTE: 2,
  DELETE_PROFILE: 3,
  LOGOUT: 4,
  EDIT_PROFILE: 5,
}
const ProfileTab = ({
  handleLogout,
  onSwitchDivision,
  onUIPalette,
  onEditProfile,
}) => {
  const theme = useTheme()
  const role = useSelector(getRole)
  const user = useSelector(getUser)
  const [languageSwitchOpen, setLanguageSwitchOpen] = useState(false)
  const { i18n } = useTranslation()
  const { logout } = useUserActions()
  const subs = {
    appTitle,
  }
  const trans = useTranslateDictionary({
    nameSpace: 'scenes',
    path: 'personal.delete',
    subs,
  })
  const { alert, confirm, notice, loading } = useDialogs()

  const languages = store.get('constants.LANGUAGES')

  const [selectedIndex, setSelectedIndex] = useState(PROFILE_MENUS.LOGOUT)

  const deleteMyAccount = async () => {
    let okayToDelete = await confirm({
      title: trans.deleteAccountWarning,
      question: trans.deleteAccountWarningText,
    })
    let result = false
    if(okayToDelete) {
      loading({ message: trans.deleteInProgress })
      result = await deleteUserAccount(user) // trigger account deletion flow
      if(result.notice) {   // operation successful
        loading()
        notice(result.notice)
        alert({
          title: trans.accountDeletedAlert,
          message: trans.accountDeletedAlertText,
        })
        setTimeout(async () => {
          try {
            logout()
          } catch (err) {
          }
        }, 5000)
      } else if(result.error) {  // operation failed
        loading()
        notice(result.error)
      }
    } else {
      // operation cancelled
    }
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
    switch (index) {
      case PROFILE_MENUS.SWITCH_DIVISION:
        onSwitchDivision()
        break
      case PROFILE_MENUS.LANGUAGE_SWITCH:
        setLanguageSwitchOpen(!languageSwitchOpen)
        break
      case PROFILE_MENUS.UI_PALETTE:
        onUIPalette()
        break
      case PROFILE_MENUS.DELETE_PROFILE:
        // Delete user profile
        deleteMyAccount()
        break
      case PROFILE_MENUS.EDIT_PROFILE:
        // Delete user profile
        onEditProfile()
        break
      case PROFILE_MENUS.LOGOUT:
        handleLogout()
        break
    }
    if(index !== PROFILE_MENUS.LANGUAGE_SWITCH) setLanguageSwitchOpen(false)
  }
  const selectLanguage = lang => {
    i18n.changeLanguage(lang)
    setLanguageSwitchOpen(false)
  }
  const languageList = _.map(languages, (name, lang) => (
    <ListItemButton key={name} sx={{ pl: 4 }} onClick={() => selectLanguage(lang)}>
      <ListItemText primary={name} />
    </ListItemButton>))
  return (<>
      <List component='nav' sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
        <ListItemButton selected={selectedIndex === PROFILE_MENUS.SWITCH_DIVISION}
                        onClick={(event) => handleListItemClick(event, PROFILE_MENUS.SWITCH_DIVISION)}>
          <ListItemIcon>
            <SwapOutlined />
          </ListItemIcon>
          <ListItemText primary='Switch Division' />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === PROFILE_MENUS.LANGUAGE_SWITCH}
                        onClick={(event) => handleListItemClick(event, PROFILE_MENUS.LANGUAGE_SWITCH)}>
          <ListItemIcon>
            <TranslationOutlined />
          </ListItemIcon>
          <ListItemText primary='Language' />
          {languageSwitchOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={languageSwitchOpen} timeout='auto'
                  sx={{ maxHeight: '300px', overflowY: 'auto' }}
                  unmountOnExit>
          <List component='div' disablePadding>
            {languageList}
          </List>
        </Collapse>
        {role && ['Admin', 'Account Owner'].includes(role) &&
          (<ListItemButton selected={selectedIndex === PROFILE_MENUS.UI_PALETTE}
                           onClick={(event) => handleListItemClick(event, PROFILE_MENUS.UI_PALETTE)}>
            <ListItemIcon>
              <FormatPainterOutlined />
            </ListItemIcon>
            <ListItemText primary='UI Palette' />
          </ListItemButton>)}
        <ListItemButton selected={selectedIndex === PROFILE_MENUS.EDIT_PROFILE}
                        onClick={(event) => handleListItemClick(event, PROFILE_MENUS.EDIT_PROFILE)}>
          <ListItemIcon>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary='Edit Profile' />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === PROFILE_MENUS.DELETE_PROFILE}
                        onClick={(event) => handleListItemClick(event, PROFILE_MENUS.DELETE_PROFILE)}>
          <ListItemIcon>
            <UserDeleteOutlined />
          </ListItemIcon>
          <ListItemText primary='Delete Profile' />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === PROFILE_MENUS.LOGOUT}
                        onClick={(event) => handleListItemClick(event, PROFILE_MENUS.LOGOUT)}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItemButton>
      </List>
    </>

  )
}

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
}

export default ProfileTab