export const getLab = state => state.lab
export const getCurrentDivId = state => getLab(state).currentDivId
export const getCurrentLocId = state => getLab(state).currentLocId
export const getCurrentVisitType = state => getLab(state).currentVisitType
export const getReuseIntakeForm = state => getLab(state).reuseIntakeForm

// Patient
export const getPatient = state => getLab(state).patient
export const getLoadingPatient = state => getLab(state).loadingPatient
export const getIntakeForm = state => getLab(state).intakeForm
export const getIsEditingTest = state => getLab(state).isEditingTest
export const getIsEditingVacc = state => getLab(state).isEditingVacc
export const getIsInsuranceClaimExists = state => getLab(state).insuranceClaim

// Visit
export const getTestingLoadingMessage = state => {
  if(getLoadingPatient(state)) return 'Retrieving Patient'
  if(getLoadingTest(state)) return 'Retrieving Test'
  if(getLoadingVacc(state)) return 'Retrieving Vacc'
}

//lastTestDate

export const getLastTestDate = state => getLab(state).lastTestDate

// Tests
export const getTestDefaults = state => getLab(state).testDefaults
export const getTestInfo = state => getLab(state).testInfo
export const getLoadingTest = state => getLab(state).loadingTest
export const getTestsToCallAbout = state => getLab(state).testsToCallAbout || []
export const getTotalTestsToCallAbout = state => getTestsToCallAbout(state).length
export const getPendingTests = state => getLab(state).pendingTests || []
export const getTotalPendingTests = state => getPendingTests(state).length

// Vaccinations
export const getVaccDefaults = state => getLab(state).vaccDefaults
export const getVaccInfo = state => getLab(state).vaccInfo
export const getLoadingVacc = state => getLab(state).loadingVacc
export const getPendingVaccs = state => getLab(state).pendingVaccs || []
export const getTotalPendingVaccs = state => getPendingVaccs(state).length

// Insurance and claims
export const checkInsuranceEligibility = state => getLab(state).insurance
export const hasClaimSubmitted = state => getLab(state).hasClaimSubmitted
export const getClaimInfo = state => getLab(state).insuranceClaim
export const getInsurerGroups = state => getLab(state).insurerGroups
export const getInsurers = state => getLab(state).insurers
export const getFormStepStatus = state => getLab(state).formStepStatus