import {
  aSchema,
  schemas,
} from '@sustainhawaii/heal-schemas'
import _ from 'lodash'
import { str2bool } from '@sustainhawaii/object-utils'
import {
  getLuxonDateTime,
  getNow,
  getPrettyDate,
} from 'utils/temporal'
import { getOrgDivisionProperty } from 'utils/org'
import { isFileInputVal } from 'utils/file'

const errorsEn = {
  'errorMsg': 'error message',
  'formSubmitProblem': 'problem submitting the form',
  'initFail': 'Could not initialize the app',
  'notFound': 'Not found',
  'required': 'Required',
  'selectYesNo': 'Select "Yes" or "No"',
  'selectOne': 'Select one',
  'selectAtLeastOne': 'Select at least one',
  'tooShort': 'Too short',
  'tooLong': 'Too long',
  'tooFew': 'Too few',
  'tooMany': 'Too many',
  'noFutureDate': 'Today or before',
  'invalidPhone': 'Please enter a valid phone number',
  'dateStartAfterEnd': 'Must be before end date',
  'dateEndBeforeStart': 'Must be after start date',
  'phoneCountryCodeRequired': 'Country Code "{{code}}" required after "+"',
  'companyNameCharsAllowed': 'Only letters, numbers and ,.?!-',
  'tryAgain': 'Please try again',
  'pleaseSpecify': 'Please Specify',
  'enterFirstName': 'Enter your first name',
  'enterLastName': 'Enter your last name',
}

const { string, date, numberNaN2Undefined, array, mixed, object } = schemas

const arrayOfStringOrEmpty = array().ensure().compact().of(string())

/**
 * Return set of schemas using ii18n t function with name spaces: common, glossary, error
 *
 * @param {function} t
 * @return {object}
 */
export default (t = () => false) => {

  const errMsg = _.mapValues(errorsEn, (v, k) => t(`error:${k}`))

  const mongoObjIdSchema = string().length(24)

  const neo4jIdSchema = string().min(24)    // to accommodate both mongoId schema & neo4j uuid schema

  const personFullNameSchema = aSchema({
    first: string().required(errMsg.enterFirstName)
      .min(1, errMsg.tooShort)
      .max(50, errMsg.tooLong),
    middleInitial: string().max(1).notRequired(),
    last: string().required(errMsg.enterLastName)
      .min(1, errMsg.tooShort)
      .max(50, errMsg.tooLong),
    suffix: string().max(20, errMsg.tooLong).notRequired(),
  })

  const genderRegEx = new RegExp(_.chain(getOrgDivisionProperty({ key: 'genders' })).map('value').join('|').value())

  const genderSchema = string().ensure().matches(genderRegEx, errMsg.selectOne)

  const birthDateSchema = string().nullable()
  const vitalsSchema = aSchema({
    gender: genderSchema,
    birthDate: birthDateSchema.required(errMsg.required),
  }).required(errMsg.required)

  const physicalAddressSchema = aSchema({
    street1: string().min(3, errMsg.tooShort).max(50, errMsg.tooLong).required(errMsg.required),
    street2: string().max(50),
    street3: string().max(50),
    city: string().min(1).max(50).required(errMsg.required),
    state: string().min(2).max(50).required(errMsg.required),
    zip: string().min(1).max(20).required(errMsg.required),
    country: string().min(2).max(2).required(errMsg.required),
  })

  return {

    errMsg,

    pass: aSchema({
      userId: neo4jIdSchema,
      name: personFullNameSchema.required(),
      email: schemas.string().required(),
      ethnicity: schemas.string().required(),
      addresses: aSchema({
        home: physicalAddressSchema,
      }).required(errMsg.required),
      vitals: aSchema({
        gender: genderSchema,
        birthDate: birthDateSchema.required(errMsg.required),
      }).required(errMsg.required),
      qrSecret: schemas.string().notRequired(),
      govtIdExists: schemas.boolean(),
      selfiePhoto: schemas.mixed().when('govtIdExists', {
        is: true,
        then: mixed().required(),
        otherwise: mixed().optional(),
      }),
      govtIdCardFront: mixed().when('govtIdExists', {
        is: true,
        then: mixed().optional(),
        otherwise: mixed().optional(),
      }),
    }),

    arrayOfStringOrEmpty,

    notRequiredSchema: mixed().notRequired(),

    neo4jIdSchema,

    namePartSchema: string().min(1, errMsg.tooShort).max(50, errMsg.tooLong).required(errMsg.required),

    physicalAddressSchema,

    companyNameSchema: string()
      .matches(/[\w\d\s!?,.-]/, errMsg.companyNameCharsAllowed)
      .min(1, errMsg.tooShort)
      .max(50, errMsg.tooLong),

    nonFutureDate: date().max(getNow(), errMsg.noFutureDate).typeError(''),

    birthDateSchema,

    genderSchema,
    vitalsSchema,

    ssnSchema: string().matches(/^\d{3}-?\d{2}-?\d{4}$/, '"#########" or "###-##-####"').default(''),

    phoneSchema: string().matches(/(\D*\d){8}/, errMsg.invalidPhone).required(errMsg.required),

    yesNoSchema: (message = errMsg.selectYesNo) => string().oneOf(['true', 'false']).required(message),

    atLeastOneCheckedSchema: arrayOfStringOrEmpty.min(1, errMsg.selectAtLeastOne).required(errMsg.selectAtLeastOne),

    atLeastOneCheckedOrNoneSchema: array().ensure().compact().of(string()).min(1, `${errMsg.selectAtLeastOne}, or ${errMsg.noneAbove}`),

    singleChoiceRequiredSchema: choices => string().oneOf(choices, errMsg.selectOne).required(errMsg.selectOne),

    fileInputSchema: object().test('is-file-input-val', errMsg.required, isFileInputVal),

    fileInputSchemaOptional: mixed().test(o => _.isEmpty(o) ? true : isFileInputVal(o)),

    personFullNameSchema,

    personalAddressesSchema: aSchema({
      home: physicalAddressSchema,
      visiting: object().when('isVisiting', isVisiting => isVisiting || str2bool(isVisiting, true) ? physicalAddressSchema : object().optional()),
    }),

    vitalsSchemas: {
      bodyTemp: numberNaN2Undefined.min(95, '>= 95').max(105, '<= 105'),
      o2: numberNaN2Undefined.min(75, '>= 75').max(100, '<= 100'),
      pulse: numberNaN2Undefined.min(30, '>= 30').max(150, '<= 150'),
    },

    getDateRangeSchema: ({
      startDateName,
      endDateName,
      maxDays,
    }) => {
      const noFutureMessage = errMsg.noFutureDate
      return schemas.object().shape({
        [startDateName]: schemas.date().when(endDateName, (endDate, schema) => {
          const nowISO = getNow().toISO()
          const endISO = endDate && getLuxonDateTime(endDate).endOf('day').toISO()
          if(!endISO) return schema.max(nowISO, noFutureMessage).nullable()
          const minISO = maxDays
            ? getLuxonDateTime(endDate).minus({ days: maxDays }).toISO()
            : '2021-01-01'
          const minPrettyDate = getPrettyDate({ dateTime: minISO })
          return schema
            .min(minISO, maxDays ? `${maxDays} days max` : `${minPrettyDate} or later`)
            .max(endISO || nowISO, endISO < nowISO ? errMsg.dateStartAfterEnd : noFutureMessage).typeError('')
        }),
        [endDateName]: schemas.date().when(startDateName, (startDate, schema) => {
          const startISO = startDate
            ? getLuxonDateTime(startDate).startOf('day').toISO()
            : '2021-01-01'
          return schema.min(startISO, errMsg.dateEndBeforeStart).max(getNow().endOf('day').toISO(), noFutureMessage).typeError('')
        }),
      }, [startDateName, endDateName])
    },
  }
}