import { handleActions } from 'redux-actions'
import produce from 'immer'
import _ from 'lodash'

export default handleActions({
  LAB: {
    SET_CURRENT_VISIT_TYPE: (state, { payload: visitType }) => produce(state, draft => {
      draft.currentVisitType = visitType
    }),
    LOAD_PATIENT: {
      START: state => ({
        ...state,
        loadingPatient: true,
        patient: null,
        intakeForm: null,
        reuseIntakeForm: false,
        testInfo: null,
        lastTestDate: null,
        vaccInfo: null,
      }),
      SUCCESS: (state, {
        payload: {
          patient,
          lastTestDate = null,
          intakeForm = null,
          pendingTestInfo = null,
          pendingVaccInfo = null,
        },
      }) => ({
        ...state,
        loadingPatient: false,
        patient,
        lastTestDate: lastTestDate,
        intakeForm,
        testInfo: pendingTestInfo,
        vaccInfo: pendingVaccInfo,
      }),
      FAILURE: state => ({
        ...state,
        loadingPatient: false,
        patient: null,
        lastTestDate: null,
        intakeForm: null,
        testInfo: null,
        vaccInfo: null,
      }),
    },
    SAVE_TEST: {
      SUCCESS: (state, { payload }) => produce(state, draft => {
        const { isNew, division, insuranceClaim, ...testInfo } = payload
        draft.testInfo = testInfo
        draft.reuseIntakeForm = false
        if(isNew) {
          draft.insuranceClaim = null
          draft.testDefaults = {
            division,
            location: testInfo.location.id,
            phase: testInfo.test?.phase,
            type: testInfo.test?.type,
            labAccountId: testInfo.test?.labAccountId,
            specimenSource: testInfo.test?.specimenSource,
            specimenSourceRequired: false,
          }
          draft.currentDivId = testInfo.divisionId
          draft.currentLocId = testInfo.location?.id
          draft.currentVisitType = 'test'
        } else {
          draft.insuranceClaim = (insuranceClaim ? true : false)
          draft.intakeForm.paid = !!testInfo.paid
          draft.isEditingTest = false
        }
      }),
      FAILURE: state => produce(state, draft => {
        draft.testInfo = null
      }),
    },
    SUBMIT_SELF_TEST: state => state,
    NEW_TEST: state => ({
      ...state,
      testInfo: null,
      reuseIntakeForm: true,
    }),
    EDIT_TEST: state => ({
      ...state,
      testInfo: state.testInfo,
      isEditingTest: true,
      reuseIntakeForm: true,
    }),
    CANCEL_EDIT: state => ({
      ...state,
      isEditingTest: false,
    }),
    LOAD_TEST: {
      START: state => ({
        ...state,
        loadingTest: true,
        patient: null,
        lastTestDate: null,
        intakeForm: null,
        testInfo: null,
        insuranceClaim: null,
      }),
      SUCCESS: (state, { payload: { patient, lastTestDate, intakeForm, testInfo, insuranceClaim } }) => ({
        ...state,
        loadingTest: false,
        patient,
        lastTestDate,
        intakeForm,
        testInfo,
        insuranceClaim,
        currentVisitType: 'test',
      }),
      FAILURE: state => ({
        ...state,
        loadingTest: false,
        patient: null,
        lastTestDate: null,
        intakeForm: null,
        testInfo: null,
        insuranceClaim: null,
      }),
    },
    SAVE_VACC: {
      SUCCESS: (state, { payload }) => produce(state, draft => {
        const { isNew, division, ...vaccInfo } = payload
        draft.vaccInfo = vaccInfo
        if(isNew) {
          draft.insuranceClaim = null
          draft.vaccDefaults = {
            division,
            type: vaccInfo.vacc?.type,
            location: vaccInfo.location.id,
            lotNumber: vaccInfo.vacc?.lotNumber,
          }
          draft.currentDivId = vaccInfo.divisionId
          draft.currentLocId = vaccInfo.location?.id
          draft.currentVisitType = 'vacc'
        } else {
          draft.isEditingVacc = false
        }
      }),
      FAILURE: state => produce(state, draft => {
        draft.vaccInfo = null
      }),
    },
    NEW_VACC: state => ({
      ...state,
      vaccInfo: null,
      reuseIntakeForm: true,
    }),
    EDIT_VACC: state => ({
      ...state,
      vaccInfo: state.vaccInfo,
      isEditingVacc: true,
      reuseIntakeForm: true,
    }),
    CANCEL_EDIT_VACC: state => ({
      ...state,
      isEditingVacc: false,
    }),
    LOAD_VACC: {
      START: state => ({
        ...state,
        loadingVacc: true,
        patient: null,
        intakeForm: null,
        vaccInfo: null,
        insuranceClaim: null,
      }),
      SUCCESS: (state, { payload: { patient, lastTestDate, intakeForm, vaccInfo, insuranceClaim } }) => ({
        ...state,
        loadingVacc: false,
        patient,
        lastTestDate,
        intakeForm,
        vaccInfo,
        insuranceClaim,
        currentVisitType: 'vacc',
      }),
      FAILURE: state => ({
        ...state,
        loadingVacc: false,
        patient: null,
        lastTestDate: null,
        intakeForm: null,
        vaccInfo: null,
        insuranceClaim: null,
      }),
    },
    LOAD_ACTION_ITEMS: {
      START: (state, { payload }) => produce(state, draft => {
        if(payload?.locId) {
          draft.currentDivId = payload.divId
          draft.currentLocId = payload.locId
          if(!draft.testDefaults?.location) draft.testDefaults.location = payload.locId
          if(!draft.vaccDefaults?.location) draft.vaccDefaults.location = payload.locId
        }
      }),
      SUCCESS: (state, { payload: actionItems }) => ({
        ...state,
        ...actionItems, // pendingTests, testsToCallAbout, pendingVaccs
      }),
    },
    LOAD_INSURANCE_ELIGIBILITY: {
      START: (state) => ({
        ...state,
        insurance: null,
      }),
      SUCCESS: (state, { payload }) => ({
        ...state,
        insurance: {
          ...payload,
        },
      }),
    },
    SET_INSURANCE_CLAIM: {
      START: (state) => ({
        ...state,
        hasClaimSubmitted: false,
      }),
      SUCCESS: (state) => {
        return ({
          ...state,
          hasClaimSubmitted: true,
        })
      },
    },
    FETCH_INSURER_GROUPS: {
      START: (state) => state,
      SUCCESS: (state, { payload }) => {
        const insurerGroups = _.reduce(payload, (obj, group) => {
          obj[group] = group
          return obj
        }, {})
        return ({
          ...state,
          insurerGroups,
        })
      },
    },
    FETCH_INSURERS: {
      START: (state) => state,
      SUCCESS: (state, { payload }) => {
        const insurers = _.reduce(payload, (obj, insurer) => {
          obj[insurer] = insurer
          return obj
        }, {})
        return ({
          ...state,
          insurers,
        })
      },
    },
    UPDATE_STEP_STATUS: (state, { payload }) => {
      return ({
        ...state,
        formStepStatus: payload,
      })
    },
  },
}, {
  currentDivId: '',
  currentLocId: '',
  currentVisitType: 'test',
  loadingPatient: false,
  loadingTest: false,
  loadingVacc: false,
  patient: null,
  intakeForm: null,
  reuseIntakeForm: false,
  testInfo: null,
  lastTestDate: null,
  vaccInfo: null,
  insurerGroups: {},
  insurers: {},
  formStepStatus: null,
  testDefaults: {
    division: '',
    location: '',
    phase: 'baseline',
    type: '',
    labAccountId: '',
    specimenSource: '',
    specimenSourceRequired: false,
  },
  testsToCallAbout: [],
  pendingTests: [],
  vaccDefaults: {
    division: '',
    location: '',
    type: '',
    lotNumber: '',
    doseNumber: '',
    bodySite: '',
    route: '',
    color: '',
  },
  pendingVaccs: [],
})