import { createActions } from 'redux-actions'
import phases from '../actionPhases'

export default createActions({
  USER: {
    LOGIN: phases.formik,
    LOGOUT: phases.formik,
    UPDATE: phases.formik,
    EDIT_PASS: undefined,
    SAVE_PASS: phases.formik,
    GET_QR: phases.formik,
    SET_LINKED_SMART: undefined,
    SET_USER_ETHNICITY: undefined,
  },
})