import { personalDashBoardPath } from '../../config/const'

export default [
  {
    path: ['/', '/div/:division?', '/staff*'],
    component: () => import(/* webpackChunkName: "home" */ 'app/views/scenes/Home'),
    exact: true,
    enabled: true,
  },
  {
    path: personalDashBoardPath,
    component: () => import(/* webpackChunkName: "personal-dashboard" */ 'app/views/scenes/personal/Dashboard'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/passes',
    component: () => import(/* webpackChunkName: "personal-passes" */ 'app/views/scenes/personal/Passes'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/my-health',
    component: () => import(/* webpackChunkName: "personal-health" */ 'app/views/scenes/personal/Health'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/proctoredtest',
    component: () => import(/* webpackChunkName: "personal-webinar" */ 'app/views/scenes/personal/ProctoredTest'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/gate-keeper',
    component: () => import(/* webpackChunkName: "gatekeeper" */ 'app/views/scenes/GateKeeper'),
    exact: true,
    userRoles: ['Account Owner', 'Admin', 'HCP', 'Lab Tech', 'Gatekeeper', 'Analyst R', 'Analyst X'],
    features: ['Gatekeeper', 'Administration of Organization'],
    enabled: true,
  },
  {
    path: '/Scheduling/visit-type',
    component: () => import(/* webpackChunkName: "scheduling-visit-type-selection" */ 'app/views/scenes/personal/Scheduling/VisitTypeSelection'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/Scheduling/select-scheduling-type',
    component: () => import(/* webpackChunkName: "scheduling-visit-location-selection" */ 'app/views/scenes/personal/Scheduling/SchedulingTypeSelection'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/Scheduling/select-location',
    component: () => import(/* webpackChunkName: "scheduling-visit-location-selection" */ 'app/views/scenes/personal/Scheduling/LocationSelection'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/Scheduling/select-user',
    component: () => import(/* webpackChunkName: "scheduling-visit-user-selection" */ 'app/views/scenes/personal/Scheduling/UserSelection'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/Scheduling/select-slot',
    component: () => import(/* webpackChunkName: "scheduling-visit-calendly" */'app/views/scenes/personal/Scheduling/Calendly'),
    authRequired: true,
    enabled: true,
  },
  {
    path: '/Scheduling/paid-pcr',
    component: () => import(/* webpackChunkName: "scheduling-visit-paid-pcr" */ 'app/views/scenes/personal/Scheduling/PaidPCR'),
    userRoles: ['Account Owner', 'Admin', 'HCP', 'Lab Tech'],
    features: ['Administration of Organization', 'Lab Functions'],
    enabled: true,
  },
  {
    path: '/personal/intake-forms',
    component: () => import(/* webpackChunkName: "personal-intake-forms" */ 'app/views/scenes/personal/IntakeForms'),
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/dri',
    component: () => import(/* webpackChunkName: "dri" */ 'app/views/scenes/personal/DRI'),
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/food-compass',
    component: () => import(/* webpackChunkName: "food-explorer" */ 'app/views/scenes/personal/FoodExplorer'),
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/diabetes',
    component: () => import(/* webpackChunkName: "diabetes" */ 'app/views/scenes/personal/Diabetes'),
    authRequired: true,
    enabled: true,
  },

  {
    path: '/personal/food-preferences',
    component: () => import('app/views/scenes/personal/FoodPreferences'),
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/scan-upca',
    component: () => import( 'app/views/scenes/personal/ScanUpca'),
    authRequired: true,
    enabled: true,
  },
  {
    path: '/personal/food-diary',
    component: () => import( 'app/views/scenes/personal/FoodDiary'),
    authRequired: true,
    enabled: true,
  },
  /*
 {
    path: '/personal/medical-chat',
    component: () => import(/!* webpackChunkName: "personal-intake-forms" *!/ 'app/views/scenes/personal/MedicalChat'),
    authRequired: true,
    enabled: true,
  },*/
  {
    path: '/personal/intake-form',
    component: () => import(/* webpackChunkName: "personal-intake-form" */ 'app/views/scenes/personal/IntakeForms/Form'),
    authRequired: true,
    enabled: true,
  },
  {
    path: '/i-c/:formId',
    component: () => import(/* webpackChunkName: "personal-intake-form" */ 'app/views/scenes/personal/IntakeForms/Form'),
    enabled: true,
    forGuardian: true,
  },
  {
    path: '/intake-cosign-done',
    component: () => import(/* webpackChunkName: "personal-intake-form" */ 'app/views/scenes/personal/IntakeForms/CosignThanks'),
    enabled: true,
  },
  {
    path: '/personal/symptoms',
    component: () => import(/* webpackChunkName: "symptom-survey" */ 'app/views/scenes/personal/SymptomSurvey'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/payment/success',
    component: () => import(/* webpackChunkName: "payment-success" */ 'app/views/scenes/personal/Payment/success'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/lab/pending-tests',
    component: () => import(/* webpackChunkName: "lab-pending-tests" */ 'app/views/scenes/lab/PendingTests'),
    exact: true,
    userRoles: ['HCP', 'Account Owner', 'Admin'],
    features: ['Administration of Organization', 'Lab Functions'],
    enabled: true,
  },
  {
    path: '/lab/pending-vaccs',
    component: () => import(/* webpackChunkName: "lab-pending-vaccs" */ 'app/views/scenes/lab/PendingVaccs'),
    exact: true,
    userRoles: ['HCP', 'Account Owner', 'Admin'],
    features: ['Administration of Organization', 'Lab Functions'],
    enabled: false,
  },
  {
    path: '/lab/calls',
    component: () => import(/* webpackChunkName: "account-owner-tests-to-call-about" */ 'app/views/scenes/lab/TestsToCallAbout'),
    exact: true,
    userRoles: ['HCP', 'Account Owner', 'Admin', 'Analyst X'],
    features: ['Administration of Organization', 'Lab Functions'],
    enabled: true,
  },
  {
    path: '/lab/create-patient',
    component: () => import(/* webpackChunkName: "account-owner-tests-to-call-about" */ 'app/views/scenes/lab/CreatePatient'),
    exact: true,
    userRoles: ['HCP', 'Account Owner', 'Admin'],
    features: ['Administration of Organization', 'Lab Functions'],
    enabled: true,
  },
  {
    path: '/lab/printing-test',
    component: () => import(/* webpackChunkName: "lab-printing-test" */ 'app/views/scenes/lab/PrintTestLabel'),
    exact: true,
    userRoles: ['Account Owner', 'Admin', 'HCP', 'Lab Tech'],
    features: ['Administration of Organization', 'Lab Functions'],
    enabled: true,
  },
  {
    path: '/lab/visit',
    component: () => import(/* webpackChunkName: "lab-visit" */ 'app/views/scenes/lab/Visit'),
    enabled: true,
  },
  {
    path: ['/personal/my-status', '/myact/div/:division'],
    component: () => import(/* webpackChunkName: "personal-my-status" */ 'app/views/scenes/personal/Status'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    path: '/privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ 'app/views/scenes/site-info-pages/PrivacyPolicy.js'),
    exact: true,
    enabled: true,
  },
  {
    path: '/cookie-policy',
    component: () => import(/* webpackChunkName: "cookie-policy" */ 'app/views/scenes/site-info-pages/CookiePolicy.js'),
    exact: true,
    enabled: true,
  },
  {
    path: '/supported-browsers',
    component: () => import(/* webpackChunkName: "supported-browsers" */ 'app/views/scenes/site-info-pages/SupportedBrowsers.js'),
    exact: true,
    enabled: true,
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ 'app/views/scenes/admin'),
    userRoles: ['Analyst R', 'Analyst X', 'HCP', 'Account Owner', 'Admin', 'Sales Manager', 'Prospect AO'],
    features: ['Administration of Organization', 'Lab Functions', 'CRM', 'Gatekeeper',
      'Inventory', 'Org Management', 'Reporting', 'PCRM Admin', 'FC Admin'],
    enabled: true,
  },
  {
    path: '/personal/schedule',
    component: () => import(/* webpackChunkName: "personal-passes" */ 'app/views/scenes/personal/Scheduler'),
    exact: true,
    authRequired: true,
    enabled: true,
  },
  {
    component: () => import(/* webpackChunkName: "not-found" */ 'app/views/scenes/NotFound'),
    enabled: true,
  },
]