// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AppBar(theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        }
      }
    }
  }
}
