// project import
import Navigation from './Navigation'
import { Box } from '@mui/material'
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
  <Box sx={{
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
  }}>
    <Box
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Navigation />
    </Box>
  </Box>

)

export default DrawerContent