import React from 'react'
import {
  put,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects'
import actions from 'app/state/ducks/actions'
import { push } from 'connected-react-router'

const NOTIFICATION_TYPE_ERROR = 'ERROR',
  NOTIFICATION_TYPE_INFO = 'INFO',
  NOTIFICATION_TYPE_SUCCESS = 'SUCCESS',
  NOTIFICATION_TYPE_WARNING = 'WARNING'
const notificationTypes = {
  success: {
    actionType: NOTIFICATION_TYPE_SUCCESS,
    icon: 'check', // appended to font-awesome class string 'fa fa-'
  },
  info: {
    actionType: NOTIFICATION_TYPE_INFO,
    icon: 'info',
  },
  warning: {
    actionType: NOTIFICATION_TYPE_WARNING,
    icon: 'exclamation-triangle',
  },
  error: {
    actionType: NOTIFICATION_TYPE_ERROR,
    icon: 'exclamation',
  },
}

/**
 * Open confirmation dialog with configuration.
 *
 * @param {object} payload
 * @param {string} payload.title
 * @param {string} payload.question
 * @param {string=} payload.yesButtonLabel
 * @param {string=} payload.noButtonLabel
 * @param {boolean=} payload.forceYes - if true, hide the No button
 * @param {boolean=} payload.cancelable - if true, display Cancel button
 * @param {func=} payload.callback - optional callback called with boolean result
 * @return {boolean|void}
 */
export function * ask ({ payload: dialogConfig }) {
  const { confirm } = actions.dialog
  yield put(confirm.open(dialogConfig))
  const { yes, cancel } = yield race({
    yes: take(confirm.yes),
    no: take(confirm.no),
    cancel: take(confirm.cancel),
  })
  if(!!cancel) return
  return !!yes
}

export function * watchAsk () {
  yield takeEvery(actions.dialog.confirm.ask, ask)
}

function createNotificationAction (config) {
  const notification = { id: parseInt(Math.random().toString().split('.')[1], 10), ...config }
  const { notice } = actions.dialog
  return notice.addNotification(notification)
}

function removeNotificationAction (id) {
  const { notice } = actions.dialog
  return notice.removeNotification(id)
}

/**
 * Display notification.
 *
 * @param {object} config
 * @param {object} config.payload
 * @param {string=} config.payload.title - short title
 * @param {string} config.payload.message - body of message
 * @param {number} config.payload.duration - milliseconds until notification is dismissed, or 0 for never; defaults to 5000
 * @param {string=} config.payload.urgency - 'info' (default), 'success', 'warn', 'error'
 * @param {object[]=} config.payload.actions - array of action objects representing button(s) to display on alert or notice; alerts only display the first button; notices display both, but first one must be the affirmative button and second must be the negative button.
 * @param {string=} config.payload.actions[].label - label for alert and notice action buttons
 * @param {string=} config.payload.actions[].path - path of PWA route to navigate to when button clicked
 * @return {string} - notification id
 */
function * addNotice (
  {
    payload: {
      title,
      message,
      urgency = 'info',
      actions,
      duration = 10000,
    },
  },
) {
  const { actionType, icon } = notificationTypes[urgency] || notificationTypes.info
  const config = {
    message: `${title ? title + ': ' : ''}${message}`,
    type: actionType,
    icon: <i className={`fa fa-${icon}`} />,
    canDismiss: true,
    duration,
  }
  // action buttons:
  if(Array.isArray(actions)) {
    const [acceptBtn, denyBtn] = actions.map(action => ({
      title: action.label,
      handler: action.path ? () => push(action.path) : null,
    }))
    config.acceptBtn = acceptBtn
    config.denyBtn = denyBtn
  }
  const action = createNotificationAction(config)
  yield put(action)
}

function * removeNotice ({
  payload: { id },
}) {
  const action = removeNotificationAction(id)
  yield put(action)
}

export function * watchNotice () {
  yield takeEvery(actions.dialog.notice.add, addNotice)
}

export function * removeNotification () {
  yield takeEvery(actions.dialog.notice.remove, removeNotice)
}