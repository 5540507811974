import React from 'react'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'
import CdnImg from '../components/CdnImg'
import Typography from '@mui/material/Typography'
import useTranslateDictionary from '../hooks/useTranslateDictionary'

const useStyles = makeStyles(({ palette }) => ({
  text: {
    color: palette.common.white,
  },
}))

const TextBlock = ({
  text,
  variant,
}) => {
  const classes = useStyles()
  return (
    <Typography
      component='div'
      variant={variant}
      align='center'
      gutterBottom
      className={classes.text}
    >
      {text}
    </Typography>
  )
}

export default () => {
  const { title, message } = useTranslateDictionary({
    nameSpace: 'scenes',
    path: 'maintenance',
  })
  const { palette } = useTheme()
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100%'
      bgcolor={palette.primary.main}
    >
      <Box
        mb={6}
      >
        <CdnImg
          src='perseus/logo/PERSEUS-icon-acronym-words-white'
          transforms={[
            {
              height: 300,
              crop: 'limit',
            },
          ]}
        />
      </Box>
      <TextBlock text={title} variant='h5' />
      <TextBlock text={message} variant='h6' />
    </Box>
  )
}
