import React from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import makeStyles from '@mui/styles/makeStyles'
import useMainMenu from 'app/views/hooks/useMainMenu'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(({ spacing, palette }) => ({
  itemRoot: {
    height: spacing(8),
    fontSize: '1.25em',
    color: palette.primary.contrastText,
    borderBottom: `1px solid ${palette.primary.light}`,
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  divider: {
    height: 2,
  },
}))

export default () => {
  const { items, isOpen, onToggle } = useMainMenu()
  const { push } = useHistory()
  const classes = useStyles()
  return (
    <Drawer
      open={isOpen}
      onClose={onToggle}
      anchor='left'
    >
      <List>
        {items.map((item, i) => item?.divider
          ? (
            <Divider
              key={i}
              className={classes.divider}
            />
          )
          : (
            <ListItem
              key={i}
              button
              onClick={() => {
                push(`/${item.path}`)
                onToggle({ menuItemName: item.name })
              }}
              classes={{
                root: classes.itemRoot,
              }}
            >
              {item.name}
            </ListItem>
          ))}
      </List>
    </Drawer>
  )
}
