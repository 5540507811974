import axios from 'axios'
import { env } from 'utils/env'
import { downloadFile, getMimeTypeFromFileName, hasImageFileExt, previewFile, previewUrl } from 'utils/file'
import store from 'utils/local-storage'
import _ from 'lodash'
import { mapStringValsToBooleans, mapValuesDeep, mapValuesDeepAsync } from '@sustainhawaii/object-utils'
import async from 'async'
import { client as apiClient } from 'api/client'
import { getImageDataUri } from 'utils/image'
import { addAxiosRetry } from './http'

let axiosInstance
let downloadInstance
const client = () => {
  if(axiosInstance) return axiosInstance
  axiosInstance = axios.create({
    timeout: 30000,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
  addAxiosRetry({ instance: axiosInstance })
  return axiosInstance
}

const downloadClient = () => {
  if(downloadInstance) return downloadInstance
  downloadInstance = axios.create({
    timeout: 30000000,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
  addAxiosRetry({ instance: downloadInstance })
  return downloadInstance
}

/**
 * Recursively map any values that are AWS image URLs to base 64 image data URIs.
 *
 * @param {object} obj
 * @param {boolean} metaOnly - if false, entire file is downloaded and dataUri is set
 * @return {object}
 */
export const mapAwsS3UrlsToFileInputVals = ({
  obj,
  metaOnly = false,
}) => mapValuesDeepAsync({
  obj,
  iter: async ({ val }) => {
    if(!isAwsBucketUrl(val)) return val
    const {
      blob,
      metaData,
    } = await getBlobAndMeta({
      val,
      metaOnly,
    })
    const {
      name,
      type,
      inline,
      ...otherMetaData
    } = getAwsS3MetaDataDeStringified(metaData)
    const mimeType = type || getMimeTypeFromFileName(val)
    const result = {
      isUploadedFile: true,
      fileName: name,
      mimeType,
      inline,
      url: val,
      ...otherMetaData,
    }
    // eslint-disable-next-line no-undef
    if(!metaOnly && inline) {
      result.dataUri = getImageDataUri({ data: blob, mimeType })
    }
    return result
  },
})

const getBlobAndMeta = ({
  val,
  metaOnly,
}) => async.parallel({
  blob: cb => metaOnly
    ? Promise.resolve().then(() => cb(null, null))
    : getFileFromAwsS3({ url: val }).then(result => cb(null, result?.data)),
  metaData: cb => apiClient().post('/files/get-meta', {
    url: val,
    s3CustomerKey: env('AWS_CUSTOMER_KEY'),
  }).then(result => cb(null, result?.data)),
})

export const removeIntakeFormExportEntry = ({
  id,
}) => {
  apiClient().post('/files/delete-intake-form-export-zip', {
    url,
  })
}

/**
 * Convert strings to booleans and numbers:
 *
 * @param metaData
 */
const getAwsS3MetaDataDeStringified = metaData => {
  const boolified = mapStringValsToBooleans(metaData)
  return mapValuesDeep({
    obj: boolified,
    iter: ({ val }) => {
      if(_.isBoolean(val)) return val
      const num = _.toNumber(val)
      return _.isFinite(num) ? num : val
    },
  })
}

export const getFileFromAwsS3 = async ({
  url,
  responseType,
  bigData,
}) => {
  let getClient = client()
  if(bigData) getClient = downloadClient()
  const res = await getClient.get(url, {
    headers: {
      'x-amz-server-side-encryption-customer-algorithm': 'AES256',
      'x-amz-server-side-encryption-customer-key': env('AWS_CUSTOMER_KEY_B64'),
      'x-amz-server-side-encryption-customer-key-MD5': env('AWS_CUSTOMER_KEY_MD5_B64'),
    },
    responseType,
  })
  return res
}

export const getBase64ImageFromAWS = async ({
  url,
  mimeType = 'image/jpeg',
  metaOnly = false,
}) => {
  try {
    const { data } = await getFileFromAwsS3({
      url,
      metaOnly,
    })
    return getImageDataUri({ data, mimeType })
  } catch (e) {
    return ''
  }
}

export const downloadFileFromAwsS3 = async ({
  url,
  fileName,
  mimeType,
  encoding,
  open = false,
}) => {
  if(encoding === 'base64') {
    const dataUri = await getBase64ImageFromAWS({ url, mimeType })
    if(open) return previewUrl(dataUri)
    const { triggerBase64Download } = require('react-base64-downloader')
    triggerBase64Download(dataUri, fileName)
  } else {
    const response = await getFileFromAwsS3({
      url,
      responseType: 'blob',
    })
    if(open) {
      return previewFile({
        data: response.data,
        fileName,
        mimeType,
      })
    }
    return downloadFile({
      data: response.data,
      fileName,
      open,
    })
  }
}

export const getAwsBucket = () => store.get('constants.imageBucketAWS')

export const isAwsBucketUrl = val => _.isString(val) && val.indexOf(getAwsBucket()) > 0

export const isAwsImageUrl = val => isAwsBucketUrl(val) && hasImageFileExt(val)
