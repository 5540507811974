import { handleActions } from 'redux-actions'

const confirmationChoiceReducer = state => ({
  ...state,
  confirm: {
    open: false,
  },
})

export default handleActions({
  DIALOG: {
    LOADING: {
      OPEN: (state, action) => ({
        ...state,
        loading: {
          ...action.payload,
          open: true,
        },
      }),
      CLOSE: state => ({
        ...state,
        loading: {
          open: false,
          progress: null,
        },
      }),
    },
    ALERT: {
      OPEN: (state, action) => ({
        ...state,
        alert: {
          ...action.payload,
          open: true,
        },
      }),
      CLOSE: state => ({
        ...state,
        alert: {
          open: false,
        },
      }),
    },
    CONFIRM: {
      OPEN: (state, action) => ({
        ...state,
        confirm: {
          ...action.payload,
          open: true,
        },
      }),
      YES: confirmationChoiceReducer,
      NO: confirmationChoiceReducer,
      CANCEL: confirmationChoiceReducer,
    },
    DETAILS: {
      OPEN: (state, action) => ({
        ...state,
        details: {
          ...action.payload,
          open: true,
        },
      }),
      CLOSE: state => ({
        ...state,
        details: {
          open: false,
        },
      }),
    },
  },
},
{
  loading: {
    open: false,
    progress: null,
  },
  alert: {
    open: false,
  },
  confirm: {
    open: false,
  },
  details: {
    open: false,
  },
})
