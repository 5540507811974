// ==============================|| OVERRIDES - LINK ||============================== //

export default function List() {
  return {
    anticon: {
        width : '50px',
        height : '50px',
    }
  }
}
