import { client } from 'api/client'
import { getAndStoreConstants } from 'utils/site-context'

/**
 * @return {Promise<string>}
 */
export const saveDivision = async ({ json, ...rest }) => {
  const result = await client().post('/admin/divisions', {
    ...json,
    ...rest,
  })
  await getAndStoreConstants({ noOrgCache: true, noLocCache: true })
  return result?.data
}

/**
 * @return {Promise<object[]>}
 */
export const getOrgEventTypes = async () => {
  const response = await client().get('/admin/calendly-event-types')
  return response?.data
}

/**
 * Return array of info objects of labs that have orderables from CHC
 * @return {Promise<object[]>}
 */
export const getLabs = async () => {
  const response = await client().get('/admin/labs')
  return response?.data
}

export const saveLabAccount = async data => {
  const response = await client().post(`/admin/labAccount`, data)
  return response?.data
}

export const deleteLabAccounts = async ids => {
  const response = await client().post(`/admin/deleteLabAccounts`, { ids })
  return response?.data
}

export const getLabAccounts = async () => {
  const response = await client().get('/admin/labAccounts')
  return response?.data
}

export const getAllOrgMap = async() => {
  const result = await client().get('/domain-org-map')
  return result?.data
}

/**
 * @return {Promise<string>}
 */
export const updateTheme = async ({ json, ...rest }) => {
  const result = await client().post('/domain-org-map/update-theme', {
    ...json,
    ...rest,
  })
  return result?.data
}

/*
TEST BED FUNCTIONS:
 */

export const getOrderableInfo = async () => {
  const result = {}

  // AEL:
  let response = await client().post('/admin/test-bed-orderable-info', {
    labObjId: '13089285',
    orderCode: 'U696',
  })
  result.ael = response.data

  // SYNERGY:
  // response = await client().post('/admin/test-bed-orderable-info', {
  //   labObjId: '3003823717',
  //   orderCode: 'GEN_COVID19',
  // })
  // result.synergy = response.data

  // response = await client().post('/admin/test-bed-orderable-info', {
  //   labObjId: '25667019',
  //   orderCode: '7305',
  // })
  // result.clh = response?.data
  return result
}
