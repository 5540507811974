import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  text: {
    paddingRight: spacing(0.25),
  },
}))

export default ({
  text = '',
  fat = false,
}) => {
  const classes = useStyles()
  return (
    <Box
      display='flex'
      justifyContent='space-around'
      alignItems='center'
      flexGrow={1}
    >
      <span className={classes.text}>{text}</span>
      <CircularProgress
        size={fat ? 32 : 24}
        data-testid='loading-animation'
      />
    </Box>
  )
}
