import { createSelector } from 'reselect'
import { getCanUseAdmin, getRole, getUserPermissions } from 'app/state/ducks/user/selectors'
import _ from 'lodash'
import { isNonEmptyArray } from '@sustainhawaii/object-utils'
import { getDivisionProperty, getOrgDivisionProperty } from 'utils/org'
import { getPassLabel } from 'utils/string'
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined'
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined'
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined'
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined'
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined'
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined'
import {
  AreaChartOutlined,
  ContainerOutlined,
  CopyOutlined,
  DashboardOutlined,
  DiffOutlined,
  DownloadOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExperimentOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FormatPainterOutlined,
  FormOutlined,
  HeartOutlined,
  HighlightOutlined,
  IdcardOutlined,
  LayoutOutlined,
  MedicineBoxOutlined,
  MoneyCollectOutlined,
  NodeIndexOutlined,
  OneToOneOutlined,
  PhoneOutlined,
  ReconciliationOutlined,
  SafetyCertificateOutlined,
  SafetyOutlined,
  ScheduleOutlined,
  SnippetsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { systemRoles } from 'app/components/Drawer/DrawerContent/Navigation/feature-config'

const icons = {
  DashboardOutlined,
  SafetyCertificateOutlined,
  SafetyOutlined,
  DownloadOutlined,
  LayoutOutlined,
  IdcardOutlined,
  PhoneOutlined,
  MedicineBoxOutlined,
  FormOutlined,
  FormatPainterOutlined,
  UnorderedListOutlined,
  SnippetsOutlined,
  CopyOutlined,
  EditOutlined,
  DiffOutlined,
  HighlightOutlined,
  EnvironmentOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  ContainerOutlined,
  UserAddOutlined,
  OneToOneOutlined,
  TeamOutlined,
  ScheduleOutlined,
  ReconciliationOutlined,
  UserSwitchOutlined,
  ExperimentOutlined,
  VaccinesOutlinedIcon,
  CookieOutlinedIcon,
  SecurityOutlinedIcon,
  CorporateFareOutlinedIcon,
  DesignServicesOutlinedIcon,
  WarehouseOutlinedIcon,
  MedicalInformationOutlinedIcon,
  ContactEmergencyOutlinedIcon,
  CoronavirusOutlinedIcon,
  BallotOutlinedIcon,
  FactCheckOutlinedIcon,
  MapsHomeWorkOutlinedIcon,
  ManageAccountsOutlinedIcon,
  MoneyCollectOutlined,
  MonitorHeartOutlinedIcon,
  AreaChartOutlined,
  NodeIndexOutlined,
  CalculateOutlinedIcon,
  ExploreOutlinedIcon,
  FastfoodOutlinedIcon,
  HeartOutlined,
  DinnerDiningOutlinedIcon,
  LocalHospitalIcon,
  HistoryOutlinedIcon,
  MedicationLiquidOutlinedIcon,
}

const getAllMainItems = ({
  canUseAdmin,
  role,
}) => {
  const intakeFormEnabled = getDivisionProperty({ key: 'intakeFormEnabled' })
  const symptomSurveyEnabled = getOrgDivisionProperty({ key: 'symptomSurveyEnabled' })
  const patientCallItem = {
    name: 'Patients to Call',
    path: 'lab/calls',
    enabled: true,
    icon: icons.PhoneOutlined,
  }
  const printTestLabels = {
    name: 'Print Test Labels',
    path: 'lab/printing-test',
    enabled: true,
    icon: icons.OneToOneOutlined,
  }
  const medStaffItems = [
    {
      name: 'Pending Tests',
      path: 'lab/pending-tests',
      enabled: true,
      icon: icons.VaccinesOutlinedIcon,
    },
    {
      name: 'Pending Vaccinations',
      path: 'lab/pending-vaccs',
      enabled: false,
      icon: icons.ReconciliationOutlined,
    },
    patientCallItem,
    {
      name: 'Add New Patient',
      path: 'lab/create-patient',
      enabled: true,
      icon: icons.UserAddOutlined,
    },
  ]
  const reportingItems = []
  if(canUseAdmin) {
    let path = 'admin/tests-list'
    if(role === 'Sales Manager') {
      reportingItems.push({
        name: 'Leads Management',
        path: 'admin/leads-management',
        pathPattern: /\badmin\b/,
        enabled: true,
      })
      reportingItems.push({
        name: 'Sales Setup',
        path: 'admin/sales-setup',
        pathPattern: /\badmin\b/,
        enabled: true,
      })
    } else if(role === 'Prospect AO') {
      reportingItems.push({
        name: 'Administration',
        path: 'admin/org-setup',
        pathPattern: /\badmin\b/,
        enabled: true,
      })
    } else {
      reportingItems.push({
        name: 'Administration',
        path,
        pathPattern: /\badmin\b/,
        enabled: true,
      })
    }

  }
  return {
    everyone: [
      {
        name: 'My Dashboard',
        path: 'personal/dashboard',
        enabled: true,
        icon: icons.DashboardOutlined,

      },
      {
        name: `My ${getPassLabel()}s`,
        path: 'personal/passes',
        enabled: true,
        icon: icons.MedicalInformationOutlinedIcon,

      },
      {
        name: 'My Intake Forms',
        path: 'personal/intake-forms',
        pathPattern: /intake-forms?/,
        enabled: !!intakeFormEnabled,
        icon: icons.ContactEmergencyOutlinedIcon,

      },
      {
        name: 'COVID-19 Status',
        path: 'personal/my-status',
        pathPattern: /(personal\/my-status|myact\/div)/,
        enabled: true,
        icon: icons.CoronavirusOutlinedIcon,

      }, {
        name: 'My Health',
        path: 'personal/my-health',
        enabled: true,
        icon: icons.MonitorHeartOutlinedIcon,
      },
      {
        name: 'DRI',
        path: 'personal/dri',
        enabled: true,
        icon: icons.CalculateOutlinedIcon,
      },
      {
        name: 'Food Compass',
        path: 'personal/food-compass',
        enabled: true,
        icon: icons.ExploreOutlinedIcon,
      },
      {
        name: 'Diabetes',
        path: 'personal/diabetes',
        enabled: true,
        icon: icons.MedicationLiquidOutlinedIcon,
      },
      {
        name: 'Food Preferences',
        path: 'personal/food-preferences',
        enabled: true,
        icon: icons.DinnerDiningOutlinedIcon,
      },
      {
        name: 'Scan Upca',
        path: 'personal/scan-upca',
        enabled: true,
        icon: icons.FastfoodOutlinedIcon,
      },
      {
        name: 'Food Diary',
        path: 'personal/food-diary',
        enabled: true,
        icon: icons.HistoryOutlinedIcon,
      },
      /*  
      {
        name: 'Medical Chat',
        path: 'personal/medical-chat',
        enabled: true,
        icon: icons.LocalHospitalIcon,
      },*/
      {
        name: 'Report Symptoms',
        path: 'personal/symptoms',
        enabled: symptomSurveyEnabled,
        icon: icons.PhoneOutlined,

      },
    ],
    byRole: [
      {
        roles: ['Lab Tech', 'HCP', 'Account Owner', 'Admin'],
        items: [printTestLabels],
      },
      {
        roles: ['HCP', 'Account Owner', 'Admin'],
        items: [
          ...medStaffItems,
          ...reportingItems,
        ],
      },
      {
        roles: ['Sales Manager'],
        items: [...reportingItems],
      },
      {
        roles: ['Prospect AO'],
        items: [...reportingItems],
      },
      {
        roles: ['Analyst X'],
        items: [patientCallItem],
      },
      {
        roles: ['Analyst R', 'Analyst X'],
        items: reportingItems,
      },
    ],
    byFeature: [
      {
        feature: 'Administration of Organization',
        items: [printTestLabels, patientCallItem, ...medStaffItems],
      },
      {
        feature: 'CRM',
        items: [],
      },
      {
        feature: 'Gatekeeper',
        items: [],
      },
      {
        feature: 'Inventory',
        items: [],
      },
      {
        feature: 'Lab Functions',
        items: [printTestLabels, patientCallItem, ...medStaffItems,
        ],
      },
      {
        feature: 'Org Management',
        items: [],
      },
      {
        feature: 'Reporting',
        items: reportingItems,
      },
      {
        feature: 'FC Admin',
        items: [
          {
            name: 'FCS Mgmt',
            path: 'fcs-management',
            enabled: true,
            icon: icons.ManageAccountsOutlinedIcon,
          },
        ],
      },
      {
        feature: 'PCRM Admin',
        items: [
          {
            name: 'Health Profile',
            path: 'health-profile-management',
            enabled: true,
            icon: icons.HeartOutlined,
          },
        ],
      },
    ],
    bottom: [
      {
        name: 'Privacy Policy',
        path: 'privacy-policy',
        enabled: true,
        icon: icons.SecurityOutlinedIcon,

      },
      {
        name: 'Cookie Policy',
        path: 'cookie-policy',
        enabled: true,
        icon: icons.CookieOutlinedIcon,

      },
      {
        name: 'Supported Browsers',
        path: 'supported-browsers',
        enabled: true,
        icon: icons.LayoutOutlined,

      },
    ],
  }
}

const getAllAdminItems = () => {
  return ({
    byRole: [
      {
        roles: ['HCP', 'Account Owner', 'Admin', 'Analyst X', 'Analyst R'],
        items: [
          {
            name: 'Analytics',
            path: 'reporting',
            enabled: true,
            icon: icons.AreaChartOutlined,

          },
          {
            name: 'Test List',
            path: 'tests-list',
            enabled: true,
            icon: icons.BallotOutlinedIcon,

          },
          {
            name: 'Vacc List',
            path: 'vacc-list',
            enabled: true,
            icon: icons.FactCheckOutlinedIcon,

          },
          {
            name: 'Proc List',
            path: 'proc-list',
            enabled: true,
            icon: icons.FactCheckOutlinedIcon,
          },
          {
            name: 'Vacc Stats',
            path: 'vacc-stats',
            enabled: false,
            icon: icons.ContainerOutlined,

          },
        ],
      },
      {
        roles: ['HCP', 'Account Owner', 'Admin'],
        items: [
          {
            name: 'Claims',
            path: 'claim-management',
            enabled: true,
            icon: icons.FileDoneOutlined,

          },
          {
            name: 'Vacc Downloads',
            path: 'vaccs-export',
            enabled: false,
            icon: icons.DownloadOutlined,

          },
          {
            name: 'Scheduled Visits',
            path: 'scheduled-visits',
            enabled: true,
            icon: icons.ScheduleOutlined,

          },
        ],
      },
      {
        roles: ['HCP', 'Account Owner', 'Admin'],
        items: [
          {
            name: 'HCP Scheduler',
            path: 'hcp-scheduler',
            enabled: true,
            icon: icons.ScheduleOutlined,

          },
        ],
      },
      {
        roles: ['Account Owner', 'Admin'],
        items: [
          {
            name: 'Patients',
            path: 'patients',
            enabled: true,
            icon: icons.TeamOutlined,

          },
          {
            name: 'User Mgmt',
            path: 'user-management',
            enabled: true,
            icon: icons.ManageAccountsOutlinedIcon,

          },
          {
            name: 'Workflow Mgmt',
            path: 'workflow-management',
            enabled: true,
            icon: icons.NodeIndexOutlined,

          },
          {
            name: 'Health Profile',
            path: 'health-profile-management',
            enabled: true,
            icon: icons.HeartOutlined,

          },
          {
            name: 'FCS Mgmt',
            path: 'fcs-management',
            enabled: true,
            icon: icons.NodeIndexOutlined,

          },
        ],
      },
      {
        roles: ['Admin'],
        items: [
          {
            name: 'Divisions',
            path: 'division-manager',
            enabled: true,
            icon: icons.MapsHomeWorkOutlinedIcon,

          },
        ],
      }, {
        roles: ['Account Owner', 'Admin'],
        items: [
          {
            name: 'Locations',
            path: 'location-manager',
            enabled: true,
            icon: icons.EnvironmentOutlined,

          },
          {
            name: 'Inventory Mgmt',
            path: 'inventory-management',
            enabled: true,
            icon: icons.WarehouseOutlinedIcon,

          },
        ],
      },
      {
        roles: ['Admin'],
        items: [
          {
            name: 'Lab Accounts',
            path: 'lab-account-manager',
            enabled: true,
            icon: icons.ExperimentOutlined,

          },
        ],
      },
      {
        roles: ['Sales Manager', 'Account Owner', 'Admin'],
        items: [
          {
            name: 'Leads',
            path: 'leads-management',
            enabled: true,
            icon: icons.DiffOutlined,
          },
        ],
      },
      {
        roles: ['Sales Manager', 'Admin', 'Account Owner'],
        items: [
          {
            name: 'Sales Setup',
            path: 'sales-setup',
            enabled: true,
            icon: icons.MoneyCollectOutlined,
          },
        ],
      },
      {
        roles: ['Account Owner', 'Admin'],
        items: [
          {
            name: 'Form Designer',
            path: 'form-designer',
            enabled: true,
            icon: icons.DesignServicesOutlinedIcon,
          },
        ],
      },
      {
        roles: ['Prospect AO', 'Admin'],
        items: [
          {
            name: 'Org Setup',
            path: 'org-setup',
            enabled: true,
            icon: icons.CorporateFareOutlinedIcon,
          },
        ],
      },
    ],
    byFeature: [
      {
        feature: 'Administration of Organization',
        items: [
          {
            name: 'Test List',
            path: 'tests-list',
            enabled: true,
            icon: icons.BallotOutlinedIcon,
          },
          {
            name: 'Vacc List',
            path: 'vacc-list',
            enabled: true,
            icon: icons.FactCheckOutlinedIcon,
          },
          {
            name: 'Proc List',
            path: 'proc-list',
            enabled: true,
            icon: icons.FactCheckOutlinedIcon,
          },
          {
            name: 'Vacc Stats',
            path: 'vacc-stats',
            enabled: false,
            icon: icons.ContainerOutlined,
          },
          {
            name: 'Claims',
            path: 'claim-management',
            enabled: true,
            icon: icons.FileDoneOutlined,
          },
          {
            name: 'Vacc Downloads',
            path: 'vaccs-export',
            enabled: false,
            icon: icons.DownloadOutlined,
          },
          {
            name: 'Scheduled Visits',
            path: 'scheduled-visits',
            enabled: true,
            icon: icons.ScheduleOutlined,
          },
          {
            name: 'Patients',
            path: 'patients',
            enabled: true,
            icon: icons.TeamOutlined,
          },
          {
            name: 'User Mgmt',
            path: 'user-management',
            enabled: true,
            icon: icons.ManageAccountsOutlinedIcon,
          },
          {
            name: 'Divisions',
            path: 'division-manager',
            enabled: true,
            icon: icons.MapsHomeWorkOutlinedIcon,
          },
          {
            name: 'Locations',
            path: 'location-manager',
            enabled: true,
            icon: icons.EnvironmentOutlined,
          },
          {
            name: 'Inventory Mgmt',
            path: 'inventory-management',
            enabled: true,
            icon: icons.WarehouseOutlinedIcon,
          },
          {
            name: 'Lab Accounts',
            path: 'lab-account-manager',
            enabled: true,
            icon: icons.ExperimentOutlined,
          },
          {
            name: 'Leads',
            path: 'leads-management',
            enabled: true,
            icon: icons.DiffOutlined,
          },
          {
            name: 'Form Designer',
            path: 'form-designer',
            enabled: true,
            icon: icons.DesignServicesOutlinedIcon,
          },
          {
            name: 'Org Setup',
            path: 'org-setup',
            enabled: true,
            icon: icons.CorporateFareOutlinedIcon,
          },
          {
            name: 'Sales Setup',
            path: 'sales-setup',
            enabled: true,
            icon: icons.MoneyCollectOutlined,
          },
          {
            name: 'Workflow Mgmt',
            path: 'workflow-management',
            enabled: true,
            icon: icons.NodeIndexOutlined,
          },
          {
            name: 'Health Profile',
            path: 'health-profile-management',
            enabled: true,
            icon: icons.HeartOutlined,
          },
          {
            name: 'FCS Mgmt',
            path: 'fcs-management',
            enabled: true,
            icon: icons.ManageAccountsOutlinedIcon,
          },
        ],
      },
      {
        feature: 'FC Admin',
        items: [
          {
            name: 'FCS Mgmt',
            path: 'fcs-management',
            enabled: true,
            icon: icons.ManageAccountsOutlinedIcon,
          },
        ],
      },
      {
        feature: 'PCRM Admin',
        items: [
          {
            name: 'Health Profile',
            path: 'health-profile-management',
            enabled: true,
            icon: icons.HeartOutlined,
          },
        ],
      },
      {
        feature: 'CRM',
        items: [
          {
            name: 'Leads',
            path: 'leads-management',
            enabled: true,
            icon: icons.DiffOutlined,
          },
          {
            name: 'Sales Setup',
            path: 'sales-setup',
            enabled: true,
            icon: icons.MoneyCollectOutlined,
          },
        ],
      },
      {
        feature: 'Gatekeeper',
        items: [
          {
            name: 'Test List',
            path: 'tests-list',
            enabled: true,
            icon: icons.BallotOutlinedIcon,
          },
        ],
      },
      {
        feature: 'Inventory',
        items: [
          {
            name: 'Inventory Mgmt',
            path: 'inventory-management',
            enabled: true,
            icon: icons.WarehouseOutlinedIcon,
          },
        ],
      },
      {
        feature: 'Lab Functions',
        items: [
          {
            name: 'Test List',
            path: 'tests-list',
            enabled: true,
            icon: icons.BallotOutlinedIcon,
          },
          {
            name: 'Vacc List',
            path: 'vacc-list',
            enabled: true,
            icon: icons.FactCheckOutlinedIcon,
          },
          {
            name: 'Proc List',
            path: 'proc-list',
            enabled: true,
            icon: icons.FactCheckOutlinedIcon,
          },
          {
            name: 'Claims',
            path: 'claim-management',
            enabled: true,
            icon: icons.FileDoneOutlined,
          },
          {
            name: 'Scheduled Visits',
            path: 'scheduled-visits',
            enabled: true,
            icon: icons.ScheduleOutlined,
          },
          {
            name: 'Patients',
            path: 'patients',
            enabled: true,
            icon: icons.TeamOutlined,
          },
        ],
      },
      {
        feature: 'Org Management',
        items: [
          {
            name: 'Org Setup',
            path: 'org-setup',
            enabled: true,
            icon: icons.CorporateFareOutlinedIcon,
          },
        ],
      },
      {
        feature: 'Reporting',
        items: [
          {
            name: 'Test List',
            path: 'tests-list',
            enabled: true,
            icon: icons.BallotOutlinedIcon,
          },
          {
            name: 'Vacc List',
            path: 'vacc-list',
            enabled: true,
            icon: icons.FactCheckOutlinedIcon,
          },
          {
            name: 'Vacc Stats',
            path: 'vacc-stats',
            enabled: false,
            icon: icons.ContainerOutlined,
          },
          {
            name: 'Vacc Downloads',
            path: 'vaccs-export',
            enabled: false,
            icon: icons.DownloadOutlined,
          },
        ],
      },
    ],
  })
}

export const getMainMenuItems = createSelector(getRole, getCanUseAdmin, getUserPermissions, (role, canUseAdmin, permissions) => buildItemsArray(getAllMainItems({
  canUseAdmin,
  role,
}), role, permissions))

export const getAdminMenuItems = createSelector(getRole, getUserPermissions, (role, permissions) => buildItemsArray(getAllAdminItems(), role, permissions))

const buildItemsArray = (allItems, role, permissions) => {
  const menuItems = []
  addItems(menuItems, allItems.everyone)

  if(systemRoles.includes(role)) {
    const roleItems = _.chain(allItems.byRole).filter(({ roles }) => roles.includes(role)).map('items').flatten().value()
    addItems(menuItems, roleItems)

  } else {
    const allowedFeatures = _.chain(permissions).map(perm => {
      if(perm.view) {
        return perm.featureName
      }
    }).compact().value()
    const roleItems = _.chain(allItems.byFeature).filter((features) => allowedFeatures.includes(features.feature)).map('items').flatten().uniqBy('name').value()
    addItems(menuItems, roleItems)
  }

  addItems(menuItems, allItems.bottom)
  return menuItems
}

export const getIsOpen = state => state.menu.isOpen || false

const addItems = (menuItems, newItems) => {
  if(isNonEmptyArray(newItems)) menuItems.push(..._.castArray(_.filter(newItems, 'enabled')))
}
