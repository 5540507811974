import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Chip, Stack } from '@mui/material'

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled'
import { getThisAppVersion } from '../../../../api/general'
import { getOrg } from '../../../../utils/org'

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme()
  const orgId = getOrg().id
  const appVersion = getThisAppVersion()

  return (
    // only available in paid version
   <></>
  )
}

DrawerHeader.propTypes = {
  open: PropTypes.bool,
}

export default DrawerHeader