// MUI SelectField wrapped for use with Formik

import React from 'react'
import { Field, useFormikContext } from 'formik'
import FormControl from '@mui/material/FormControl'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import InputLabel from '@mui/material/InputLabel'
import { Select } from 'formik-mui'
import { getMenuItems } from 'app/views/components/form/formHelpers'
import _ from 'lodash'
import TextField from 'app/views/components/form/mui-formik/TextField'
import Chip from '@mui/material/Chip'
import HelperText from 'app/views/components/form/HelperText'
import useTranslateMapValues from '../../../hooks/useTranslateMapValues'

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

export default ({
  name,
  itemData,
  otherChoiceName,
  otherChoiceLabel = 't:error:pleaseSpecify',
  label,
  multiple = false,
  inputVariant = 'outlined', // variant of the MUI text input
  formControlProps = {}, // props passed to FormControl
  required = false,
  disabled = false,
  inputProps = {}, // props passed to the MUI text input used to display selection
  placeholder, // optional placeholder text/object displayed in text field and is added as a disabled first item
  margin = 'dense',
  fullWidth,
  autoComplete,
}) => {
  const { values } = useFormikContext()
  const classes = useStyles()
  const theme = useTheme()
  const labels = useTranslateMapValues({
    obj: {
      label,
      otherChoiceLabel,
      placeholder,
    },
  })
  const menuItems = getMenuItems({
    name,
    itemData,
    placeholder: labels.placeholder,
    otherChoiceName,
    multiple,
    values,
    theme,
  })
  _.assign(formControlProps, {
    variant: inputVariant,
    required,
  })
  _.assign(inputProps, {
    autoComplete,
  })
  let renderValue
  if (multiple) {
    renderValue = selected => (
      <div className={classes.chips}>
        {selected.map(v => (
          <Chip key={v} label={itemData[v]} className={classes.chip} />
        ))}
      </div>
    )
  }
  return (
    <>
      <FormControl
        fullWidth={fullWidth}
        margin={margin}
        {...formControlProps}
      >
        {/*{labels.label && <InputLabel>{labels.label}</InputLabel>}*/}
        <Field
          id={name}
          name={name}
          label={labels.label}
          component={Select}
          renderValue={renderValue}
          multiple={multiple}
          displayEmpty={!!placeholder}
          fullWidth={fullWidth}
          disabled={disabled}
          variant={inputVariant}
          inputProps={{
            id: name,
            autoComplete,
          }}
        >
          {menuItems}
        </Field>
        <HelperText name={name} />
      </FormControl>
      {!multiple && _.get(values, name) === 'other' && (
        <>
          <TextField
            name={otherChoiceName}
            label={otherChoiceLabel}
            required={required}
            disabled={disabled}
            fullWidth
          />
          <HelperText name={otherChoiceName} />
        </>
      )}
    </>
  )
}
