import axios from 'axios'
import _ from 'lodash'
import { env } from 'utils/env'
import { mapValuesDeepAsync, mapValuesToJSON, objectWithoutUndefinedValues } from '@sustainhawaii/object-utils'
import { getReducedFileUploadBlob, isNewFileUpload, isPreviousFileUpload } from 'utils/file'
import { addAxiosRetry } from 'utils/http'

let instance
let multiPartInstance

const CancelToken = axios.CancelToken
const source = CancelToken.source()

const requestInterceptor = async (config) => {
  config.cancelToken = source.token
  return config
}

export const client = () => {
  if(instance && instance.baseURL === clientApiURL) return instance
  instance = axios.create({
    baseURL: clientApiURL,
    timeout: 35000,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
  addAxiosRetry({ instance })
  instance.interceptors.request.use(requestInterceptor)
  return instance
}

export const multiPartClient = () => {
  if(multiPartInstance && multiPartInstance.baseURL === clientApiURL) return multiPartInstance
  multiPartInstance = axios.create({
    baseURL: clientApiURL,
    timeout: 30000,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    withCredentials: true,
  })
  addAxiosRetry({ instance: multiPartInstance })
  multiPartInstance.interceptors.request.use(requestInterceptor)
  return multiPartInstance
}

export const createFormData = async formDataObj => {
  if(!_.isObjectLike(formDataObj)) return
  // eslint-disable-next-line no-undef
  const formData = new FormData()
  const definedPairs = objectWithoutUndefinedValues(formDataObj)
  const {
    formDataWithBlobRefs,
    blobs,
  } = await getBlobifiedFormData(definedPairs)
  const combinedPairs = {
    ...mapValuesToJSON(formDataWithBlobRefs),
    ...blobs,
    s3CustomerKey: env('AWS_CUSTOMER_KEY'),
  }
  _.forEach(combinedPairs, (val, key) => formData.append(key, val))
  return formData
}

/**
 * Recursively find all file input value objects in an object.
 * Create separate object whose keys are dot-notation paths of those values, and values are blobs.
 * Optimize each blob for upload.
 * Delete the keys from the original object.
 * Return the stripped original object and new blobs object in another object.
 * Replace previously-uploaded file input objects with the AWS S3 url.
 *
 * @param {object} formData
 * @return {object}
 */
const getBlobifiedFormData = async formData => {
  const blobs = {}
  const iter = async ({ val }) => {
    if(isPreviousFileUpload(val)) return val.url
    if(!isNewFileUpload(val)) return val
    const blob = await getReducedFileUploadBlob(val.file)
    const blobKey = `blob_${_.size(blobs)}`
    blobs[blobKey] = blob
    const { dataUri, ...goodStuff } = val
    return { ...goodStuff, file: blobKey }
  }
  const recursionTest = val => !isNewFileUpload(val) && !isPreviousFileUpload(val)
  const formDataWithBlobRefs = await mapValuesDeepAsync({
    obj: formData,
    iter,
    recursionTest,
  })
  return {
    formDataWithBlobRefs,
    blobs,
  }
}
