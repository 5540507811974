import React from 'react'
import CenteredContentContainer from 'app/views/components/layout/CenteredContentContainer'
import HugeIconAndTextButton from 'app/views/components/buttons/HugeIconAndTextButton'
import ReloadIcon from 'app/views/components/icons/ReloadIcon'
import useAppInit from '../../../hooks/useAppInit'

export default ({ label }) => {
  const { updateApp } = useAppInit()
  return (
    <CenteredContentContainer>
      <HugeIconAndTextButton
        Icon={ReloadIcon}
        text={label}
        onClick={updateApp}
        fullWidth
      />
    </CenteredContentContainer>
  )
}
