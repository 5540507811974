import React from 'react'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import DivisionListNav from 'app/views/components/lists/DivisionListNav'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginTop: spacing(3),
    marginBottom: spacing(2),
  },
}))

export default ({
  isOpen = false,
  handleClose,
}) => {
  const classes = useStyles()
  return (
    <Drawer
      open={isOpen}
      anchor='right'
      ModalProps={{
        onClose: handleClose,
      }}
    >
      <Typography
        variant='h6'
        align='center'
        className={classes.title}
      >
        Select Division
      </Typography>
      <DivisionListNav />
    </Drawer>
  )
}