import { eventChannel } from 'redux-saga'
import { standardFluxThunk } from 'utils/redux'
import { useDispatch } from 'react-redux'
import log from 'utils/logger'

export const getIntervalChannel = seconds => {
  try {
    return eventChannel(emitter => {
      let n = 0
      const iv = setInterval(() => {
        emitter({ pollCount: ++n })
      }, seconds * 1000)
      return () => {
        clearInterval(iv)
      }
    })
  } catch (e) {
    log.error('getIntervalChannel error:', e)
  }
}

export const getSagaCall = actions => {
  const dispatch = useDispatch()
  return standardFluxThunk(dispatch, actions.start, {
    waiting: actions,
  })
}
