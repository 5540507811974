// material-ui
import { Box, Typography } from '@mui/material'

// project import
import NavGroup from './NavGroup'
import useMainMenu from '../../../../views/hooks/useMainMenu'
import useAdminMenu from '../../../../views/hooks/useAdminMenu'

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { items } = useMainMenu()
  const { items: adminItems } = useAdminMenu()

  // Group menus into 4 sections
  const support = ['privacy-policy', 'cookie-policy', 'supported-browsers']
  const admin = ['admin/tests-list']
  const nav = [
    'personal/dashboard',
    'personal/passes',
    'personal/intake-forms',
    'personal/my-status',
    'personal/my-health',
    'personal/dri',
    'personal/schedule',
    'personal/food-compass',
    'personal/diabetes',
    'personal/food-preferences',
    'personal/scan-upca',
    'personal/food-diary'
  ]
  const lab = ['lab/printing-test', 'lab/pending-tests', 'lab/pending-vaccs', 'lab/calls', 'lab/create-patient']

  const supportMenus = {
    id: 'support',
    title: 'Support',
    type: 'group',
    children: [],
  }
  const adminMenus = {
    id: 'admin',
    title: 'Administration',
    type: 'group',
    children: [],
  }
  const navMenus = {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    children: [],
  }
  const labMenus = {
    id: 'labs',
    title: 'Lab',
    type: 'group',
    children: [],
  }
  const adminMenuSet = adminItems.map(item => ({
    id: item.path,
    title: item.name,
    url: `/admin/${item.path}`,
    type: 'item',
    icon: item.icon,
  }))
  let isAdminMenu = false
  for (const navItem of items) {
    let menuSet
    if(nav.includes(navItem.path)) menuSet = navMenus
    else if(support.includes(navItem.path)) menuSet = supportMenus
    else if(admin.includes(navItem.path)) {
      isAdminMenu = true
      continue
    } else if(lab.includes(navItem.path)) menuSet = labMenus
    else continue

    menuSet.children.push({
      id: navItem.path,
      title: navItem.name,
      url: `/${navItem.path}`,
      icon: navItem.icon,
      type: 'item',
    })

  }
  if(isAdminMenu) adminMenus.children = adminMenuSet
  else if(adminMenuSet) adminMenus.children = adminMenuSet
  const menuItems = []
  navMenus.children.length && menuItems.push(navMenus)
  labMenus.children.length && menuItems.push(labMenus)
  adminMenus.children.length && menuItems.push(adminMenus)
  supportMenus.children.length && menuItems.push(supportMenus)

  const navGroups = menuItems.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Fix - Navigation Group
          </Typography>
        )
    }
  })

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>
}

export default Navigation