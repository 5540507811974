import { handleActions } from 'redux-actions'
import produce from 'immer'
import _ from 'lodash'

export default handleActions({
  APPINIT: {
    START: (state) => ({
      ...state,
      initialized: false,
      initializing: true,
    }),
    SUCCESS: (state, { payload }) => produce(state, draft => {
      draft.initialized = true
      draft.initializing = false
      _.assign(draft, payload)
    }),
    FAILURE: (state) => ({
      ...state,
      initialized: false,
      initializing: false,
    }),
  },
  BEGIN_APP_UPDATE: (state) => ({
    ...state,
    appUpdateBegun: true,
  }),
  THEME: (state, { theme }) => ({
    ...state,
    theme: theme.theme,
    fontFamily: theme.fontFamily,
  }),
}, {
  initialized: false,
  initializing: false,
  appUpdateBegun: false,
  theme: __appTheme,
  fontFamily: __appFontFamily,
})